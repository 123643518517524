import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase';
import { DialogService } from 'src/app/dialog.service';
import { PricingClioComponent } from 'src/app/pricing-clio/pricing-clio.component';
import { AuthService } from 'src/app/services/auth.service';
import { RecurlyService } from 'src/app/services/recurly.service';
import { RedirectionService } from 'src/app/services/redirection.service';
import { environment } from 'src/environments/environment';

const validators = {
  creditCard: {
    state: [Validators.required, Validators.pattern('[A-Z]*'), Validators.minLength(2), Validators.maxLength(2)],
    zip: [Validators.required, Validators.minLength(3), Validators.maxLength(5)],
    city: [Validators.required, Validators.pattern('[a-zA-Z ]*')],
    address: [Validators.required],
  },
  first_name: [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(20),
    Validators.pattern('[a-zA-Z ]*'),
  ],
  last_name: [Validators.required, Validators.minLength(3), Validators.maxLength(20), Validators.pattern('[a-zA-Z ]*')],
  phone: [Validators.required, Validators.pattern('^\\(?([0-9]{3})\\)?[-.\\s]?([0-9]{3})[-.\\s]?([0-9]{4})$')],
};

@Component({
  selector: 'app-complete-nuagedx-registration',
  templateUrl: './complete-nuagedx-registration.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      #recurly-elements > .recurly-element-card {
        height: 40px;
      }
      .form-actions {
        margin-top: 20px;
      }

      .plan-details {
        font-size: 14px;
      }

      .hidden {
        display: none;
      }

      .error-message {
        color: red;
        font-size: 12px;
      }
    `,
  ],
})
export class CompleteNuagedxRegistrationComponent implements OnInit {
  pricingOpened: boolean = false;
  planContent: any;
  currentPlan: string;
  requirePayment: boolean = false;
  plansContent: any;
  recurlyElementsValidity: any = false;
  showWrongCreditCard: boolean = false;
  creditCardError: string = `Please enter a valid credit card number.`;

  constructor(
    private ngZone: NgZone,
    private redirect_$: RedirectionService,
    private auth_$: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private recurly_$: RecurlyService,
    private dialog_$: DialogService,
  ) {}

  errorMessage = {
    first_name: [
      { type: 'required', message: 'First name is required' },
      { type: 'minlength', message: 'First name must be at least 3 characters long' },
      { type: 'maxlength', message: 'First name cannot be more than 20 characters long' },
      { type: 'pattern', message: 'Your first name must contain only numbers and letters' },
    ],
    last_name: [
      { type: 'required', message: 'Last name is required' },
      { type: 'minlength', message: 'Last name must be at least 3 characters long' },
      { type: 'maxlength', message: 'Last name cannot be more than 20 characters long' },
      { type: 'pattern', message: 'Your last name must contain only numbers and letters' },
    ],
    email: [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'Please enter a valid email' },
    ],
    phone: [
      { type: 'required', message: 'Cell phone number is required' },
      { type: 'pattern', message: 'Please enter a valid cell phone number' },
    ],
    extension: [
      { type: 'required', message: 'Extension is required' },
      { type: 'pattern', message: 'Please enter a valid extension' },
    ],
    companyName: [
      { type: 'required', message: 'Company name is required' },
      { type: 'minlength', message: 'Company name must be at least 3 characters long' },
      { type: 'maxlength', message: 'Company name cannot be more than 20 characters long' },
    ],
    cardNumber: [
      { type: 'required', message: 'Card number is required' },
      { type: 'pattern', message: 'Please enter a valid card number' },
      { type: 'minlength', message: 'Card number must be at least 16 characters long' },
      { type: 'maxlength', message: 'Card number cannot be more than 16 characters long' },
    ],
    expiration: [
      { type: 'required', message: 'Expiration is required' },
      { type: 'pattern', message: 'Please enter a valid expiration' },

      { type: 'minlength', message: 'Expiration must be at least 4 characters long' },
      { type: 'maxlength', message: 'Expiration cannot be more than 4 characters long' },
    ],
    cvv: [
      { type: 'required', message: 'CVV is required' },
      { type: 'pattern', message: 'Please enter a valid CVV' },
      { type: 'minlength', message: 'CVV must be at least 3 characters long' },
      { type: 'maxlength', message: 'CVV cannot be more than 4 characters long' },
    ],
    address: [{ type: 'required', message: 'Address is required' }],
    address2: [{ type: 'required', message: 'Address 2 is required' }],
    state: [
      { type: 'required', message: 'State is required' },
      { type: 'pattern', message: 'Please enter a valid state' },
    ],
    city: [
      { type: 'required', message: 'City is required' },
      { type: 'pattern', message: 'Please enter a valid city' },
    ],
    zip: [
      { type: 'required', message: 'Zip is required' },
      { type: 'pattern', message: 'Please enter a valid zip' },
      { type: 'minlength', message: 'Zip must be at least 3 characters long' },
      { type: 'maxlength', message: 'Zip cannot be more than 5 characters long' },
    ],
  };

  backToPlans() {
    this.openPricingModal();
  }

  completeNuagedxRegistrationForm: FormGroup = new FormGroup({
    first_name: new FormControl('', validators['first_name']),
    last_name: new FormControl('', validators['last_name']),
    recurlyToken: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    recurlyplan: new FormControl('', [Validators.required]),
    phone: new FormControl('', validators['phone']),
    extension: new FormControl('', [Validators.pattern('[0-9]*')]),
    companyName: new FormControl('', [Validators.minLength(3)]),
    validCreditCard: new FormControl(false, [Validators.requiredTrue]),
    creditCard: new FormGroup({
      address: new FormControl('', validators['creditCard']['address']),
      address2: new FormControl(''),
      state: new FormControl('', validators['creditCard']['state']),
      city: new FormControl('', validators['creditCard']['city']),
      zip: new FormControl('', validators['creditCard']['zip']),
    }),

    terms: new FormControl(false, [Validators.requiredTrue]),
    baa: new FormControl(false, [Validators.requiredTrue]),
  });

  openPricingModal(plancode?: string) {
    if (this.pricingOpened) {
      return;
    }

    const dialog = this.dialog_$.open(PricingClioComponent, {
      panelClass: 'pricing-modal',
      closeOnNavigation: false,
      disableClose: true,
      data: {
        closable: plancode ? true : false,
        plan: plancode,
      },
    });

    dialog.afterClosed().subscribe(plan => {
      const planCode = plan === 'free' ? 'fp' : plan;
      this.completeNuagedxRegistrationForm.patchValue({ recurlyplan: planCode });

      const creditCardGroup = this.completeNuagedxRegistrationForm.get('creditCard') as FormGroup;
      const fields = ['address', 'state', 'city', 'zip'];

      const updateValidators = (add: boolean) => {
        fields.forEach(field => {
          const control = creditCardGroup.get(field);
          if (add) {
            control.addValidators(validators['creditCard'][field]);
          } else {
            control.clearValidators();
          }
          control.updateValueAndValidity();
        });
      };

      updateValidators(planCode !== 'fp');

      creditCardGroup.updateValueAndValidity();

      if (this.currentPlan !== plan) {
        this.getPlanContent(plan);
      }

      this.pricingOpened = false;
    });

    dialog.afterOpened().subscribe(result => {
      console.log('The dialog was opened');
      this.pricingOpened = true;
    });
  }

  getPlanContent(plan: string) {
    if (!plan) {
      return;
    }
    this.planContent = this.plansContent[plan];
    this.currentPlan = plan;
    return;
  }

  getPlansContent() {
    return firebase
      .firestore()
      .collection('plans')
      .get()
      .then((querySnapshot: any) => {
        const plans = {};
        querySnapshot.forEach((doc: any) => {
          plans[doc.id] = doc.data().content;
        });
        return plans;
      });
  }

  async ngOnInit(): Promise<void> {
    this.requirePayment = this.currentPlan !== 'free';
    this.plansContent = await this.getPlansContent();

    this.auth_$.auth.onAuthStateChanged({
      next: user => {
        if (user) {
          this.auth_$.getUserData(user).then((data: any) => {
            if (data) {
              const { email, firstName, lastName, name, plancode, phoneNumber } = data.docs[0].data();

              const { nuagedxplan } = <{ nuagedxplan: string }>this.auth_$.userData.value;
              const patchObject = {
                email: email,
                first_name: firstName || name?.split(' ')[0],
                last_name: lastName || name?.split(' ')[1],
                phone: phoneNumber,
                recurlyplan: nuagedxplan || '',
              };

              this.completeNuagedxRegistrationForm.patchValue(patchObject);

              if (!this.currentPlan) {
                this.ngZone.run(() => {
                  this.dialog_$.dialog.closeAll();
                  this.openPricingModal(plancode);
                });
              }
            } else {
              console.log('no data');
            }
          });
        }
      },
      error: err => console.log(err),
      complete: () => console.log('complete'),
    });

    this.loadScript();

    console.log(this.completeNuagedxRegistrationForm.get('creditCard').get('cardNumber'));

    this.route.queryParams.subscribe(params => {
      const defaultPlan = 'gold';
      if (params['plan']) {
        // Get the plan code from the URL query params
        const plan = params['plan'];
        this.getPlanContent(plan ? plan : defaultPlan);
      } else {
        this.getPlanContent(defaultPlan);
      }
    });
  }

  getErrorMessage(controlname) {
    return this.errorMessage[controlname]
      .filter(error => this.completeNuagedxRegistrationForm.get(controlname).hasError(error.type))
      .map(error => error.message)[0];
  }

  getErrorMessageN(controlnames) {
    return this.errorMessage[controlnames[1]]
      .filter(error =>
        this.completeNuagedxRegistrationForm.get(controlnames[0]).get(controlnames[1]).hasError(error.type),
      )
      .map(error => error.message)[0];
  }

  clean(str) {
    return str.replace(' ', '-').replace(/[^A-Za-z0-9-]/g, '');
  }

  loadScript() {
    const script = document.createElement('script');
    script.src = 'https://js.recurly.com/v4/recurly.js';
    script.type = 'text/javascript';
    script.async = true;
    script.onload = this.configureRecurly.bind(this);
    script.onerror = () => {
      console.error('Failed to load Recurly script');
    };
    document.head.appendChild(script);
  }

  configureRecurly() {
    try {
      const recurly = window['recurly'];

      // @ts-ignore
      recurly.configure(environment.recurly.publicKey);
      // @ts-ignore
      window['elements'] = recurly.Elements();

      // @ts-ignore
      const cardElement = window['elements'].CardElement({
        inputType: 'text',
        placeholder: 'Enter your card details',
        style: {
          fontSize: '1em',
          placeholder: {
            content: {
              number: 'Card number',
              cvv: 'CVC',
            },
          },
          invalid: {
            fontColor: 'red',
          },
        },
      });
      cardElement.attach('#recurly-elements');
      cardElement.on('change', event => {
        this.completeNuagedxRegistrationForm.patchValue({ validCreditCard: event.valid });
        if (!event.empty && !event.number.empty && !event.number.valid && !event.number.focus) {
          this.showWrongCreditCard = true;
        }
      });
    } catch (error) {
      console.error('Failed to configure Recurly', error);
    }
  }

  updateUserRecurlyCode(code, plancode: string) {
    return this.recurly_$.updateUserRecurlyCode(this.auth_$.userData.value['id'], code, plancode);
  }

  async handleSuccessfulSubscriptionCreation(data) {
    this.auth_$.showLoader('Updating your account...');
    const { code } = data.account;
    const plancode = data.plan.code;
    this.updateUserRecurlyCode(code, plancode);
    console.log('Your subscription is active!');

    const dbPlans = firebase.firestore().collection('plans');
    const plan = dbPlans.doc(plancode);

    const newUserData = {
      ...this.auth_$.userData.getValue(),
      plancode: plancode,
      plan: plan,
    };

    const afterUserReady = async () => {
      // Update the sssion user data.
      this.auth_$.userData.next(newUserData);

      // this.auth_$.userReady(this.auth_$.currentUser.getValue(), 'handleSuccessfulSubscriptionCreation 2');
      this.auth_$.hideLoader();
      this.redirectToWelcomPage();
    };

    this.auth_$
      .userReady(this.auth_$.userData.getValue()['user'], 'handleSuccessfulSubscriptionCreation')
      .then(async () => afterUserReady());
  }

  redirectToWelcomPage() {
    this.dialog_$.dialog.closeAll();
    this.router.navigate(['lpmwelcome']);
  }

  redirectToHome() {
    this.router.navigate(['/']);
  }

  onSubmit() {
    const recurly = window['recurly'];
    const elements = window['elements'];
    this.auth_$.showLoader('Updating your payment information...');

    if (this.currentPlan === 'free') {
      // Avoid recurly.token creation.

      const formData = { ...this.completeNuagedxRegistrationForm.value, creditCard: null };
      firebase
        .functions()
        .httpsCallable('recurly-handleSubscriptionCreationFree')(formData)
        .then(res => {
          console.log(res);
          if (res.data.error) {
            this.auth_$.setToastMessage(res.data.error['params'][0]['message'], 'ERROR');
            this.auth_$.hideLoader();
          } else {
            this.handleSuccessfulSubscriptionCreation(res.data);
            this.auth_$.hideLoader();
          }
        });

      return;
    }

    // @ts-ignore
    recurly.token(elements, document.querySelector('form'), (err, token) => {
      if (err) {
        console.log('err :', err);
        return;
      }

      console.log('token :', token);
      this.completeNuagedxRegistrationForm.patchValue({ recurlyToken: token });

      console.log(this.completeNuagedxRegistrationForm.value);

      firebase
        .functions()
        // FIXME: Upgrade should transform current subscription instead of clear all of them and create a brand new one each time?
        // .httpsCallable('recurly-handleSubscriptionCreationV3')(this.completeNuagedxRegistrationForm.value)
        .httpsCallable('recurly-handleSubscriptionCreationV2')(this.completeNuagedxRegistrationForm.value)
        .then(res => {
          console.log(res);
          if (res.data.error) {
            if (typeof res.data.error === 'string') {
              this.auth_$.setToastMessage(res.data.error, 'ERROR');
            } else {
              this.auth_$.setToastMessage(res.data.error['params'][0]['message'], 'ERROR');
            }
            this.auth_$.hideLoader();
          } else {
            this.handleSuccessfulSubscriptionCreation(res.data);
            this.auth_$.hideLoader();
          }
        });
    });
  }
}
