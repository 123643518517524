import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MattersService } from 'src/app/services/matters.service';

export interface FileItem {
  name: string;
  type: 'file' | 'folder' | 'image';
  size?: string;
  date?: string;
  selected?: boolean;
  isDuplicate?: boolean;
  path?: string;
  children?: FileItem[];
  expanded?: boolean;
  count?: number;
  parentFolder?: string;
  // Add content property for file preview
  content?: string;
  // New properties for tree view
  depth?: number;
  visible?: boolean;
  parentPath?: string;
  // New property for already transferred files
  alreadyTransferred?: boolean;
  // Cache properties for folder counts to optimize UI updates
  _fileCount?: number;
  _subfolderCount?: number;
  // Additional tracking properties for more reliable folder counts
  _needsCountRefresh?: boolean;
  _lastCountRefreshTime?: number;
}

export interface BreadcrumbItem {
  name: string;
  folder: FileItem | null;
}

@Component({
  selector: 'app-file-transfer-v2',
  templateUrl: './file-transfer-v2.component.html',
  styleUrls: ['./file-transfer-v2.component.scss'],

  encapsulation: ViewEncapsulation.None, // Add this line to disable view encapsulation
})
export class FileTransferV2Component implements OnInit {
  matterId: string = '00002_Mayerlen';

  // Source files from Clio
  /* clioFiles: FileItem[] = [
    { name: 'File1.pdf', type: 'file', selected: false, size: '56KB', date: '11/15/2023' },
    { name: 'Contract.docx', type: 'file', selected: false, size: '128KB', date: '11/20/2023' },
    { name: 'Spreadsheet.xlsx', type: 'file', selected: false, size: '340KB', date: '11/10/2023' },
    {
      name: 'Folder 1',
      type: 'folder',
      expanded: false,
      children: [
        { name: 'Document.pdf', type: 'file', selected: false, size: '14KB', date: '08/04/2023' },
        { name: 'Book.pdf', type: 'file', selected: false, size: '2.3MB', date: '09/12/2023' },
        { name: 'Evidence.jpg', type: 'image', selected: false, size: '1.4MB', date: '10/23/2023' },
        { name: 'Presentation.pptx', type: 'file', selected: false, size: '4.1MB', date: '10/15/2023' },
        {
          name: 'Subfolder A',
          type: 'folder',
          expanded: false,
          children: [
            { name: 'Notes.txt', type: 'file', selected: false, size: '2KB', date: '09/05/2023' },
            { name: 'Screenshot.png', type: 'image', selected: false, size: '800KB', date: '09/10/2023' },
            {
              name: 'Deep Folder',
              type: 'folder',
              expanded: false,
              children: [
                { name: 'Technical_Specs.pdf', type: 'file', selected: false, size: '1.2MB', date: '08/20/2023' },
                { name: 'Architecture.svg', type: 'image', selected: false, size: '45KB', date: '08/22/2023' },
                { name: 'Requirements.xlsx', type: 'file', selected: false, size: '78KB', date: '08/25/2023' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'Other',
      type: 'folder',
      expanded: false,
      children: [
        { name: 'Document.pdf', type: 'file', selected: false, size: '14KB', date: '08/04/2023' },
        { name: 'Report.pdf', type: 'file', selected: false, size: '4KB', date: '10/04/3' },
        { name: 'Logo.ai', type: 'image', selected: false, size: '2.1MB', date: '07/15/2023' },
        {
          name: 'References',
          type: 'folder',
          expanded: false,
          children: [
            { name: 'Bibliography.docx', type: 'file', selected: false, size: '32KB', date: '06/12/2023' },
            { name: 'Sources.pdf', type: 'file', selected: false, size: '420KB', date: '06/14/2023' },
            {
              name: 'Academic Papers',
              type: 'folder',
              expanded: false,
              children: [
                { name: 'Paper_2021.pdf', type: 'file', selected: false, size: '1.8MB', date: '05/10/2023' },
                { name: 'Paper_2022.pdf', type: 'file', selected: false, size: '2.1MB', date: '05/20/2023' },
                { name: 'Research_Data.csv', type: 'file', selected: false, size: '890KB', date: '05/22/2023' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'Case Materials',
      type: 'folder',
      expanded: false,
      children: [
        { name: 'Deposition.mp4', type: 'file', selected: false, size: '245MB', date: '10/05/2023' },
        { name: 'Transcript.pdf', type: 'file', selected: false, size: '1.2MB', date: '10/07/2023' },
        { name: 'Evidence_Photo_1.jpg', type: 'image', selected: false, size: '3.4MB', date: '10/03/2023' },
        { name: 'Evidence_Photo_2.jpg', type: 'image', selected: false, size: '2.8MB', date: '10/03/2023' },
        {
          name: 'Witness Statements',
          type: 'folder',
          expanded: false,
          children: [
            { name: 'Witness_A.pdf', type: 'file', selected: false, size: '450KB', date: '09/28/2023' },
            { name: 'Witness_B.pdf', type: 'file', selected: false, size: '380KB', date: '09/29/2023' },
            {
              name: 'Supporting Documents',
              type: 'folder',
              expanded: false,
              children: [
                { name: 'Timeline.xlsx', type: 'file', selected: false, size: '75KB', date: '09/15/2023' },
                { name: 'Location_Map.png', type: 'image', selected: false, size: '1.2MB', date: '09/16/2023' },
                { name: 'Analysis.docx', type: 'file', selected: false, size: '120KB', date: '09/18/2023' },
              ],
            },
          ],
        },
      ],
    },
  ]; */

  clioFiles: FileItem[] = [];

  // Breadcrumb navigation
  breadcrumbs: BreadcrumbItem[] = [{ name: 'Home', folder: null }];
  currentFolderContents: FileItem[] = [];

  // Selected files for transfer - initialize as empty
  selectedFiles: FileItem[] = [];

  // Destination files in NuageDx
  nuageDxFiles: FileItem[] = [];

  // Duplicated files
  duplicatedFiles: FileItem[] = [
    { name: 'Document.pdf', type: 'file', selected: true },
    { name: 'Document_1.pdf', type: 'file', selected: true },
  ];

  // Resolution option
  resolutionOptions = [
    { value: 'skip', label: 'Skip duplicates' },
    { value: 'rename', label: 'Rename' },
    { value: 'keep', label: 'Keep both', checked: true },
  ];

  // Selected resolution option - initialize with the checked option
  selectedResolutionOption: string = 'keep';

  // Track if there are exact duplicates
  hasExactDuplicates: boolean = false;

  // Flag to toggle visibility of duplicates panel and warning icons
  showDuplicatesPanel: boolean = false;

  // Debug flag
  showDebugInfo: boolean = false;

  // Add properties for file preview
  previewFileItem: FileItem | null = null;
  showFilePreview: boolean = false;

  // Add property for destination panel visibility
  showDestinationPanel: boolean = false;

  // Add properties for search functionality
  showSearchInput: boolean = false;
  searchQuery: string = '';
  filteredFolderContents: FileItem[] = [];

  // New property to track all flattened items for tree view
  flattenedItems: FileItem[] = [];

  // New property to track the current active path
  currentActivePath: string = '';

  // Add loading state properties
  loadingFolderId: string | null = null;

  constructor(
    private dialogRef: MatDialogRef<FileTransferV2Component>,
    private matters_$: MattersService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {}

  getMatterContents(matterId: string): Promise<FileItem[]> {
    // Fetch matter contents from the service
    return this.matters_$
      .getMatterContentV2(matterId)
      .then((response: any) => {
        if (response.data.code === 200) {
          const data = response.data.data.data;

          const convertBytestToKB = (bytes: number): string => {
            if (bytes === 0) return '0 KB';
            const kB = bytes / 1024;
            return kB.toFixed(2) + ' KB';
          };

          const checkAlreadyTransferred = (id: string): boolean => {
            // Check if the file is already transferred
            return this.nuageDxFiles.some(item => item.name === id);
          };

          // Format the data.
          const newData = data.map((item: any) => {
            return {
              name: item.name,
              type: item.type === 'Document' ? 'file' : 'folder',
              size: item.latest_document_version ? `${convertBytestToKB(item.latest_document_version.size)}` : '0 KB',
              date: item.latest_document_version
                ? new Date(item.latest_document_version.created_at).toLocaleDateString()
                : new Date().toLocaleDateString(),
              selected: false,
              alreadyTransferred: checkAlreadyTransferred(item.id),
              expanded: false,
              children: item.children || [],
            };
          });

          console.log('🚀 ~ FileTransferV2Component ~ .then ~ data:', data, newData);

          return newData;
          // Transform the data into FileItem format
          // this.clioFiles = this.transformDataToFileItems(data);
          // this.flattenedItems = this.clioFiles;
          // this.flattenedItems = newData;
          // console.log('Matter contents fetched successfully:', this.clioFiles);
        } else {
          console.error('Error fetching matter contents:', response.data.message);
        }
      })
      .catch(error => {
        console.error('Error fetching matter contents:', error);
      });
  }

  async ngOnInit() {
    this.clioFiles = await this.getMatterContents(this.data.clioFolderId);

    // Initialize the current folder contents with root level files
    this.updateCurrentFolderContents();

    // Make sure only one folder is expanded initially
    this.ensureSingleExpandedFolder();

    // Ensure selectedFiles is empty initially
    this.selectedFiles = [];

    // Check for duplicates
    this.updateDuplicatesState();

    // Initialize selected resolution option
    this.selectedResolutionOption = this.resolutionOptions.find(opt => opt.checked)?.value || 'keep';

    // Sync folder selection states with their children
    this.updateAllFolderSelectionStates();

    // Initialize showDuplicatesPanel to false
    this.showDuplicatesPanel = false;

    // Initialize the flattened items array
    this.updateFlattenedItems();

    // Initialize filtered contents with all contents
    this.filteredFolderContents = [...this.currentFolderContents];
  }

  // Add method for opening file preview
  openFilePreview(file: FileItem): void {
    if (file.type === 'folder') return;

    // Set mock content based on file type
    if (!file.content) {
      if (file.type === 'image') {
        file.content =
          'This is an image preview placeholder. In a real application, an actual image would be displayed here.';
      } else {
        file.content = `This is the content of ${file.name}. In a real application, the actual file content would be retrieved and displayed here.`;
      }
    }

    this.previewFileItem = file;
    this.showFilePreview = true;
    console.log(`Opened preview for file: ${file.name}`);
  }

  // Add method for closing file preview
  closeFilePreview(): void {
    this.showFilePreview = false;
    this.previewFileItem = null;
  }

  /**
   * Updates the contents of the current folder based on breadcrumb path
   */
  updateCurrentFolderContents(): void {
    // Start with the root level files
    let contents: FileItem[] = [...this.clioFiles];

    // If we're not at the root, navigate to the current folder
    if (this.breadcrumbs.length > 1) {
      for (let i = 1; i < this.breadcrumbs.length; i++) {
        const breadcrumb = this.breadcrumbs[i];
        if (breadcrumb.folder && breadcrumb.folder.children) {
          contents = [...breadcrumb.folder.children];
        }
      }
    }

    this.currentFolderContents = contents;

    // Update filtered contents as well (will respect any active search)
    this.filterFiles();
  }

  /**
   * Navigate to a specific folder
   */
  async navigateToFolder(folder: FileItem): Promise<void> {
    if (folder.type !== 'folder') return;

    // Set loading state for this specific folder
    this.loadingFolderId = folder.name;

    try {
      // Wait for the async operation to complete
      await this.fetchFolderContents(folder);

      // Expand the folder if it's not already expanded
      if (!folder.expanded) {
        folder.expanded = true;
        this.updateItemVisibility();
      }

      // Update breadcrumbs
      this.updateBreadcrumbsForFolder(folder);

      // Update the current folder contents
      this.currentFolderContents = folder.children || [];

      // Update filtered contents as well
      this.filterFiles();

      // Clear any active search when navigating
      this.clearSearch();

      console.log(`Navigated to folder: ${folder.name}`);
    } catch (error) {
      console.error(`Error navigating to folder ${folder.name}:`, error);
    } finally {
      // Clear loading state
      this.loadingFolderId = null;
    }
  }

  /**
   * Navigate to a specific breadcrumb
   */
  async navigateToBreadcrumb(index: number): Promise<void> {
    if (index >= this.breadcrumbs.length) return;

    // Get the target breadcrumb
    const targetBreadcrumb = this.breadcrumbs[index];

    try {
      // Set loading state if navigating to a folder (not root)
      if (index > 0 && targetBreadcrumb.folder) {
        this.loadingFolderId = targetBreadcrumb.folder.name;

        // Wait for the async operation to complete
        await this.fetchFolderContents(targetBreadcrumb.folder);
      }

      // Collapse all folders
      this.flattenedItems.forEach(item => {
        if (item.type === 'folder') {
          item.expanded = false;
        }
      });

      // If this is not the root, expand the target folder
      if (index > 0 && targetBreadcrumb.folder) {
        targetBreadcrumb.folder.expanded = true;

        // Also expand all parent folders
        this.expandParentFolders(targetBreadcrumb.folder);
      }

      // Update visibility
      this.updateItemVisibility();

      // Remove breadcrumbs after the clicked one
      this.breadcrumbs = this.breadcrumbs.slice(0, index + 1);

      // Update current folder contents
      if (index === 0) {
        // Root level
        this.currentFolderContents = this.clioFiles;
      } else if (targetBreadcrumb.folder && targetBreadcrumb.folder.children) {
        // Specific folder
        this.currentFolderContents = targetBreadcrumb.folder.children;
      }

      // Update filtered contents
      this.filterFiles();

      // Clear any active search when navigating
      this.clearSearch();

      console.log(`Navigated to breadcrumb: ${targetBreadcrumb.name}`);
    } catch (error) {
      console.error(`Error navigating to breadcrumb:`, error);
    } finally {
      // Clear loading state
      this.loadingFolderId = null;
    }
  }

  /**
   * Get the currently active folder (last folder in breadcrumbs)
   */
  getCurrentFolder(): FileItem | null {
    if (this.breadcrumbs.length <= 1) return null;
    return this.breadcrumbs[this.breadcrumbs.length - 1].folder;
  }

  /**
   * Toggles the visibility of the duplicates panel and warning icons
   */
  toggleShowDuplicates(): void {
    this.showDuplicatesPanel = !this.showDuplicatesPanel;
    console.log(`Duplicates panel visibility: ${this.showDuplicatesPanel}`);
  }

  /**
   * Toggles folder expansion state
   * Collapses any other expanded folders to ensure only one is open at a time
   * No longer selects files - only handles expansion/collapse
   */
  async toggleFolder(folder: FileItem): Promise<void> {
    // If we're collapsing, no need for loading state or async operation
    if (folder.expanded) {
      folder.expanded = false;
      this.updateItemVisibility();

      // Check if this folder is in the current breadcrumb path
      const breadcrumbIndex = this.breadcrumbs.findIndex(b => b.folder === folder);
      if (breadcrumbIndex > 0) {
        // This folder is part of the current path - adjust breadcrumbs

        // If this is the current folder (last in breadcrumbs), navigate to its parent
        if (breadcrumbIndex === this.breadcrumbs.length - 1) {
          // Navigate to parent by removing the last breadcrumb
          const parentIndex = breadcrumbIndex - 1;
          if (parentIndex > 0) {
            // Navigate to parent folder
            this.navigateToBreadcrumb(parentIndex);
          } else {
            // Navigate to root
            this.navigateToBreadcrumb(0);
          }
        }
        // If this is a folder in the middle of the path, truncate breadcrumbs at this point
        else {
          // Keep breadcrumbs up to and including this folder
          this.breadcrumbs = this.breadcrumbs.slice(0, breadcrumbIndex + 1);
          // Update current folder contents to this folder's children
          if (folder.children) {
            this.currentFolderContents = folder.children;
            this.filterFiles();
          }
        }
      }

      return;
    }

    // Set loading state for this specific folder
    this.loadingFolderId = folder.name;

    try {
      // Wait for the async operation to complete
      await this.fetchFolderContents(folder);

      // Toggle the expansion state
      folder.expanded = true;

      // Get the parent path of the current folder
      const parentPath = folder.parentPath || '';

      // Find sibling folders (folders with the same parent path)
      this.flattenedItems
        .filter(item => item.type === 'folder' && item !== folder && item.parentPath === parentPath)
        .forEach(item => {
          item.expanded = false;
        });

      // Update breadcrumbs when expanding a folder
      this.updateBreadcrumbsForFolder(folder);

      // Update visibility of items
      this.updateItemVisibility();

      console.log(`Toggled folder ${folder.name} expansion: ${folder.expanded}`);
    } catch (error) {
      console.error(`Error toggling folder ${folder.name}:`, error);
    } finally {
      // Clear loading state
      this.loadingFolderId = null;
    }
  }

  /**
   * Toggle folder selection and selects/deselects all child files
   * Modified to properly update child selection states in the UI
   * and prevent adding children to selectedFiles array when folder is selected
   */
  toggleFolderSelection(folder: FileItem, newSelectionState: boolean): void {
    // The newSelectionState parameter now contains the new checkbox state from ngModelChange
    console.log(`Folder ${folder.name} selection changed to: ${newSelectionState}`);

    // Add or remove the folder itself from selected items
    if (newSelectionState) {
      // Add folder to selected items if not already there
      if (
        !this.selectedFiles.some(
          item => item.type === 'folder' && item.name === folder.name && item.parentPath === folder.parentPath,
        )
      ) {
        const selectedFolder = {
          ...folder,
          selected: true,
          parentFolder: folder.parentPath, // Store the parent path as parentFolder for consistency
        };

        this.selectedFiles.push(selectedFolder);
        console.log(`Added folder to selected list: ${selectedFolder.name}`);
      }
    } else {
      // Remove folder from selected items
      this.selectedFiles = this.selectedFiles.filter(
        item => !(item.type === 'folder' && item.name === folder.name && item.parentFolder === folder.parentPath),
      );
    }

    // Find the actual folder in the flattened items to ensure UI consistency
    const actualFolder = this.findFolderInSource(folder.name, folder.parentPath || '');

    // Skip processing if no children or folder not found
    if (!actualFolder || !actualFolder.children || actualFolder.children.length === 0) return;

    // Update selection state on the actual folder
    actualFolder.selected = newSelectionState;

    // Update UI state of children recursively - but DON'T add them to selectedFiles when parent is selected
    this.updateChildrenUIState(actualFolder, newSelectionState);

    // Also update all children in the flattened items array for visual consistency
    this.updateChildrenInFlattenedItems(folder, newSelectionState);

    // Update duplicates state
    this.updateDuplicatesState();
  }

  /**
   * Updates only the UI state of children without adding them to selectedFiles
   */
  updateChildrenUIState(folder: FileItem, selected: boolean): void {
    if (!folder.children) return;

    folder.children.forEach(child => {
      // Update child's selection state
      child.selected = selected;

      // If deselecting, remove any child files from selectedFiles
      if (!selected) {
        if (child.type === 'file' || child.type === 'image') {
          // Remove from selected files
          this.selectedFiles = this.selectedFiles.filter(
            item => !(item.name === child.name && item.parentFolder === folder.name),
          );
        }
      }

      // Recursively process nested folders
      if (child.type === 'folder' && child.children) {
        this.updateChildrenUIState(child, selected);
      }
    });

    // Always update the folder in the selected files list to ensure counts are correct
    if (selected) {
      this.forceUpdateFolderInSelectedFiles(folder.name);
    }
  }

  /**
   * Removes all files from a specific folder from the selectedFiles array
   */
  removeSelectedFilesFromFolder(folder: FileItem): void {
    if (!folder.children) return;
    // Get the folder name for identification
    const folderName = folder.name;
    // Remove all files from this folder
    this.selectedFiles = this.selectedFiles.filter(item => item.parentFolder !== folderName);
    // Update duplicates state
    this.updateDuplicatesState();
  }

  /**
   * Check if all files in a folder are selected
   */
  areAllFolderFilesSelected(folder: FileItem): boolean {
    if (!folder.children || folder.children.length === 0) return false;
    return folder.children.every(child => child.selected);
  }

  /**
   * Check if any files in a folder are selected
   */
  areSomeFolderFilesSelected(folder: FileItem): boolean {
    if (!folder.children || folder.children.length === 0) return false;
    return folder.children.some(child => child.selected) && !folder.children.every(child => child.selected);
  }

  /**
   * Collapses all folders in the clioFiles list
   */
  collapseAllFolders(): void {
    this.collapseAllFoldersInList(this.clioFiles);
    this.collapseAllFoldersInList(this.nuageDxFiles);
  }

  /**
   * Collapses all folders in a given file list
   */
  collapseAllFoldersInList(fileList: FileItem[]): void {
    fileList.forEach(item => {
      if (item.type === 'folder') {
        item.expanded = false;
        // Recursively collapse nested folders if any
        if (item.children) {
          this.collapseAllFoldersInList(item.children.filter(child => child.type === 'folder') as FileItem[]);
        }
      }
    });
  }

  /**
   * Ensures only a single folder is expanded initially
   */
  ensureSingleExpandedFolder(): void {
    let foundExpanded = false;
    // Process clioFiles
    for (let i = 0; i < this.clioFiles.length; i++) {
      const item = this.clioFiles[i];
      if (item.type === 'folder') {
        if (item.expanded && !foundExpanded) {
          foundExpanded = true;
        } else {
          item.expanded = false;
        }
      }
    }
    // Process nuageDxFiles only if no expanded folder was found in clioFiles
    if (!foundExpanded) {
      for (let i = 0; i < this.nuageDxFiles.length; i++) {
        const item = this.nuageDxFiles[i];
        if (item.type === 'folder') {
          if (item.expanded && !foundExpanded) {
            foundExpanded = true;
          } else {
            item.expanded = false;
          }
        }
      }
    }
  }

  /**
   * Select all files in the current view
   * Modified to work with breadcrumb navigation and respect alreadyTransferred property
   */
  selectAll(): void {
    const currentFolder = this.getCurrentFolder();

    if (currentFolder) {
      // We're in a subfolder, select/deselect all files in this folder
      const hasSelectedFiles = currentFolder.children && currentFolder.children.some(item => item.selected);

      // Toggle selection state
      const newSelectionState = !hasSelectedFiles;

      // Update folder selection state
      currentFolder.selected = newSelectionState;

      // Update all children
      if (currentFolder.children) {
        // Update selection state for children
        currentFolder.children.forEach(child => {
          // Only update selection state if not already transferred
          if (!this.isFileAlreadyTransferred(child)) {
            child.selected = newSelectionState;

            // Update selectedFiles list
            if (newSelectionState) {
              this.addFileToSelectedList(child, currentFolder.name);
            } else {
              this.selectedFiles = this.selectedFiles.filter(
                item => !(item.name === child.name && item.parentFolder === currentFolder.name),
              );
            }
          }
        });
      }
    } else {
      // We're at the root level
      // Get the current selection state (if any files are selected)
      const hasSelectedFiles = this.currentFolderContents.some(item => {
        if (item.type === 'file') return item.selected;
        if (item.type === 'folder' && item.children) {
          return item.children.some(child => child.selected);
        }
        return false;
      });

      // Toggle selection state
      const newSelectionState = !hasSelectedFiles;

      // Update selection for current view items
      this.currentFolderContents.forEach(item => {
        if ((item.type === 'file' || item.type === 'image') && !this.isFileAlreadyTransferred(item)) {
          item.selected = newSelectionState;
          if (newSelectionState) {
            this.addFileToSelectedList(item, null);
          } else {
            this.selectedFiles = this.selectedFiles.filter(
              selected => !(selected.name === item.name && !selected.parentFolder),
            );
          }
        }
      });
    }

    // Update duplicates state
    this.updateDuplicatesState();
  }

  /**
   * Updates selection state for all files in a list
   */
  updateSelectionForAllFiles(fileList: FileItem[], selected: boolean): void {
    fileList.forEach(item => {
      if (item.type === 'file' || item.type === 'image') {
        item.selected = selected;
      } else if (item.type === 'folder' && item.children) {
        item.selected = selected;
        this.updateSelectionForAllFiles(item.children, selected);
      }
    });
  }

  /**
   * Adds all files from a list to the selected files list
   */
  addAllFilesToSelected(fileList: FileItem[]): void {
    fileList.forEach(item => {
      if ((item.type === 'file' || item.type === 'image') && !this.isFileInSelectedList(item)) {
        this.selectedFiles.push({
          ...item,
          size: item.size || '0KB',
          date: item.date || this.getCurrentDate(),
          isDuplicate: this.checkIfDuplicate(item.name),
        });
      } else if (item.type === 'folder' && item.children) {
        this.addAllFilesToSelected(item.children);
      }
    });
  }

  /**
   * Helper method to add a file to the selected files list
   * Modified to be more robust for files from nested folders and prevent
   * adding files that are already covered by a selected parent folder
   */
  private addFileToSelectedList(file: FileItem, parentFolderName: string): void {
    // Check if the parent folder is already selected - if so, don't add the file individually
    const isParentFolderSelected = this.selectedFiles.some(
      item => item.type === 'folder' && item.name === parentFolderName && !item.parentFolder,
    );

    if (isParentFolderSelected) {
      // Don't add individual file if parent folder is already selected
      // But make sure the file's UI state is updated
      file.selected = true;
      return;
    }

    // Check if file is already in the selected list
    const existingFile = this.selectedFiles.find(
      item => item.name === file.name && item.parentFolder === parentFolderName && item.type === file.type,
    );

    if (!existingFile) {
      // Create a copy with additional info
      const selectedFile = {
        ...file,
        size: file.size || '0KB',
        date: file.date || this.getCurrentDate(),
        isDuplicate: this.checkIfDuplicate(file.name),
        parentFolder: parentFolderName,
      };
      this.selectedFiles.push(selectedFile);
      console.log(`Added file to selected list: ${selectedFile.name} from ${parentFolderName || 'Root'}`);
    }
  }

  /**
   * Removes a file from the selected files list
   * Modified to handle files with the same name from different folders
   */
  removeSelectedFile(file: FileItem): void {
    // Remove specific file instance from selectedFiles array
    this.selectedFiles = this.selectedFiles.filter(
      item => !(item.name === file.name && item.parentFolder === file.parentFolder),
    );
    // Also update the selection state in the original file list
    this.updateSourceFileSelectionState(file, false);
    // Update duplicates state
    this.updateDuplicatesState();
  }

  /**
   * Updates a file's selection state in the original file list
   * Modified to handle files with the same name from different folders
   */
  updateFileSelectionInList(fileList: FileItem[], fileToUpdate: FileItem): void {
    const parentFolderName = fileToUpdate.parentFolder;
    // If we know the parent folder, use it to find the exact file
    if (parentFolderName) {
      // Find the parent folder first
      for (const item of fileList) {
        if (item.type === 'folder' && item.name === parentFolderName && item.children) {
          // Find and update the file in this folder
          const fileInFolder = item.children.find(child => child.name === fileToUpdate.name);
          if (fileInFolder) {
            fileInFolder.selected = false;
            return;
          }
        }
      }
    }
    // Fallback to the old method if parent folder approach fails
    fileList.forEach(item => {
      if (
        (item.type === 'file' || item.type === 'image') &&
        item.name === fileToUpdate.name &&
        item.size === fileToUpdate.size &&
        item.date === fileToUpdate.date
      ) {
        item.selected = false;
      } else if (item.type === 'folder' && item.children) {
        this.updateFileSelectionInList(item.children, fileToUpdate);
      }
    });
  }

  /**
   * Toggle file selection
   * Modified to handle files with the same name from different folders
   * and respect alreadyTransferred flag and parent folder selection state
   */
  toggleFileSelection(file: FileItem): void {
    // Don't allow selection of already transferred files
    if (file.alreadyTransferred) {
      file.selected = false;
      return;
    }

    // Find the parent folder for proper identification - do this regardless of selection state
    const parentFolder = this.findParentFolder(this.clioFiles, file);
    const parentFolderName = parentFolder ? parentFolder.name : null;

    // Check if the parent folder is already selected
    const isParentSelected = this.selectedFiles.some(
      item => item.type === 'folder' && item.name === parentFolderName && !item.parentFolder,
    );

    // When parent folder is selected, don't add/remove individual files to the selectedFiles list
    // Just update the UI state
    if (isParentSelected) {
      // When the parent folder is selected, the file selection state only affects UI
      // We don't need to modify the selectedFiles array
      console.log(
        `File ${file.name} selection toggled, but parent folder is already selected. Only updating UI state.`,
      );

      // Update parent folder's selection state and counts if necessary
      if (parentFolder) {
        this.updateFolderSelectionState(parentFolder);
        this.forceUpdateParentFolderInSelectedFiles(parentFolderName);
      }

      return;
    }

    // Normal handling when parent folder is NOT selected
    if (file.selected) {
      // If file is selected, add it to the selectedFiles array if not already there
      // Create a copy with additional info to track its origin
      const selectedFile = {
        ...file,
        size: file.size || '0KB',
        date: file.date || this.getCurrentDate(),
        isDuplicate: this.checkIfDuplicate(file.name),
        parentFolder: parentFolderName, // Add parent folder info
      };

      // Check if this exact file is already in selected list
      if (!this.selectedFiles.some(item => item.name === file.name && item.parentFolder === parentFolderName)) {
        this.selectedFiles.push(selectedFile);
        console.log(
          `Selected file: ${selectedFile.name} from ${parentFolderName || 'root'} (${selectedFile.size}, ${selectedFile.date})`,
        );
      }
    } else {
      // When deselecting a file, remove it from selectedFiles
      this.selectedFiles = this.selectedFiles.filter(
        item => !(item.name === file.name && item.parentFolder === parentFolderName),
      );
    }

    // Always update parent folder selection state if there is a parent folder
    if (parentFolder) {
      this.updateFolderSelectionState(parentFolder);

      // Check if parent folder is in the selectedFiles list and update its counts
      const parentFolderInSelectedList = this.selectedFiles.find(
        item => item.type === 'folder' && item.name === parentFolderName,
      );

      if (parentFolderInSelectedList) {
        // Force update the folder in the selected files to refresh its subtitle counts
        this.forceUpdateParentFolderInSelectedFiles(parentFolderName);
      }
    }

    // Update duplicates state
    this.updateDuplicatesState();
  }

  /**
   * Checks if a file is already in the selected files list
   * Modified to check by reference instead of just by name
   */
  isFileInSelectedList(file: FileItem): boolean {
    // Create a unique identifier for the file including path information if available
    const fileId = file.path ? `${file.path}/${file.name}` : file.name;
    // For files in folders, we need to track which folder they came from
    if (!file.path && file.type !== 'folder') {
      // Get all parent folders
      const parentFolder = this.findParentFolder(this.clioFiles, file);
      if (parentFolder) {
        const folderPath = parentFolder.name;
        return this.selectedFiles.some(item => item.name === file.name && item.parentFolder === folderPath);
      }
    }
    // If no path or parent folder, just check by name and content equality
    return this.selectedFiles.some(
      item =>
        item.name === file.name &&
        item.size === file.size &&
        item.date === file.date &&
        item.parentFolder === file.parentFolder,
    );
  }

  /**
   * Find the parent folder of a file
   */
  findParentFolder(fileList: FileItem[], targetFile: FileItem): FileItem | null {
    for (const item of fileList) {
      if (item.type === 'folder' && item.children) {
        for (const child of item.children) {
          if (child === targetFile) {
            return item;
          }
        }
        // Recursive search in nested folders
        const foundInNestedFolder = this.findParentFolder(
          item.children.filter(child => child.type === 'folder') as FileItem[],
          targetFile,
        );
        if (foundInNestedFolder) {
          return foundInNestedFolder;
        }
      }
    }
    return null;
  }

  /**
   * Gets the current date formatted as MM/DD/YYYY
   */
  getCurrentDate(): string {
    const date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  /**
   * Check if a file is a duplicate in NuageDx files
   * This checks only by name for the warning icon
   */
  checkIfDuplicate(fileName: string): boolean {
    // Check if the file exists in any of the NuageDx folders
    for (const folder of this.nuageDxFiles) {
      if (folder.children && folder.children.some(file => file.name === fileName)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check for exact duplicates (name, size, and date match)
   * This now correctly checks for duplicates across different folders
   */
  checkForExactDuplicates(): boolean {
    // Use the debug version for all checks to ensure consistency
    return this.checkForExactDuplicatesDebug();
  }

  /**
   * Check for exact duplicates with extended debugging
   */
  checkForExactDuplicatesDebug(): boolean {
    // Reset duplicate flags on all selected files
    this.selectedFiles.forEach(file => {
      file.isDuplicate = false;
    });
    // Update duplicatedFiles array with exact duplicates
    this.duplicatedFiles = [];
    console.log(`Checking ${this.selectedFiles.length} files for duplicates...`);
    // Early return if we don't have enough files to have duplicates
    if (this.selectedFiles.length < 2) {
      console.log('Not enough files to check for duplicates');
      return false;
    }
    // Group by name first for easier debugging
    const filesByName = new Map<string, FileItem[]>();
    for (const file of this.selectedFiles) {
      if (!filesByName.has(file.name)) {
        filesByName.set(file.name, []);
      }
      filesByName.get(file.name)?.push(file);
    }
    // Log files grouped by name
    console.log('Files grouped by name:');
    filesByName.forEach((files, name) => {
      console.log(`${name}: ${files.length} files`);
    });
    // Check each name group for exact duplicates
    filesByName.forEach((files, name) => {
      if (files.length > 1) {
        // Group by size and date
        const exactMatches = new Map<string, FileItem[]>();
        for (const file of files) {
          const key = `${file.size}_${file.date}`;
          if (!exactMatches.has(key)) {
            exactMatches.set(key, []);
          }
          exactMatches.get(key)?.push(file);
        }
        // Check each group for exact duplicates (same name, size, date)
        exactMatches.forEach((exactFiles, key) => {
          console.log(`Group ${name} with ${key}: ${exactFiles.length} files`);
          if (exactFiles.length > 1) {
            // We have duplicates!
            console.log(`FOUND DUPLICATES: ${exactFiles.length} files with name=${name}, size/date=${key}`);
            // Mark all files in group as duplicates
            exactFiles.forEach(file => {
              file.isDuplicate = true;
              // Add each duplicate file individually to the duplicatedFiles array
              this.duplicatedFiles.push({
                name: file.name,
                type: file.type,
                selected: true,
                size: file.size,
                date: file.date,
                parentFolder: file.parentFolder,
              });
            });
          }
        });
      }
    });
    return this.duplicatedFiles.length > 0;
  }

  /**
   * Update the duplicates state whenever selection changes
   */
  updateDuplicatesState(): void {
    this.hasExactDuplicates = this.checkForExactDuplicates();
  }

  /**
   * Called when transferring files to verify duplicates state
   */
  transferSelectedFiles(): void {
    // Show the destination panel
    this.showDestinationPanel = true;

    // Force update duplicates state before transfer
    this.updateDuplicatesState();
    console.log('Transferring files...', {
      selectedFiles: this.selectedFiles,
      hasDuplicates: this.hasExactDuplicates,
      duplicatedFiles: this.duplicatedFiles,
    });
  }

  cancelTransfer(): void {
    // Logic for canceling transfer
    console.log('Transfer canceled');
  }

  changeResolutionOption(option: string): void {
    this.resolutionOptions.forEach(opt => {
      opt.checked = opt.value === option;
    });
    this.selectedResolutionOption = option;
  }

  /**
   * Gets a preview of a renamed file for display purposes
   * Uses a simple pattern with underscore and number
   */
  getRenamingPreview(fileName: string): string {
    if (!fileName) return '';
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return `${fileName}_1`;
    } else {
      const name = fileName.substring(0, lastDotIndex);
      const extension = fileName.substring(lastDotIndex);
      return `${name}_1${extension}`;
    }
  }

  /**
   * Navigate back to Clio
   */
  navigateBackToClio(): void {
    console.log('Navigating back to Clio');
    // Implement actual navigation logic here
  }

  closeDialog(): void {
    console.log('Closing dialog');
    this.dialogRef.close();
  }

  /**
   * Toggle debug panel
   */
  toggleDebug(): void {
    this.showDebugInfo = !this.showDebugInfo;
    if (this.showDebugInfo) {
      // Update duplicates state when debug panel is shown
      this.updateDuplicatesState();
    }
  }

  /**
   * Called when a file's checkbox is changed in the selected files panel
   */
  onSelectedFileCheckboxChange(file: FileItem): void {
    // Check if the file is inside a selected folder
    const parentFolderName = file.parentFolder;
    const isParentSelected =
      parentFolderName &&
      this.selectedFiles.some(item => item.type === 'folder' && item.name === parentFolderName && !item.parentFolder);

    // If the parent folder is already selected, only update UI state, not the selectedFiles array
    if (isParentSelected) {
      // Update UI state only - no need to add/remove individual files when the folder is selected
      const parentFolder = this.findFolderInSource(parentFolderName, '');
      if (parentFolder) {
        // Update the file's UI state in the source data
        const fileInFolder = parentFolder.children?.find(child => child.name === file.name);
        if (fileInFolder) {
          fileInFolder.selected = file.selected;
        }

        // Update folder selection state based on children
        this.updateFolderSelectionState(parentFolder);

        // Update the parent folder's counts in the selected files list
        this.forceUpdateParentFolderInSelectedFiles(parentFolderName);

        console.log(
          `File ${file.name} selection updated, but parent folder ${parentFolderName} is already selected. Only updating UI state.`,
        );
      }

      // Update UI and return early - don't modify the selectedFiles array
      this.updateAllFolderSelectionStates();
      this.updateFlattenedItems();
      this.updateItemVisibility();
      return;
    }

    // Normal handling for files not inside selected folders
    if (!file.selected) {
      if (file.type === 'folder') {
        // For folders, find the actual folder in the source tree
        const sourceFolder = this.findFolderInSource(file.name, file.parentFolder || '');

        if (sourceFolder) {
          // Update the folder's selection state
          sourceFolder.selected = false;

          // Update all its children recursively
          this.updateChildrenSelectionState(sourceFolder, false);

          // Also update all children in the flattened items array for visual consistency
          const folderAsItem = {
            ...sourceFolder,
            parentPath: file.parentFolder || '',
          };
          this.updateChildrenInFlattenedItems(folderAsItem, false);

          // Remove the folder itself from selected files
          this.selectedFiles = this.selectedFiles.filter(
            item => !(item.type === 'folder' && item.name === file.name && item.parentFolder === file.parentFolder),
          );

          // Remove all its children from selected files
          this.removeFilesFromFolder(sourceFolder);
        }
      } else {
        // Regular file handling
        this.updateSourceFileSelectionState(file, false);
        this.removeSelectedFile(file);

        // If the file belongs to a folder, update the folder's selection state
        if (file.parentFolder) {
          const parentFolder = this.findFolderInSource(file.parentFolder, '');
          if (parentFolder) {
            this.updateFolderSelectionState(parentFolder);

            // Update the parent folder in the selected files list to refresh its file count display
            this.forceUpdateParentFolderInSelectedFiles(file.parentFolder);
          }
        }
      }
    } else {
      // Handle selecting a file that was previously deselected
      this.updateSourceFileSelectionState(file, true);

      // If it's not a folder and the parent folder isn't selected, add to selectedFiles
      if (file.type !== 'folder' && !isParentSelected) {
        this.addFileToSelectedList(file, file.parentFolder || null);
      }

      // If file's parent folder exists in selectedFiles, update it
      if (file.parentFolder) {
        this.forceUpdateParentFolderInSelectedFiles(file.parentFolder);
      }
    }

    // Update folder selection states to ensure UI consistency
    this.updateAllFolderSelectionStates();

    // Update the flattened items to reflect selection changes
    this.updateFlattenedItems();

    // Update visibility of items
    this.updateItemVisibility();

    // Ensure duplicates state is updated
    this.updateDuplicatesState();

    // Always refresh all folder references to ensure folder counts are updated
    this.refreshAllFolderReferences();
  }

  /**
   * Updates the source file selection state after a selection in the selected files panel
   * Enhanced with better folder reference handling to ensure counts update properly
   */
  updateSourceFileSelectionState(file: FileItem, selected: boolean): void {
    // First try to find the file in the flattened items
    const flatItem = this.flattenedItems.find(
      item =>
        item.name === file.name &&
        item.parentPath === (file.parentFolder ? file.parentFolder : '') &&
        item.type !== 'folder',
    );

    if (flatItem) {
      flatItem.selected = selected;

      // Find parent folder to update its status
      if (file.parentFolder) {
        // Mark the parent folder as needing a refresh
        const parentFolderInFlattened = this.flattenedItems.find(
          item => item.type === 'folder' && item.name === file.parentFolder && !item.parentPath,
        );

        if (parentFolderInFlattened) {
          parentFolderInFlattened._needsCountRefresh = true;
          parentFolderInFlattened._lastCountRefreshTime = Date.now();
        }

        // Update any parent folder in the selectedFiles list to refresh its UI
        this.forceUpdateFolderInSelectedFiles(file.parentFolder);
      }

      return;
    }

    // Fall back to the old method as a safety net
    const parentFolderName = file.parentFolder;
    let fileFound = false;

    if (parentFolderName) {
      for (const item of this.clioFiles) {
        if (item.type === 'folder' && item.name === parentFolderName && item.children) {
          const fileInFolder = item.children.find(child => child.name === file.name);
          if (fileInFolder) {
            fileInFolder.selected = selected;
            fileFound = true;

            // Mark the folder as needing count refresh
            item._needsCountRefresh = true;
            item._lastCountRefreshTime = Date.now();

            // Update folder selection state based on children
            this.updateFolderSelectionState(item);

            // Force update the folder in the selected files list
            this.forceUpdateFolderInSelectedFiles(parentFolderName);

            break;
          }
        }
      }
    }

    // If the file wasn't found by parent folder, try to find it by name, size, and date
    if (!fileFound) {
      this.findAndUpdateFileInList(this.clioFiles, file, selected);
    }
  }

  /**
   * Helper method to find and update a file's selection state in a file list
   */
  findAndUpdateFileInList(fileList: FileItem[], fileToUpdate: FileItem, selected: boolean): boolean {
    for (const item of fileList) {
      if (
        (item.type === 'file' || item.type === 'image') &&
        item.name === fileToUpdate.name &&
        (item.size === fileToUpdate.size || !item.size) &&
        (item.date === fileToUpdate.date || !item.date)
      ) {
        item.selected = selected;
        return true;
      } else if (item.type === 'folder' && item.children) {
        if (this.findAndUpdateFileInList(item.children, fileToUpdate, selected)) {
          // Update folder selection state based on children
          this.updateFolderSelectionState(item);
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Update folder selection state based on its children's selection states
   */
  updateFolderSelectionState(folder: FileItem): void {
    if (!folder.children || folder.children.length === 0) return;
    // Set folder selected state to true if all children are selected,
    // false if none are selected
    const allSelected = folder.children.every(child => child.selected);
    const noneSelected = folder.children.every(child => !child.selected);
    if (allSelected) {
      // When all children are selected, the folder must be selected
      folder.selected = true;
      console.log(`Folder ${folder.name} is fully selected`);
    } else if (noneSelected) {
      folder.selected = false;
      console.log(`Folder ${folder.name} is fully unselected`);
    } else {
      console.log(`Folder ${folder.name} is partially selected`);
      // Leave indeterminate state (handled in the template)
    }
  }

  /**
   * Sync folder selection states with their children
   */
  syncFolderWithChildrenSelection(): void {
    // Update all folder selection states based on their children
    this.clioFiles.forEach(item => {
      if (item.type === 'folder' && item.children) {
        this.updateFolderSelectionState(item);
      }
    });
  }

  /**
   * Update all folder selection states to match their children's selection
   * Called at important points to ensure UI consistency
   */
  updateAllFolderSelectionStates(): void {
    // Process all folders to sync their selected state with their children
    this.clioFiles.forEach(item => {
      if (item.type === 'folder' && item.children) {
        this.updateFolderSelectionState(item);
      }
    });
  }

  /**
   * Navigate to matter details when matter ID is clicked
   */
  navigateToMatter(): void {
    console.log(`Navigating to matter details: ${this.matterId}`);
    // Implement actual navigation logic here
    // This could redirect to another component or external URL
  }

  /**
   * Toggle search input visibility
   */
  toggleSearch(): void {
    this.showSearchInput = !this.showSearchInput;

    // If search is being shown, focus the input after a brief delay to allow animation
    if (this.showSearchInput) {
      setTimeout(() => {
        const searchInputElement = document.querySelector('.search-container.active input') as HTMLInputElement;
        if (searchInputElement) {
          searchInputElement.focus();
        }
      }, 300); // 300ms delay to match the CSS transition
    } else {
      // Clear search when hiding
      this.clearSearch();
    }
  }

  /**
   * Clear search and reset filtered results
   */
  clearSearch(): void {
    this.searchQuery = '';
    this.filterFiles();
  }

  /**
   * Navigate to the parent folder of a file when clicking its location
   */
  navigateToParentFolder(file: FileItem): void {
    if (!file.parentFolder) return;

    // Find the folder in clioFiles array
    const folderToNavigate = this.findFolderByName(this.clioFiles, file.parentFolder);

    if (folderToNavigate) {
      // Reset breadcrumbs to root
      this.breadcrumbs = [{ name: 'Home', folder: null }];

      // Add the target folder to breadcrumbs
      this.breadcrumbs.push({ name: folderToNavigate.name, folder: folderToNavigate });

      // Update current folder contents
      this.updateCurrentFolderContents();

      // Clear any active search when navigating
      this.clearSearch();

      console.log(`Navigated to parent folder: ${folderToNavigate.name}`);
    }
  }

  /**
   * Helper method to find a folder by its name in the file list
   */
  findFolderByName(fileList: FileItem[], folderName: string): FileItem | null {
    for (const item of fileList) {
      if (item.type === 'folder' && item.name === folderName) {
        return item;
      } else if (item.type === 'folder' && item.children) {
        // Search recursively in children
        const found = this.findFolderByName(
          item.children.filter(child => child.type === 'folder') as FileItem[],
          folderName,
        );
        if (found) return found;
      }
    }
    return null;
  }

  /**
   * Flattens the entire file structure into a single array for tree display
   * Also sets the depth and visibility of each item
   */
  updateFlattenedItems(): void {
    // Reset the flattened items array
    this.flattenedItems = [];

    // Start with root level files
    this.flattenItems(this.clioFiles, 0, '');

    // Update visibility based on expanded folders
    this.updateItemVisibility();

    console.log('Updated flattened items:', this.flattenedItems.length);
  }

  /**
   * Recursively flatten the file structure
   */
  flattenItems(items: FileItem[], depth: number, parentPath: string): void {
    items.forEach((item, index) => {
      // Create a clone of the item with depth information
      const flatItem = { ...item };
      flatItem.depth = depth;

      // Set parent path for location tracking
      const itemPath = parentPath ? `${parentPath}/${item.name}` : item.name;
      flatItem.parentPath = parentPath;

      // Add to flattened array
      this.flattenedItems.push(flatItem);

      // If this is a folder with children, process them too
      if (item.type === 'folder' && item.children) {
        this.flattenItems(item.children, depth + 1, itemPath);
      }
    });
  }

  /**
   * Updates visibility of items based on expanded folders
   */
  updateItemVisibility(): void {
    // First reset all visibility
    this.flattenedItems.forEach(item => {
      item.visible = false;
    });

    // Build a set of visible paths based on expanded folders
    const visiblePaths = new Set<string>();
    visiblePaths.add(''); // Root path is always visible

    // Add all expanded folder paths
    for (let item of this.flattenedItems) {
      if (item.type === 'folder' && item.expanded && item.parentPath !== undefined) {
        visiblePaths.add(item.parentPath ? `${item.parentPath}/${item.name}` : item.name);
      }
    }

    // Mark items as visible if their parent path is in the visible paths set
    for (let item of this.flattenedItems) {
      if (visiblePaths.has(item.parentPath || '')) {
        item.visible = true;
      }
    }
  }

  /**
   * Gets items to display in the tree view based on current state
   */
  getDisplayedItems(): FileItem[] {
    // If search is active, show all matching items
    if (this.searchQuery && this.searchQuery.trim() !== '') {
      return this.filteredFolderContents;
    }

    // Otherwise, show only items that should be visible based on expanded state
    return this.flattenedItems.filter(item => item.visible);
  }

  /**
   * Get depth level for an item (for indentation)
   */
  getItemDepth(item: FileItem): number {
    return item.depth || 0;
  }

  /**
   * Update breadcrumbs when a folder is expanded in the tree
   */
  updateBreadcrumbsForFolder(folder: FileItem): void {
    // Start with root breadcrumb
    const newBreadcrumbs: BreadcrumbItem[] = [{ name: 'Home', folder: null }];

    // If no parent path, this is a root folder
    if (!folder.parentPath) {
      newBreadcrumbs.push({ name: folder.name, folder: folder });
    } else {
      // Build path by traversing parent paths
      const pathParts = folder.parentPath.split('/');
      let currentPath = '';

      // Add each ancestor folder to breadcrumbs
      for (const part of pathParts) {
        if (part) {
          // Find the folder item for this part
          const pathFolder = this.findFolderByPath(currentPath, part);
          if (pathFolder) {
            newBreadcrumbs.push({ name: pathFolder.name, folder: pathFolder });
          }

          // Update current path
          currentPath = currentPath ? `${currentPath}/${part}` : part;
        }
      }

      // Add the current folder as the last breadcrumb
      newBreadcrumbs.push({ name: folder.name, folder: folder });
    }

    // Update breadcrumbs
    this.breadcrumbs = newBreadcrumbs;

    // Update current folder contents to match what's in the expanded folder
    this.currentFolderContents = folder.children || [];

    console.log(`Updated breadcrumbs for folder: ${folder.name}`, this.breadcrumbs);
  }

  /**
   * Find a folder by path and name
   */
  findFolderByPath(parentPath: string, folderName: string): FileItem | null {
    return (
      this.flattenedItems.find(
        item => item.type === 'folder' && item.name === folderName && item.parentPath === parentPath,
      ) || null
    );
  }

  /**
   * Expand all parent folders of a given folder
   */
  expandParentFolders(folder: FileItem): void {
    if (!folder.parentPath) return;

    const pathParts = folder.parentPath.split('/');
    let currentPath = '';

    for (const part of pathParts) {
      if (part) {
        const parentFolder = this.findFolderByPath(currentPath, part);
        if (parentFolder) {
          parentFolder.expanded = true;
        }

        currentPath = currentPath ? `${currentPath}/${part}` : part;
      }
    }
  }

  /**
   * Filter files based on search query - updated for tree view
   */
  filterFiles(): void {
    if (!this.searchQuery || this.searchQuery.trim() === '') {
      // If no search query, show current folder items only
      this.filteredFolderContents = [...this.currentFolderContents];
    } else {
      const query = this.searchQuery.toLowerCase().trim();

      // When searching, we search across all items, not just the current folder
      // This makes search more useful with the tree structure
      this.filteredFolderContents = this.flattenedItems.filter(item => item.name.toLowerCase().includes(query));
    }
  }

  /**
   * Find a folder by name and path
   */
  findFolderByNameAndPath(fileList: FileItem[], folderName: string, parentPath: string): FileItem | null {
    // Check direct children first
    for (const item of fileList) {
      if (item.type === 'folder' && item.name === folderName && (item.parentPath || '') === parentPath) {
        return item;
      }
    }

    // Check recursively in subfolders
    for (const item of fileList) {
      if (item.type === 'folder' && item.children) {
        const childPath = item.parentPath ? `${item.parentPath}/${item.name}` : item.name;
        const found = this.findFolderByNameAndPath(item.children, folderName, childPath);
        if (found) return found;
      }
    }

    return null;
  }

  /**
   * Get folder size (sum of all child file sizes)
   * Returns a formatted string like "1.2MB"
   */
  getFolderSize(folder: FileItem): string {
    if (!folder.children || folder.children.length === 0) {
      return '0KB';
    }

    // Calculate total size in bytes (using simple estimation since we have formatted strings)
    let totalSize = 0;
    const collectSizes = (items: FileItem[]) => {
      items.forEach(item => {
        if (item.type === 'file' || item.type === 'image') {
          // Convert size string to bytes (approximate)
          if (item.size) {
            const size = parseFloat(item.size);
            if (item.size.includes('MB')) {
              totalSize += size * 1024 * 1024;
            } else if (item.size.includes('KB')) {
              totalSize += size * 1024;
            } else {
              totalSize += size;
            }
          }
        } else if (item.type === 'folder' && item.children) {
          collectSizes(item.children);
        }
      });
    };

    collectSizes(folder.children);

    // Format the size back to a readable string
    if (totalSize > 1024 * 1024) {
      return `${(totalSize / (1024 * 1024)).toFixed(1)}MB`;
    } else if (totalSize > 1024) {
      return `${(totalSize / 1024).toFixed(1)}KB`;
    } else {
      return `${totalSize}B`;
    }
  }

  /**
   * Get count of files in a folder (recursive)
   * Modified to only count selected files and respect already transferred status
   */
  getFolderFileCount(folder: FileItem): number {
    if (!folder.children || folder.children.length === 0) {
      return 0;
    }

    let count = 0;
    const countFiles = (items: FileItem[]) => {
      items.forEach(item => {
        if ((item.type === 'file' || item.type === 'image') && item.selected) {
          count++;
        } else if (item.type === 'folder' && item.children) {
          countFiles(item.children);
        }
      });
    };

    // Find the actual folder in source data to ensure we're counting the latest state
    const actualFolder = this.findFolderInSource(folder.name, folder.parentFolder || '');
    if (actualFolder && actualFolder.children) {
      countFiles(actualFolder.children);
    } else if (folder.children) {
      // Fallback to the provided folder if we can't find the source folder
      countFiles(folder.children);
    }

    return count;
  }

  /**
   * Get count of subfolders in a folder (recursive)
   * Modified to only count selected subfolders
   */
  getFolderSubfolderCount(folder: FileItem): number {
    if (!folder.children || folder.children.length === 0) {
      return 0;
    }

    let count = 0;
    const countSubfolders = (items: FileItem[]) => {
      items.forEach(item => {
        if (item.type === 'folder' && item.selected) {
          count++;
          // Also count nested subfolders if they're selected
          if (item.children) {
            countSubfolders(item.children);
          }
        }
      });
    };

    // Find the actual folder in source data to ensure we're counting the latest state
    const actualFolder = this.findFolderInSource(folder.name, folder.parentFolder || '');
    if (actualFolder && actualFolder.children) {
      countSubfolders(actualFolder.children);
    } else if (folder.children) {
      // Fallback to the provided folder if we can't find the source folder
      countSubfolders(folder.children);
    }

    return count;
  }

  /**
   * Get a formatted summary of folder contents
   * Returns a string like "10 files, 3 subfolders"
   */
  getFolderSummary(folder: FileItem): string {
    const fileCount = this.getFolderFileCount(folder);
    const subfolderCount = this.getFolderSubfolderCount(folder);

    let summary = '';
    if (fileCount > 0) {
      summary += `${fileCount} file${fileCount !== 1 ? 's' : ''}`;
    }
    if (summary) summary += ', ';
    summary += `${subfolderCount} subfolder${subfolderCount !== 1 ? 's' : ''}`;

    return summary || 'Empty folder';
  }

  /**
   * Helper method to remove all files from a folder and its subfolders from the selected files list
   */
  removeFilesFromFolder(folder: FileItem): void {
    if (!folder || !folder.children) return;

    // First remove immediate children
    this.selectedFiles = this.selectedFiles.filter(item => item.parentFolder !== folder.name);

    // Then recursively process subfolders
    folder.children.forEach(child => {
      if (child.type === 'folder') {
        this.removeFilesFromFolder(child);
      }
    });
  }

  /**
   * Returns true if the given folder is selected in the left panel.
   */
  isFolderSelectedInLeftPanel(folder: FileItem): boolean {
    return this.selectedFiles.some(item => item.type === 'folder' && item.name === folder.name);
  }

  /**
   * Find a folder in the source data structure
   */
  findFolderInSource(folderName: string, parentPath: string): FileItem | null {
    // Check in flattened items first for better performance
    const flatFolder = this.flattenedItems.find(
      item => item.type === 'folder' && item.name === folderName && (item.parentPath || '') === parentPath,
    );

    if (flatFolder) {
      return flatFolder;
    }

    // Fallback to search in the original data structure
    if (parentPath === '') {
      // It's a root level folder
      return this.clioFiles.find(item => item.type === 'folder' && item.name === folderName) || null;
    } else {
      // It's a nested folder, use the helper method
      return this.findFolderByNameAndPath(this.clioFiles, folderName, parentPath);
    }
  }

  /**
   * Get count of selected folders
   */
  getSelectedFoldersCount(): number {
    return this.selectedFiles.filter(item => item.type === 'folder').length;
  }

  /**
   * Get count of selected files (excluding folders)
   */
  getSelectedFilesCount(): number {
    return this.selectedFiles.filter(item => item.type !== 'folder').length;
  }

  /**
   * Get common parent folder for selected items, if any
   * Returns the folder path or null if items are from different folders
   */
  getCommonParentFolder(): string | null {
    if (this.selectedFiles.length === 0) return null;

    // Get all parent folders
    const parentFolders = this.selectedFiles.filter(item => item.parentFolder).map(item => item.parentFolder);

    // If no parent folders, items are in root
    if (parentFolders.length === 0) return 'Root';

    // Check if all items are from the same parent folder
    const firstParent = parentFolders[0];
    const allSameParent = parentFolders.every(folder => folder === firstParent);

    if (allSameParent) {
      return firstParent;
    } else {
      return 'Multiple folders';
    }
  }

  /**
   * Updates selection state of all children in the flattened items array
   * This ensures visual consistency in the tree view
   * Modified to respect alreadyTransferred property
   */
  private updateChildrenInFlattenedItems(folder: FileItem, selected: boolean): void {
    // Get the full path of this folder to find its children
    const folderPath = folder.parentPath ? `${folder.parentPath}/${folder.name}` : folder.name;

    // Find all items that have this folder as parent and update their selection state
    this.flattenedItems.forEach(item => {
      if (item.parentPath === folderPath && !this.isFileAlreadyTransferred(item)) {
        item.selected = selected;

        // If this is also a folder, recursively process its children
        if (item.type === 'folder') {
          this.updateChildrenInFlattenedItems(item, selected);
        }
      }
    });
  }

  /**
   * Check if a file is already transferred
   * This is used to disable checkboxes for already transferred files
   * Enhanced to handle folder checking more thoroughly
   */
  isFileAlreadyTransferred(file: FileItem): boolean {
    if (!file) return false;

    // Direct check if the file has the property
    if (file.alreadyTransferred === true) return true;

    // For files, check if this file exists in the NuageDx files
    if (file.type === 'file' || file.type === 'image') {
      for (const folder of this.nuageDxFiles) {
        if (folder.children && folder.children.some(f => f.name === file.name)) {
          return true;
        }

        // Also check subfolders recursively
        if (folder.children) {
          const hasTransferred = this.checkChildrenForFile(folder.children, file.name);
          if (hasTransferred) return true;
        }
      }
    }

    // For folders, check if the folder exists in NuageDx with the same name
    if (file.type === 'folder') {
      return this.nuageDxFiles.some(f => f.type === 'folder' && f.name === file.name && f.alreadyTransferred === true);
    }

    return false;
  }

  /**
   * Helper method to check children recursively for a file
   */
  checkChildrenForFile(items: FileItem[], fileName: string): boolean {
    for (const item of items) {
      if ((item.type === 'file' || item.type === 'image') && item.name === fileName) {
        return true;
      }

      if (item.type === 'folder' && item.children) {
        const found = this.checkChildrenForFile(item.children, fileName);
        if (found) return true;
      }
    }
    return false;
  }

  /**
   * For folders, get the count of already transferred files inside them
   */
  getTransferredFileCount(folder: FileItem): number {
    if (!folder.children || folder.children.length === 0) {
      return 0;
    }

    let count = 0;
    const countTransferredFiles = (items: FileItem[]) => {
      items.forEach(item => {
        if ((item.type === 'file' || item.type === 'image') && item.alreadyTransferred) {
          count++;
        } else if (item.type === 'folder' && item.children) {
          countTransferredFiles(item.children);
        }
      });
    };

    countTransferredFiles(folder.children);
    return count;
  }

  /**
   * Recursively update selection state of all children and add them to selected files
   * Modified to respect alreadyTransferred property
   */
  updateChildrenSelectionState(folder: FileItem, selected: boolean): void {
    if (!folder.children) return;

    folder.children.forEach(child => {
      // Skip already transferred items when selecting
      if (!this.isFileAlreadyTransferred(child)) {
        child.selected = selected;

        // If selecting, add the file to selected files
        if (selected && (child.type === 'file' || child.type === 'image')) {
          this.addFileToSelectedList(child, folder.name);
        }

        // Process subfolders recursively
        if (child.type === 'folder') {
          this.updateChildrenSelectionState(child, selected);
        }
      }
    });
  }

  /**
   * Get a formatted summary of selected files and subfolders in a folder
   * Returns a string like "3 files, 2 subfolders selected"
   */
  getSelectedFolderSummary(folder: FileItem): string {
    // Force recounting of files and subfolders to ensure accuracy
    let fileCount = 0;
    let subfolderCount = 0;

    // Use cached counts if available for better performance,
    // otherwise recalculate them
    if (folder._fileCount !== undefined) {
      fileCount = folder._fileCount;
    } else {
      fileCount = this.getFolderFileCount(folder);
    }

    if (folder._subfolderCount !== undefined) {
      subfolderCount = folder._subfolderCount;
    } else {
      subfolderCount = this.getFolderSubfolderCount(folder);
    }

    let summary = '';
    if (fileCount > 0) {
      summary += `${fileCount} file${fileCount !== 1 ? 's' : ''}`;
    }
    if (subfolderCount > 0) {
      if (summary) summary += ', ';
      summary += `${subfolderCount} subfolder${subfolderCount !== 1 ? 's' : ''}`;
    }

    return summary ? `${summary} selected` : 'No items selected';
  }

  /**
   * Forces an update of a folder in the selectedFiles array by creating a fresh copy
   * This ensures Angular's change detection will update the folder subtitle counts in the UI
   */
  forceUpdateFolderInSelectedFiles(folderName: string): void {
    // Find the folder in selectedFiles
    const folderIndex = this.selectedFiles.findIndex(item => item.type === 'folder' && item.name === folderName);

    if (folderIndex !== -1) {
      // Get a reference to the actual source folder to ensure we have latest data
      const sourceFolder = this.findFolderInSource(folderName, '');

      if (sourceFolder) {
        // Create a new folder object with updated count properties
        const updatedFolder = {
          ...this.selectedFiles[folderIndex],
          // Update timestamp to force Angular change detection
          _lastCountRefreshTime: Date.now(),
          // Pre-calculate counts for instant UI display
          _fileCount: this.getFolderFileCount(sourceFolder),
          _subfolderCount: this.getFolderSubfolderCount(sourceFolder),
        };

        // Replace the folder in the array with the updated one
        this.selectedFiles[folderIndex] = updatedFolder;

        // For complex changes, we can force a full array update
        if (updatedFolder._fileCount === 0 && updatedFolder._subfolderCount === 0) {
          // If all items are unselected, use a brand new array reference
          this.selectedFiles = [...this.selectedFiles];
        }
      }
    }
  }

  /**
   * Specifically updates parent folder in the selectedFiles list when a file's selected state changes
   * This is critical for updating folder subtitles in the right panel when files are selected/unselected
   */
  forceUpdateParentFolderInSelectedFiles(folderName: string): void {
    // Find the folder in selectedFiles
    const parentFolderIndex = this.selectedFiles.findIndex(item => item.type === 'folder' && item.name === folderName);

    if (parentFolderIndex !== -1) {
      // Get actual folder from source to ensure latest selection state
      const sourceFolder = this.findFolderInSource(folderName, '');

      if (sourceFolder) {
        // Calculate current counts for selected files/subfolders
        const fileCount = this.getFolderFileCount(sourceFolder);
        const subfolderCount = this.getFolderSubfolderCount(sourceFolder);

        // Create a completely new object to force Angular change detection
        const updatedFolder = {
          ...this.selectedFiles[parentFolderIndex],
          _lastCountRefreshTime: Date.now(),
          _fileCount: fileCount,
          _subfolderCount: subfolderCount,
        };

        // Replace the folder with the updated copy
        this.selectedFiles[parentFolderIndex] = updatedFolder;

        // If there are no more selected items in this folder, update the whole array
        // This ensures proper UI updates for empty folders
        if (fileCount === 0 && subfolderCount === 0) {
          this.selectedFiles = [...this.selectedFiles];
        }

        console.log(`Updated folder: ${folderName}, files: ${fileCount}, subfolders: ${subfolderCount}`);
      }
    }
  }

  /**
   * Refreshes all folder references in the selectedFiles array
   * to ensure folder counts are updated in the UI
   * Optimized to create fewer new objects for better performance
   */
  refreshAllFolderReferences(): void {
    // Track if we need to update the entire array
    let folderUpdated = false;

    // Create a new array with refreshed folder references
    const updatedSelectedFiles = this.selectedFiles.map(item => {
      if (item.type === 'folder') {
        // Create a new reference for folders to trigger Angular change detection
        folderUpdated = true;

        // Create a new reference with special attention to ensure counts will update
        const updatedFolder = { ...item };

        // Find the source folder to get accurate counts
        const sourceFolder = this.findFolderInSource(item.name, item.parentFolder || '');
        if (sourceFolder) {
          // Pre-calculate the counts to ensure they're refreshed
          updatedFolder._fileCount = this.getFolderFileCount(sourceFolder);
          updatedFolder._subfolderCount = this.getFolderSubfolderCount(sourceFolder);
        }

        return updatedFolder;
      }
      return item;
    });

    // Only create a new array reference if folders were actually updated
    if (folderUpdated) {
      // Force a refresh of the entire selectedFiles array by creating a new reference
      this.selectedFiles = updatedSelectedFiles;
    }
  }

  /**
   * Used as a trackBy function to force Angular to regenerate the folder summary
   * when files inside a folder are selected or unselected
   */
  trackFolderChanges(folder: FileItem): string {
    // Create a unique tracking key that changes when folder content selection changes
    // Use the stored counts when available to avoid unnecessary recalculation
    const fileCount = folder._fileCount !== undefined ? folder._fileCount : this.getFolderFileCount(folder);
    const subfolderCount =
      folder._subfolderCount !== undefined ? folder._subfolderCount : this.getFolderSubfolderCount(folder);

    // Include the timestamp to force rendering updates
    return `${folder.name}_files_${fileCount}_folders_${subfolderCount}_${folder._lastCountRefreshTime || Date.now()}`;
  }

  /**
   * Simulates or performs an async operation to load folder contents
   * In a real implementation, this would call an API
   */
  private async fetchFolderContents(folder: FileItem): Promise<void> {
    // In a real app, this would be an actual API call to fetch folder contents
    // For demonstration, we'll simulate a network delay
    return new Promise<void>(resolve => {
      setTimeout(() => {
        // If needed, you could update folder.children here with API response data
        resolve();
      }, 800); // Simulate network delay
    });
  }
}
