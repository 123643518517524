<mat-card class="selected-files-container">
  <mat-card-header fxLayout="row"
                   fxLayoutAlign="space-between center">
    <mat-card-title>
      Selected Files ({{selectedFilesCount}})
    </mat-card-title>
    <mat-card-subtitle>
      Total Size: {{selectedFilesSize | number}} bytes
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <mat-list dense>
      <mat-list-item *ngFor="let file of selectedFiles"
                     class="file-item">
        <mat-icon matListIcon>
          description
        </mat-icon>
        <div class="file-info"
             fxLayout="row"
             fxLayoutAlign="start center"
             matLine>
          <span class="file-name">
            {{file.fileName}}
          </span>
          <span class="file-path">
            <mat-icon class="small-icon">
              folder
            </mat-icon>
            {{file.parentFolderName}}
          </span>
        </div>
        <button color="warn"
                mat-icon-button
                (click)="removeFile.emit(file)">
          <mat-icon>
            close
          </mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
  </mat-card-content>

  <mat-card-actions *ngIf="selectedFilesCount > 0"
                    class="actions-container"
                    align="end">
    <button color="primary"
            mat-button
            (click)="shareFiles.emit()">
      <mat-icon>
        share
      </mat-icon>
      Share
    </button>
    <button color="warn"
            mat-button
            (click)="removeSelected.emit()">
      <mat-icon>
        delete
      </mat-icon>
      Remove
    </button>
    <button color="warn"
            mat-button
            (click)="removeAllFiles.emit()">
      <mat-icon>
        delete_sweep
      </mat-icon>
      Clear All
    </button>
  </mat-card-actions>
</mat-card>
