<!-- file-transfer.component.html -->
<div class="file-transfer-container">
  <!-- Header -->
  <div class="header-container"
       fxLayout="row"
       fxLayoutAlign="space-between center">
    <div fxLayout="row"
         fxLayoutAlign="start center">
      <h2>
        Transfer Files to NuageDx
      </h2>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="end center">
      <span class="matter-id">
        Matter:
        <span class="matter-value">
          {{matterId}}
        </span>
      </span>
      <button class="back-button"
              color="primary"
              mat-stroked-button
              (click)="navigateBackToClio()">
        <mat-icon>
          arrow_back
        </mat-icon>
        Back to Clio
      </button>
      <button class="close-button"
              mat-icon-button
              (click)="closeDialog()">
        <mat-icon>
          close
        </mat-icon>
      </button>
    </div>
  </div>

  <!-- File panels -->
  <div class="panels-container"
       fxLayout="row"
       fxLayoutGap="16px">
    <!-- Source files panel -->
    <mat-card class="file-panel source-panel">
      <mat-card-header>
        <mat-card-title>
          Clio Matter documents
        </mat-card-title>
        <mat-icon class="search-icon">
          search
        </mat-icon>
      </mat-card-header>
      <mat-card-content>
        <div class="select-all-container">
          <mat-checkbox (change)="selectAll()">
            Select All
          </mat-checkbox>
        </div>
        <div class="file-tree">
          <ng-container *ngFor="let item of clioFiles">
            <!-- File item -->
            <div *ngIf="item.type === 'file'"
                 class="file-item"
                 [ngClass]="{'selected': item.selected}">
              <mat-checkbox [(ngModel)]="item.selected"
                            (change)="toggleFileSelection(item)"></mat-checkbox>
              <mat-icon class="file-icon">
                insert_drive_file
              </mat-icon>
              <span class="file-name">
                {{item.name}}
              </span>
            </div>

            <!-- Folder item -->
            <div *ngIf="item.type === 'folder'"
                 class="folder-container">
              <div class="folder-item" [ngClass]="{'folder-selected': item.selected}">
                <!-- The checkbox only toggles selection, not expansion -->
                <mat-checkbox [(ngModel)]="item.selected"
                              (ngModelChange)="toggleFolderSelection(item, $event)"
                              [indeterminate]="areSomeFolderFilesSelected(item)"></mat-checkbox>
                <!-- The folder content only toggles expansion, not selection -->
                <span class="folder-content" (click)="toggleFolder(item)">
                  <mat-icon *ngIf="item.expanded">
                    remove
                  </mat-icon>
                  <mat-icon *ngIf="!item.expanded">
                    add
                  </mat-icon>
                  <mat-icon class="folder-icon">
                    folder
                  </mat-icon>
                  <span class="folder-name">
                    {{item.name}}
                  </span>
                </span>
              </div>

              <!-- Folder children -->
              <div *ngIf="item.expanded"
                   class="folder-children">
                <div *ngFor="let child of item.children"
                     class="file-item"
                     [ngClass]="{'selected': child.selected}">
                  <mat-checkbox [(ngModel)]="child.selected"
                                (change)="toggleFileSelection(child)"></mat-checkbox>
                  <mat-icon *ngIf="child.type === 'file'"
                            class="file-icon">
                    insert_drive_file
                  </mat-icon>
                  <mat-icon *ngIf="child.type === 'image'"
                            class="file-icon">
                    image
                  </mat-icon>
                  <span class="file-name">
                    {{child.name}}
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- Selected files panel -->
    <mat-card class="file-panel selected-panel">
      <mat-card-header>
        <mat-card-title class="selected-title">
          Files selected to be transfered
          <a *ngIf="hasExactDuplicates" class="show-duplicates-link" (click)="toggleShowDuplicates()">
            {{ showDuplicatesPanel ? 'Hide Duplicated Files' : 'Show Duplicated Files' }}
          </a>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div *ngIf="selectedFiles.length === 0"
             class="no-files-selected">
          <mat-icon>
            info
          </mat-icon>
          <p>
            No files selected. Select files from the left panel to transfer.
          </p>
        </div>
        <div *ngIf="selectedFiles.length > 0"
             class="selected-files-list">
          <div *ngFor="let file of selectedFiles"
               class="selected-file-item"
               fxLayout="row"
               fxLayoutAlign="start center">
            <mat-checkbox [(ngModel)]="file.selected"
                          (change)="onSelectedFileCheckboxChange(file)"></mat-checkbox>
            <mat-icon class="file-icon">
              insert_drive_file
            </mat-icon>
            <span class="file-name">
              {{file.name}}
            </span>
            <mat-icon *ngIf="file.isDuplicate && showDuplicatesPanel"
                      class="warning-icon">
              warning
            </mat-icon>
            <span class="file-size">
              {{file.size}}
            </span>
            <span class="file-date">
              {{file.date}}
            </span>
            <mat-icon class="remove-icon"
                      (click)="removeSelectedFile(file)">
              close
            </mat-icon>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- Destination files panel -->
    <mat-card class="destination-panel file-panel">
      <mat-card-header>
        <mat-card-title>
          Clio Files in NuageDx
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="file-tree">
          <ng-container *ngFor="let item of nuageDxFiles">
            <!-- Folder item with add icon -->
            <div class="folder-item">
              <mat-icon class="add-icon">
                add
              </mat-icon>
              <mat-icon class="folder-icon">
                folder
              </mat-icon>
              <span class="folder-name">
                {{item.name}}
              </span>
            </div>

            <!-- Folder children -->
            <div class="folder-children">
              <div *ngFor="let child of item.children"
                   class="file-item">
                <mat-icon *ngIf="child.type === 'image'"
                          class="file-icon">
                  image
                </mat-icon>
                <span class="file-name">
                  {{child.name}}
                </span>
                <mat-icon class="remove-icon">
                  close
                </mat-icon>
              </div>
            </div>
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Debug panel to show selected files -->
  <mat-card *ngIf="showDebugInfo"
            class="debug-panel">
    <mat-card-header>
      <mat-card-title>
        Debug Info - Selected Files
      </mat-card-title>
      <button class="close-debug"
              mat-icon-button
              (click)="showDebugInfo = false">
        <mat-icon>
          close
        </mat-icon>
      </button>
    </mat-card-header>
    <mat-card-content>
      <div *ngFor="let file of selectedFiles"
           class="debug-file-item">
        <p>
          Name: {{file.name}} | Size: {{file.size}} | Date: {{file.date}}
        </p>
      </div>
      <hr>
      <div>
        <p>
          <strong>
            Has Duplicates:
          </strong>
          {{hasExactDuplicates ? 'Yes' : 'No'}}
        </p>
        <button color="primary"
                mat-raised-button
                (click)="forceCheckDuplicates()">
          Force Check Duplicates
        </button>
      </div>
      <hr>
      <div>
        <p><strong>Folders:</strong></p>
        <div *ngFor="let folder of clioFiles">
          <div *ngIf="folder.type === 'folder'">
            <p>{{folder.name}}: {{folder.selected ? 'Selected' : 'Not Selected'}}</p>
            <button mat-button color="primary" (click)="logFolderState(folder)">Debug Folder</button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- Duplicates warning panel - only show when exact duplicates exist AND show duplicates is toggled -->
  <mat-card *ngIf="hasExactDuplicates && showDuplicatesPanel"
            class="duplicates-panel">
    <mat-card-content>
      <p class="duplicate-warning">
        Some of the files you selected are duplicates.
      </p>

      <div class="duplicated-files-section">
        <div class="duplicated-title">
          <mat-icon class="warning-icon">
            warning
          </mat-icon>
          <span>
            Duplicated files:
          </span>
        </div>

        <div class="duplicated-files-list">
          <div *ngFor="let file of duplicatedFiles; let i = index"
               class="duplicated-file-item">
            <mat-checkbox [checked]="true"
                          [(ngModel)]="file.selected"></mat-checkbox>
            <mat-icon class="file-icon">
              insert_drive_file
            </mat-icon>
            <span class="file-name">
              <!-- Show original name for most duplicates -->
              <ng-container *ngIf="!(selectedResolutionOption === 'rename' && i === 0)">
                {{file.name}}
              </ng-container>
              <!-- Show preview of renamed file for the first duplicate when Rename is selected -->
              <ng-container *ngIf="selectedResolutionOption === 'rename' && i === 0">
                <span class="rename-preview">
                  {{getRenamingPreview(file.name)}}
                </span>
              </ng-container>
            </span>
            <span *ngIf="file.size"
                  class="file-size">
              {{file.size}}
            </span>
            <span *ngIf="file.date"
                  class="file-date">
              {{file.date}}
            </span>
          </div>
        </div>

        <div class="resolution-options"
             fxLayout="row"
             fxLayoutAlign="start center">
          <span class="resolution-label">
            Resolution:
          </span>
          <mat-radio-group fxLayout="row"
                           fxLayoutGap="16px"
                           [(ngModel)]="selectedResolutionOption">
            <mat-radio-button *ngFor="let option of resolutionOptions"
                              [checked]="option.checked"
                              [value]="option.value"
                              (change)="changeResolutionOption(option.value)">
              {{option.label}}
            </mat-radio-button>
          </mat-radio-group>
        </div>

      </div>
      <!-- Close duplicated-files-section -->

      <div class="action-buttons"
           fxLayout="row"
           fxLayoutAlign="end center"
           fxLayoutGap="16px">
        <button class="transfer-button"
                color="primary"
                mat-raised-button
                (click)="transferSelectedFiles()">
          <span>
            Transfer selected
          </span>
          <mat-icon>
            arrow_forward
          </mat-icon>
        </button>
        <button class="cancel-button"
                mat-raised-button
                (click)="cancelTransfer()">
          Cancel
        </button>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- Toggle Debug Button -->
  <div class="debug-toggle">
    <button color="accent"
            mat-mini-fab
            (click)="toggleDebug()">
      <mat-icon>
        bug_report
      </mat-icon>
    </button>
  </div>
</div>
