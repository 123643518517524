import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { FilesService } from 'src/app/services/files.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cp-selected-files',
  templateUrl: './cp-selected-files.component.html',
  styleUrls: ['./cp-selected-files.component.scss'],
})
export class CpSelectedFilesComponent implements OnInit, OnDestroy {
  @Input() selectedFiles: any;
  @Input() selectedFilesCount: any;
  @Input() selectedFilesSize: any;
  @Output() removeFile: EventEmitter<any> = new EventEmitter();
  @Output() removeAllFiles: EventEmitter<any> = new EventEmitter();
  @Output() shareFiles = new EventEmitter<void>();
  @Output() removeSelected = new EventEmitter<void>();

  private subscription: Subscription = new Subscription();

  constructor(private filesService: FilesService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.filesService.selectedFiles.subscribe(files => {
        this.selectedFiles = files;
        this.selectedFilesCount = files.length;
        this.selectedFilesSize = this.calculateTotalSize(files);
        console.log('Selected files updated:', files);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private calculateTotalSize(files: any[]): number {
    return files.reduce((total, file) => total + (file.fileSize || 0), 0);
  }
}
