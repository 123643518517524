<!-- file-transfer.component.html -->
<div class="file-transfer-container">
  <!-- Header -->
  <div class="header-container"
       fxLayout="row"
       fxLayoutAlign="space-between center">
    <div fxLayout="row"
         fxLayoutAlign="start center">
      <h2>
        Transfer Files to NuageDx
      </h2>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="end center">
      <span class="matter-id">
        <!-- matter-button already has the right styling -->
        <button class="matter-button"
                mat-button
                (click)="navigateToMatter()">
          <mat-icon>
            folder_open
          </mat-icon>
          Open the Client/Matter {{matterId}}
        </button>
      </span>
      <button class="back-button nuage-button secondary"
              mat-button
              (click)="navigateBackToClio()">
        <mat-icon>
          arrow_back
        </mat-icon>
        Open the matter {{matterId}} in Clio
      </button>
      <button class="close-button nuage-button secondary"
              mat-icon-button
              (click)="closeDialog()">
        <mat-icon>
          close
        </mat-icon>
      </button>
    </div>
  </div>

  <!-- File panels -->
  <div class="panels-container"
       fxLayout="row"
       fxLayoutGap="12px">
    <!-- Source files panel with breadcrumb navigation -->
    <mat-card class="file-panel source-panel">
      <mat-card-header>
        <mat-card-title>
          <span>
            Clio Matter documents
          </span>
          <mat-icon class="search-icon"
                    [ngClass]="{'active': showSearchInput}"
                    (click)="toggleSearch()">
            {{ showSearchInput ? 'close' : 'search' }}
          </mat-icon>
        </mat-card-title>
      </mat-card-header>
      <div class="search-container"
           [ngClass]="{'active': showSearchInput}">
        <div class="search-input-wrapper">
          <mat-icon>
            search
          </mat-icon>
          <input #searchInput

                 placeholder="Search files and folders..."
                 type="text"

                 [(ngModel)]="searchQuery"
                 (input)="filterFiles()">
          <button *ngIf="searchQuery"
                  class="clear-search-button"
                  (click)="clearSearch()">
            <mat-icon>
              close
            </mat-icon>
          </button>
        </div>
      </div>
      <mat-card-content>
        <!-- Breadcrumb navigation -->
        <div class="breadcrumb-container">
          <div class="breadcrumbs">
            <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index; let last = last">
              <span class="breadcrumb-item"
                    [ngClass]="{'active': last}"
                    (click)="navigateToBreadcrumb(i)">
                {{breadcrumb.name}}
              </span>
              <span *ngIf="!last"
                    class="breadcrumb-separator">
                <mat-icon>
                  chevron_right
                </mat-icon>
              </span>
            </ng-container>
          </div>
        </div>

        <!-- Select all checkbox -->
        <div class="select-all-container">
          <mat-checkbox (change)="selectAll()">
            Select All
          </mat-checkbox>
        </div>

        <!-- Current folder contents display - replaced with tree view -->
        <div class="file-tree-container">
          <!-- No results message when search has no matches -->
          <div *ngIf="filteredFolderContents.length === 0 && searchQuery"
               class="no-search-results">
            <p>
              No files matching "{{searchQuery}}"
            </p>
          </div>

          <!-- Tree View - Recursive Component -->
          <div class="tree-view">
            <!-- Show all items from the filtered contents -->
            <ng-container *ngFor="let item of getDisplayedItems()">
              <div class="tree-node"
                   [ngClass]="{'selected': item.selected, 'already-transferred': item.alreadyTransferred}"
                   [ngStyle]="{'padding-left': (getItemDepth(item) * 16) + 'px'}">
                <!-- Folder expand/collapse icon -->
                <span *ngIf="item.type === 'folder'"

                      class="tree-toggle"
                      role="button"

                      [attr.aria-expanded]="item.expanded"
                      [attr.aria-label]="item.expanded ? 'Collapse folder' : 'Expand folder'"
                      (click)="toggleFolder(item)">
                  <mat-icon>
                    {{item.expanded ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </span>
                <span *ngIf="item.type !== 'folder'"
                      class="tree-toggle-spacer"></span>

                <!-- Checkbox for files - show disabled if already transferred -->
                <mat-checkbox *ngIf="item.type === 'file' || item.type === 'image'"
                              [attr.aria-label]="'Select ' + item.name"
                              [disabled]="isFileAlreadyTransferred(item)"
                              [(ngModel)]="item.selected"
                              (change)="toggleFileSelection(item)">
                </mat-checkbox>

                <!-- Checkbox for folders - show disabled if already transferred -->
                <mat-checkbox *ngIf="item.type === 'folder'"
                              [attr.aria-label]="'Select folder ' + item.name"
                              [disabled]="isFileAlreadyTransferred(item)"
                              [indeterminate]="areSomeFolderFilesSelected(item)"
                              [(ngModel)]="item.selected"
                              (ngModelChange)="toggleFolderSelection(item, $event)">
                </mat-checkbox>

                <!-- File icon for files -->
                <mat-icon *ngIf="item.type === 'file'"
                          class="file-icon"
                          aria-hidden="true"
                          [ngClass]="{'transferred-icon': item.alreadyTransferred}">
                  description
                </mat-icon>

                <!-- Image icon for images -->
                <mat-icon *ngIf="item.type === 'image'"
                          class="file-icon"
                          aria-hidden="true"
                          [ngClass]="{'transferred-icon': item.alreadyTransferred}">
                  image
                </mat-icon>

                <!-- Folder icon for folders -->
                <mat-icon *ngIf="item.type === 'folder'"
                          class="folder-icon"
                          aria-hidden="true"
                          [ngClass]="{'transferred-icon': item.alreadyTransferred}">
                  {{item.expanded ? 'folder_open' : 'folder'}}
                </mat-icon>

                <!-- Folder name (clickable for navigation) -->
                <span *ngIf="item.type === 'folder'"
                      class="folder-name"
                      [ngClass]="{'transferred-text': item.alreadyTransferred}">
                  <!-- Show loading spinner when this folder is being loaded -->
                  <mat-spinner *ngIf="loadingFolderId === item.name"
                               class="folder-loading-spinner"
                               diameter="20"></mat-spinner>
                  <!-- Standard folder icon when not loading -->
                  <mat-icon *ngIf="loadingFolderId !== item.name">
                    {{item.expanded ? 'folder_open' : 'folder'}}
                  </mat-icon>
                  {{item.name}}
                  <span *ngIf="isFileAlreadyTransferred(item)"
                        class="transferred-indicator">
                    <mat-icon class="transferred-check"
                              aria-hidden="false"
                              aria-label="Already transferred">
                      check_circle
                    </mat-icon>
                    <span class="transferred-text">
                      Already in NuageDx
                    </span>
                  </span>
                </span>

                <!-- File name (now clickable for preview) -->
                <span *ngIf="item.type !== 'folder'"
                      class="clickable file-name"
                      [ngClass]="{'transferred-text': item.alreadyTransferred}"
                      (click)="openFilePreview(item)">
                  {{item.name}}
                  <span *ngIf="isFileAlreadyTransferred(item)"
                        class="transferred-indicator">
                    <mat-icon class="transferred-check"
                              aria-hidden="false"
                              aria-label="Already transferred">
                      check_circle
                    </mat-icon>
                    <span class="transferred-text">
                      Already in NuageDx
                    </span>
                  </span>
                </span>

                <!-- File details for files -->
                <span *ngIf="item.type !== 'folder' && item.size"
                      class="file-size"
                      [ngClass]="{'transferred-text': item.alreadyTransferred}">
                  {{item.size}}
                </span>
                <span *ngIf="item.type !== 'folder' && item.date"
                      class="file-date"
                      [ngClass]="{'transferred-text': item.alreadyTransferred}">
                  {{item.date}}
                </span>
              </div>
            </ng-container>
          </div>

          <!-- Empty folder message -->
          <div *ngIf="currentFolderContents.length === 0 && !searchQuery"
               class="empty-folder-message">
            <mat-icon>
              info
            </mat-icon>
            <p>
              This folder is empty.
            </p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- Selected files panel -->
    <mat-card class="file-panel selected-panel">
      <mat-card-header>
        <mat-card-title class="selected-title">
          Files selected to be transfered
          <a *ngIf="hasExactDuplicates"
             class="show-duplicates-link"
             (click)="toggleShowDuplicates()">
            {{ showDuplicatesPanel ? 'Hide Duplicated Files' : 'Show Duplicated Files' }}
          </a>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div *ngIf="selectedFiles.length === 0"
             class="no-files-selected">
          <mat-icon>
            info
          </mat-icon>
          <p>
            No files selected. Select files from the left panel to transfer.
          </p>
        </div>
        <div *ngIf="selectedFiles.length > 0"
             class="selected-files-list">
          <div *ngFor="let file of selectedFiles"
               class="selected-file-item"
               fxLayout="row"
               fxLayoutAlign="start center">
            <mat-checkbox [(ngModel)]="file.selected"
                          (change)="onSelectedFileCheckboxChange(file)"></mat-checkbox>

            <!-- Display folder icon for folders -->
            <mat-icon *ngIf="file.type !== 'folder'"
                      class="file-icon"
                      aria-hidden="true">
              {{ file.type === 'image' ? 'image' : 'description' }}
            </mat-icon>
            <mat-icon *ngIf="file.type === 'folder'"
                      class="folder-icon"
                      aria-hidden="true">
              folder
            </mat-icon>

            <div class="file-info-column">
              <!-- Item name (folder or file) -->
              <span class="clickable"
                    [ngClass]="{'file-name': file.type !== 'folder', 'folder-name': file.type === 'folder'}"
                    (click)="file.type === 'folder' ? navigateToFolder(file) : openFilePreview(file)">
                {{file.name}}
              </span>

              <!-- If it's a folder, show file and subfolder counts with badges -->
              <div *ngIf="file.type === 'folder'"
                   class="folder-info-subtitle">
                <span class="folder-count">
                  <!-- Fix: Remove incorrect trackBy usage in ngIf and use a different approach -->
                  {{getSelectedFolderSummary(file)}}
                </span>
              </div>

              <!-- If it's a file, show location -->
              <div *ngIf="file.type !== 'folder' && file.parentFolder"
                   class="file-location-subtitle"
                   (click)="navigateToParentFolder(file)">
                <mat-icon class="location-folder-icon">
                  folder
                </mat-icon>
                <span class="location-text">
                  {{file.parentFolder || 'Root'}}
                </span>
              </div>
            </div>

            <!-- Warning icon for duplicate files -->
            <mat-icon *ngIf="file.type !== 'folder' && file.isDuplicate && showDuplicatesPanel"
                      class="warning-icon">
              warning
            </mat-icon>

            <!-- Show size for files and folders -->
            <span *ngIf="file.type !== 'folder'"
                  class="file-size">
              {{file.size}}
            </span>
            <span *ngIf="file.type === 'folder'"
                  class="file-size">
              {{getFolderSize(file)}}
            </span>

            <!-- Show date for files -->
            <span *ngIf="file.type !== 'folder' && file.date"
                  class="file-date">
              {{file.date}}
            </span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Destination panel overlay -->
  <div *ngIf="showDestinationPanel"
       class="destination-overlay">
    <mat-card class="destination-panel file-panel">
      <mat-card-header>
        <mat-card-title>
          Clio Files in NuageDx
        </mat-card-title>
        <!-- Updated close button -->
        <button class="close-destination-button nuage-button small"
                mat-icon-button
                (click)="showDestinationPanel = false">
          <mat-icon>
            close
          </mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content>
        <div class="file-tree">
          <ng-container *ngFor="let item of nuageDxFiles">
            <!-- Folder item with add icon -->
            <div class="folder-item">
              <mat-icon class="add-icon">
                add
              </mat-icon>
              <mat-icon class="folder-icon">
                folder
              </mat-icon>
              <span class="folder-name">
                {{item.name}}
              </span>
            </div>

            <!-- Folder children: show only if folder is not selected on the left panel -->
            <div *ngIf="!isFolderSelectedInLeftPanel(item)"
                 class="folder-children">
              <div *ngFor="let child of item.children"
                   class="file-item">
                <mat-icon *ngIf="child.type === 'image'"
                          class="file-icon">
                  image
                </mat-icon>
                <span class="clickable file-name"
                      (click)="openFilePreview(child)">
                  {{child.name}}
                </span>
                <mat-icon class="remove-icon">
                  close
                </mat-icon>
              </div>
            </div>
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Duplicates warning panel - moved above the action buttons -->
  <div *ngIf="showDuplicatesPanel"
       class="duplicates-overlay"
       fxLayout="column"
       fxLayoutAlign="center center">
    <div class="duplicates-panel">
      <!-- Updated close button -->
      <button class="close-duplicates-button danger nuage-button small"
              (click)="showDuplicatesPanel = false">
        <mat-icon>
          close
        </mat-icon>
      </button>
      <mat-card *ngIf="hasExactDuplicates && showDuplicatesPanel"
                class="duplicates-panel">
        <mat-card-content>
          <p class="duplicate-warning">
            Some of the files you selected are duplicates.
          </p>
          <div class="duplicated-files-section">
            <div class="duplicated-title">
              <mat-icon class="warning-icon">
                warning
              </mat-icon>
              <span>
                Duplicated files:
              </span>
            </div>
            <div class="duplicated-files-list">
              <div *ngFor="let file of duplicatedFiles; let i = index"
                   class="duplicated-file-item">
                <mat-checkbox [checked]="true"
                              [(ngModel)]="file.selected"></mat-checkbox>
                <mat-icon class="file-icon">
                  insert_drive_file
                </mat-icon>
                <span class="file-name">
                  <!-- Show original name for most duplicates -->
                  <ng-container *ngIf="!(selectedResolutionOption === 'rename' && i === 0)">
                    {{file.name}}
                  </ng-container>
                  <!-- Show preview of renamed file for the first duplicate when Rename is selected -->
                  <ng-container *ngIf="selectedResolutionOption === 'rename' && i === 0">
                    <span class="rename-preview">
                      {{getRenamingPreview(file.name)}}
                    </span>
                  </ng-container>
                </span>
                <!-- Added file location display with minimal extra space -->
                <span *ngIf="file.parentFolder"
                      class="file-location">
                  (
                  <mat-icon class="location-icon"
                            style="font-size:14px; vertical-align:middle;">
                    folder_open
                  </mat-icon>
                  {{file.parentFolder}})
                </span>
                <span *ngIf="file.size"
                      class="file-size">
                  {{file.size}}
                </span>
                <span *ngIf="file.date"
                      class="file-date">
                  {{file.date}}
                </span>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- Action buttons - updated with consistent styling -->
  <div class="action-buttons-container">
    <div class="action-buttons"
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
      <button class="nuage-button transfer-button"
              mat-button
              [disabled]="selectedFiles.length === 0"
              (click)="transferSelectedFiles()">
        <span>
          Transfer selected
        </span>
        <mat-icon>
          arrow_forward
        </mat-icon>
      </button>
      <button class="cancel-button grey nuage-button"
              mat-button
              (click)="cancelTransfer()">
        Cancel
      </button>
    </div>
  </div>

  <!-- Debug panel to show selected files -->
  <mat-card *ngIf="showDebugInfo"
            class="debug-panel">
    <mat-card-header>
      <mat-card-title>
        Debug Info - Selected Files
      </mat-card-title>
      <button class="close-debug"
              mat-icon-button
              (click)="showDebugInfo = false">
        <mat-icon>
          close
        </mat-icon>
      </button>
    </mat-card-header>
    <mat-card-content>
      <div *ngFor="let file of selectedFiles"
           class="debug-file-item">
        <p>
          Name: {{file.name}} | Size: {{file.size}} | Date: {{file.date}}
        </p>
      </div>
      <hr>
      <div>
        <p>
          <strong>
            Has Duplicates:
          </strong>
          {{hasExactDuplicates ? 'Yes' : 'No'}}
        </p>
        <button class="nuage-button secondary"
                mat-button
                (click)="updateDuplicatesState()">
          Check Duplicates
        </button>
      </div>
      <hr>
      <div>
        <p>
          <strong>
            Breadcrumb Path:
          </strong>
          {{breadcrumbs | json}}
        </p>
        <p>
          <strong>
            Current Folder:
          </strong>
          {{getCurrentFolder()?.name || 'Root'}}
        </p>
        <p>
          <strong>
            Folders:
          </strong>
        </p>
        <div *ngFor="let folder of clioFiles">
          <div *ngIf="folder.type === 'folder'">
            <p>
              {{folder.name}}: {{folder.selected ? 'Selected' : 'Not Selected'}}
            </p>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- File Preview Overlay -->
  <div *ngIf="showFilePreview"
       class="file-preview-overlay">
    <div class="file-preview-container">
      <div class="file-preview-header">
        <h3>
          File Preview: {{previewFileItem?.name}}
        </h3>
        <button class="close-preview-button nuage-button small"
                mat-icon-button
                (click)="closeFilePreview()">
          <mat-icon>
            close
          </mat-icon>
        </button>
      </div>
      <div class="file-preview-content">
        <ng-container *ngIf="previewFileItem?.type === 'image'">
          <div class="image-preview-placeholder">
            <mat-icon class="image-icon">
              image
            </mat-icon>
            <p>
              {{previewFileItem?.content}}
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="previewFileItem?.type === 'file'">
          <div class="file-content">
            <p>
              {{previewFileItem?.content}}
            </p>
          </div>
        </ng-container>
      </div>
      <div class="file-preview-footer">
        <div class="file-info">
          <span class="file-size">
            Size: {{previewFileItem?.size}}
          </span>
          <span class="file-date">
            Date: {{previewFileItem?.date}}
          </span>
        </div>
        <button class="nuage-button secondary"
                mat-button
                (click)="closeFilePreview()">
          Close
        </button>
      </div>
    </div>
  </div>

  <!-- Toggle Debug Button -->
  <div class="debug-toggle">
    <button class="nuage-button"
            mat-mini-fab
            (click)="toggleDebug()">
      <mat-icon>
        bug_report
      </mat-icon>
    </button>
  </div>
</div>
