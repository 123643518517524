<div class="file-row"
     (click)="onRowClick($event)">
  <input type="checkbox"
         [checked]="isSelected"
         (change)="onSelectionChange($event)"
         (click)="$event.stopPropagation()">
  <span class="filename">
    {{file.fileName}}
  </span>
  <span class="file-size">
    {{file.fileSize}}
  </span>
  <span class="file-date">
    {{file.fileDate}}
  </span>
  <span class="file-parent">
    {{file.parentFolderName}}
  </span>
  <span class="file-actions">
    <button mat-icon-button
            [matMenuTriggerFor]="menu"
            (click)="handleFileActions($event)">
      <mat-icon>
        more_vert
      </mat-icon>
    </button>
  </span>
  <mat-menu #menu="matMenu"
            xPosition="before">
    <ng-container *ngFor="let item of fileActionsMenuItems">
      <button *ngIf="item.condition()"
              mat-menu-item
              [class]="item.class"
              (click)="item.action($event)">
        <mat-icon>
          {{item.icon}}
        </mat-icon>
        {{ item.label }}
      </button>
    </ng-container>
  </mat-menu>
</div>
