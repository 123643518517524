import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { Subject } from 'rxjs';

const dbPatients = firebase.firestore().collection('patients');
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  searchResults = new Subject<any>();
  private patientProfile = new Subject<any>();
  patientInProfile = this.patientProfile.asObservable();
  searchNow = new Subject<any>();
  constructor() {}

  search(criteria: string, ownerId?: string) {
    let i = 0;
    const values = ['FirstName', 'LastName', 'LegalCaseId', 'caseName', 'DateOfBirth', 'fileSearchQuery'];

    this.searchNow.subscribe(querySnapshot => {
      if (querySnapshot.docs.length > 0) {
        this.searchResults.next(querySnapshot);
      } else if (i < values.length) {
        i++;
        this.searchByValue(values[i], criteria.toString(), ownerId || null);
      }
    });

    this.searchByValue(values[i], criteria.toString(), ownerId || null);
  }

  searchByValue(value: string, criteria: string, ownerId): any {
    if (!value) {
      return;
    }

    if (value === 'fileSearchQuery') {
      dbPatients
        .where(value, 'array-contains', criteria)
        .where('forDelete', '==', false)
        .where('owners', 'array-contains', ownerId)
        .get()
        .then(querySnapshot => this.searchNow.next(querySnapshot));
    } else {
      dbPatients
        .where(value, '==', criteria)
        .where('forDelete', '==', false)
        .where('owners', 'array-contains', ownerId)
        .get()
        .then(querySnapshot => this.searchNow.next(querySnapshot));
    }
  }

  getPatienIdByCaseName(caseName: string) {
    dbPatients.doc(caseName).onSnapshot(doc => this.patientProfile.next(doc));
  }
}
