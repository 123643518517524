import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomActions } from 'src/app/dictionaries/CustomActions';
import { MattersService } from 'src/app/services/matters.service';
import { UIMessagingService } from 'src/app/services/uimessaging.service';
import { WindowsManagerService } from 'src/app/services/windows-manager.service';

import { ClioService } from 'src/app/services/clio.service';
import { FoldersService } from 'src/app/services/folders.service';
import { RedirectionService } from 'src/app/services/redirection.service';
import { AuthService } from '../../../services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

const CLIO_FOLDER_NAME = 'Clio';

@Component({
  template: `<p></p>`,
})
export class HandleClioCustomActionsComponent implements OnInit {
  existingMatter: any;
  observable: any;
  comparisonWindowOpened: any;
  constructor(
    private windowsManager_$: WindowsManagerService,
    private activatedRoute: ActivatedRoute,
    private auth_$: AuthService,
    private matters_$: MattersService,
    private clio_$: ClioService,
    private folders_$: FoldersService,
    private router: Router,
    private uiMessaging_$: UIMessagingService,
    private redirect_$: RedirectionService,
    private localStorage_$: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.auth_$.auth.onAuthStateChanged(user => {
      if (user) this.handleExistingUser(user);
      else this.handleNotLoggedIn();
    });
  }

  handleExistingUser(user) {
    let executed = false;

    this.auth_$.userData.subscribe({
      next: async userData => {
        if (Object.keys(userData).length) {
          if (this.getUserClioCustomActions(userData)) {
            const observer = {
              next: queryParams => {
                if (executed === false) {
                  this.handleCustomActionRequest(
                    queryParams.custom_action_id,
                    queryParams.custom_action_nonce,
                    queryParams.subject_url,
                    queryParams.user_id,
                  );
                  executed = true;
                }
              },
              error: err => console.error('Observer got an error: ' + err),
              complete: () => console.log('Observer got a complete notification'),
            };
            this.activatedRoute.queryParams.subscribe(observer);
          } else {
            console.log('There are no custom actions for this user.');
          }
        }
      },
      error: err => console.log('err :', err),
      complete: () => console.log('complete'),
    });
  }

  getUserClioCustomActions(userData) {
    return userData.clioCustomActions;
  }

  handleNotLoggedIn() {
    this.activatedRoute.queryParams.subscribe(({ custom_action_id, custom_action_nonce, subject_url, user_id }) => {
      this.redirect_$.goToLogin({
        queryParams: {
          referer: this.activatedRoute.routeConfig.path,
          custom_action_id,
          custom_action_nonce,
          subject_url,
          user_id,
        },
      });
    });
  }

  handleCustomActionRequest(custom_action_id, custom_action_nonce, subject_url, user_id) {
    // Extract "/api/v4/" from the subject_url
    const origin_url = subject_url.replace('/api/v4/', '');
    this.localStorage_$.clearCCAOriginURL();
    // Save it in the local storage
    this.localStorage_$.setCCAOriginURL(origin_url);
    this.handleAction(custom_action_id, decodeURIComponent(subject_url).split('/').pop().split('%2F').pop(), user_id);
  }

  handleInvalidCustomAction() {
    this.auth_$.hideLoader();
    this.uiMessaging_$.toastMessage(
      'Invalid custom action. ' +
        'Please go back to Clio and try again. Probably you have logged in to NuageDx with a different account than the one you are logged into Clio.',
      'ERROR',
    );
    this.redirectToHome();
  }

  removeClioCustomActionUrlParams() {
    this.redirectToHome();
  }

  private async handleAction(custom_action_id, clioObjectId, user_id) {
    const custom_action = this.auth_$.userData
      .getValue()
      ['clioCustomActions'].find(ca => ca.id === parseInt(custom_action_id, 10));

    if (custom_action === undefined) {
      console.log(
        'Invalid custom action. The requested custom action does not exist in the list of the custom actions from the current logged in NuageDx user.',
      );
      this.handleInvalidCustomAction();
      return;
    }

    const target = custom_action.target_url.split('/').pop();

    // FIXME: What happen when a custom action is changed on Clio, how to update stored custom actions on user's data.
    if (['meetings', CustomActions.meetingsScheduling].includes(target)) {
      this.auth_$.preventRedirections = true;
      return await this.router.navigate(['/meetings'], { queryParams: { defaultCalendar: 'clio' } }).then(
        success => {
          console.log('Navigate to meetings page success :', success);
          this.auth_$.drawer.close();
          return;
        },
        error => console.log('error :', error),
      );
    } else {
      this.handleUIReference(custom_action, clioObjectId, user_id, target);
    }
  }

  async handleFolderAction(custom_action, clioFolderId, user_id, options) {
    console.log('handleFolderAction');
    this.checkSetAvoidFilesCompare(custom_action, options);

    try {
      const matterInfo = await this.matters_$.getMatterIdFromFolderId(clioFolderId);
      const matterId = matterInfo['data']['data']['matter']['id'];

      console.log('matterId :', matterId);

      const ocm_options = {
        ...options,
        folderMatterInfo: matterInfo['data']['data'],
        clioFolderId,
        targetFolder: CLIO_FOLDER_NAME,
      };

      this.openClioMatter(matterId, user_id, ocm_options);
    } catch (error) {
      console.error('Error fetching matter ID from folder ID:', error);
      this.uiMessaging_$.toastMessage('Failed to fetch matter information. Please try again later.', 'ERROR');
    }
  }

  checkSetAvoidFilesCompare(custom_action, options) {
    console.log('checkSetAvoidFilesCompare');
    options.avoidFilesCompare = [CustomActions.uploadDicomDisk, CustomActions.viewMedicalImages].includes(
      custom_action.target_url.split('/').pop(),
    )
      ? true
      : false;
  }

  getClioMatterId(clioDocumentId) {
    return this.matters_$.getClioMatterIdFromDocumentId(clioDocumentId);
  }

  async handleMatterAction(custom_action, clioMatterId, user_id, options) {
    console.log('handleMatterAction');
    this.checkSetAvoidFilesCompare(custom_action, options);
    let newOptions = {};

    switch (options.target) {
      case CustomActions.goToNuageDx:
        this.redirectToHome();
        return;

      case CustomActions.openClioMatterFromDocument:
        const localClioMatterId = await this.getClioMatterId(clioMatterId);
        newOptions = { ...options, targetFolder: CLIO_FOLDER_NAME };
        this.openClioMatter(localClioMatterId, user_id, newOptions);
        return;

      case CustomActions.openClioMatter:
        newOptions = { ...options, targetFolder: CLIO_FOLDER_NAME };
        break;

      case CustomActions.shareDisc:
        newOptions = { ...options, targetFolder: CLIO_FOLDER_NAME };
        break;

      case CustomActions.viewMedicalImages:
        newOptions = { ...options, section: CustomActions.viewMedicalImages };
        break;

      case CustomActions.uploadDicomDisk:
        newOptions = { ...options, section: CustomActions.uploadDicomDisk };
        break;

      default:
        newOptions = options;
        break;
    }

    this.openClioMatter(clioMatterId, user_id, newOptions);
  }

  async handleDocumentAction(custom_action, clioDocumentId, user_id, options) {
    if (CustomActions.goToNuageDx === custom_action.target_url.split('/').pop()) {
      this.redirectToHome();
      return;
    }

    this.checkSetAvoidFilesCompare(custom_action, options);
    const matterInfo = await this.matters_$.getMatterInfoFromDocumentId(clioDocumentId);
    options = {
      ...options,
      documentMatterInfo: matterInfo['data']['data'],
      clioDocumentId: clioDocumentId,
      targetFolder: CLIO_FOLDER_NAME,
    };
    console.log('matterInfo :', matterInfo);
    if (matterInfo['data']['error'] === true) {
      this.uiMessaging_$.toastMessage('This document has no Matter associated in Clio', 'ERROR');
      return;
    }
    this.openClioMatter(matterInfo['data']['data']['data']['matter']['id'], user_id, options);
  }

  async openClioMatter(matterId, user_id, options) {
    console.log('openClioMatter', { matterId, user_id, options });

    const existingMatter = await this.matters_$.checkClientMatterExistsFromClioMatterId(matterId);

    if (existingMatter.size > 0) {
      this.existingMatter = existingMatter.docs[0].data();

      const nuagedxCaseFolders = await this.folders_$.getFoldersByClioMatterId(matterId);
      const clioFolder = nuagedxCaseFolders.filter(f => f.name === CLIO_FOLDER_NAME)[0];
      const { folderId, name } = await this.getFolderInfoById(clioFolder.id);

      clioFolder.folderId = folderId;
      clioFolder.name = name;
      const clioFolderId = await this.matters_$.getClioFolderIdFromCaseName(this.existingMatter.caseName);

      // The Clio Matter exists.
      const hme_options = {
        ...options,
        clioFolderId: clioFolderId,
        parentFolderId: clioFolder.folderId,
        parentFolderName: clioFolder.name,
      };

      this.handleMatterExists(matterId, this.existingMatter, hme_options);
    } else {
      // The Clio Matter does not exist.
      this.handleMatterDoesntExist(matterId, user_id, options);
    }
  }

  async getFolderInfoById(docId) {
    const { folderId, name } = <{ folderId: string; name: string }>await this.folders_$.getFolderById(docId);
    return { folderId, name };
  }

  async handleMatterDoesntExist(matterId, user_id, options) {
    try {
      const newMatterData = await this.matters_$.importMatterOnly(matterId, user_id);
      console.log('New matter data imported:', newMatterData);
      this.existingMatter = newMatterData;

      const nuagedxCaseFolders = await this.folders_$.getFoldersByClioMatterId(matterId);
      console.log('nuagedxCaseFolders: ', nuagedxCaseFolders);

      let clioFolder = nuagedxCaseFolders.filter(f => f.name === CLIO_FOLDER_NAME)[0];

      // Throw an error if the Clio folder does not exist.
      if (!clioFolder) {
        const errorMessage = 'Clio folder does not exist.';
        const message = `${errorMessage} Please try again later.`;

        console.error(errorMessage);
        this.uiMessaging_$.toastMessage(message, 'ERROR');

        // Create the 'Clio' folder in NuageDx.
        const createClioFolder = await this.folders_$.createClioFolder(newMatterData.caseName, user_id);
        const { folderId, name } = await this.getFolderInfoById(createClioFolder.id);

        clioFolder = { ...createClioFolder, folderId, name };
        console.log('clioFolder :>>', clioFolder);
      }

      const hme_options = {
        ...options,
        clioFolderId: newMatterData.clioFolderId,
        parentFolderId: clioFolder?.folderId,
        parentFolderName: clioFolder?.name,
      };
      this.handleMatterExists(matterId, newMatterData, hme_options);
    } catch (error) {
      console.error('Error importing matter:', error);
      this.uiMessaging_$.toastMessage('Failed to import matter. Please try again later.', 'ERROR');
    }
  }

  private async getClioAccessToken() {
    return (
      JSON.parse(this.auth_$.userData.getValue()['clioAccessToken'])['access_token'] ||
      (await this.auth_$.getClioAccessToken())
    );
  }

  async handleMatterExists(matterId: string, existingMatter, options) {
    const { parentFolderId, parentFolderName, clioObjectId, folderMatterInfo, target } = options;

    if (target === 'share_disc') {
      if (!parentFolderId || !parentFolderName || !clioObjectId || !folderMatterInfo || !target) {
        const data = { parentFolderId, parentFolderName, clioObjectId, folderMatterInfo, target };
        console.error('Missing required parameters in options:', data);
        this.uiMessaging_$.toastMessage('Missing required parameters. Please try again.', 'ERROR');
        return;
      }

      try {
        const clioAccessToken = await this.getClioAccessToken();

        // Define interface for better type safety
        interface ClioFolderOptions {
          folderId: string;
          folderPath: string;
          caseName: string;
          parentFolderId: string;
          parentFolderName: string;
          email: string;
        }

        // Validate required parameters
        if (!folderMatterInfo?.matter?.id || !folderMatterInfo?.id) {
          throw new Error('Invalid folder matter information');
        }

        const gcfc_options: ClioFolderOptions = {
          folderId: clioObjectId,
          folderPath: `${folderMatterInfo.matter.id}/${folderMatterInfo.id}`,
          caseName: existingMatter.caseName,
          parentFolderId,
          parentFolderName,
          email: this.auth_$.userData.getValue()['email'] || '',
        };

        const response = await this.clio_$.getClioFolderContents(gcfc_options, clioAccessToken);

        if (!response) {
          throw new Error('No response received from Clio');
        }
      } catch (error: unknown) {
        const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
        console.error('Error fetching Clio folder contents:', errorMessage);
        this.uiMessaging_$.toastMessage(`Failed to fetch Clio folder contents: ${errorMessage}`, 'ERROR');
      }
      return;
    }

    try {
      const clioDocumentsResponse = await this.matters_$.getDocumentsListFromClioMatterId(matterId);

      this.matters_$.getDocumentsAndFoldersListFromClioMatterId(matterId).then(res => {
        console.log('res :>>', res.data.data.data);
      });

      if (clioDocumentsResponse['data'].code === 501) {
        console.log(clioDocumentsResponse['data'].message);
        this.handleClioMatterNoDocuments(options);
      } else if (clioDocumentsResponse['data'].code === 200) {
        const clioDocumentsList = clioDocumentsResponse['data']['data']['data'];

        this.handleClioMatterDocuments(existingMatter, clioDocumentsList, matterId, options);
      }
    } catch (error) {
      console.error('Error fetching Clio documents:', error);
      this.uiMessaging_$.toastMessage('Failed to fetch Clio documents. Please try again later.', 'ERROR');
    }
  }

  handleClioMatterNoDocuments(options) {
    console.log('options :>', options);
    this.uiMessaging_$.toastMessage('This matter has no documents', 'INFORMATION');
    this.matters_$.openExistingMatter(this.existingMatter, this.auth_$.userData.getValue()['id'], {
      ...options,
      targetFolder: CLIO_FOLDER_NAME,
    });
  }

  async handleClioMatterDocuments(existingMatter, clioDocumentsList, matterId: string, options) {
    const existingMatterDocuments = await this.matters_$.getDocumentsListFromCaseName(
      existingMatter.caseName,
      options.clioFolderId,
    );

    const shouldCompareDocuments =
      !options.avoidFilesCompare &&
      !this.comparisonWindowOpened &&
      (existingMatterDocuments.length === 0 ||
        this.checkIfPendingDocumentsToBeImported(clioDocumentsList, existingMatterDocuments) === 'yes');

    if (shouldCompareDocuments) {
      this.handleCompareDocuments(clioDocumentsList, existingMatterDocuments, matterId, options, existingMatter);
    } else {
      this.handleNoPendingDocumentsToBeImported(options);
    }
  }

  handleNoPendingDocumentsToBeImported(options) {
    console.log('options :>>', options);
    this.uiMessaging_$.toastMessage('All documents have already been imported', 'INFORMATION');
    this.matters_$.openExistingMatter(this.existingMatter, this.auth_$.userData.getValue()['id'], options);
  }

  checkIfPendingDocumentsToBeImported(clioDocumentsList, existingMatterDocuments) {
    if (existingMatterDocuments.length === 0) {
      return 'yes';
    }

    let answer = 'no';
    for (let index = 0; index < clioDocumentsList.length; index++) {
      const document = clioDocumentsList[index];
      if (!existingMatterDocuments.find(doc => doc.clioDocId === document.id)) {
        answer = 'yes';
        break;
      }
    }

    return answer;
  }

  handleCompareDocuments(clioDocumentsList, existingMatterDocuments, matterId: string, options, existingMatter) {
    if (this.windowsManager_$.clientProfileOpened) {
      console.log('clientProfileOpened');
    }

    const ofcm_options = {
      ...options,
      caseName: existingMatter.caseName,
      matterData: existingMatter,
      clioFolderId: options.clioFolderId,
    };

    this.matters_$
      .openFilesComparisonModal(clioDocumentsList, existingMatterDocuments, matterId, ofcm_options)
      .afterAllClosed.subscribe(() => {
        this.comparisonWindowOpened = false;
      });

    this.comparisonWindowOpened = true;
  }

  private redirectToHome() {
    this.router.navigate(['/']);
  }

  private handleUIReference(custom_action, clioObjectId, user_id, target) {
    switch (custom_action.ui_reference) {
      case 'folders/show':
        const clioFolderId = clioObjectId;
        // this.removeClioCustomActionUrlParams();
        this.handleFolderAction(custom_action, clioFolderId, user_id, { target, clioObjectId });
        break;
      case 'matters/show':
        const clioMatterId = clioObjectId;
        // this.removeClioCustomActionUrlParams();
        this.handleMatterAction(custom_action, clioMatterId, user_id, { target, clioObjectId });
        break;
      case 'documents/show':
        const clioDocumentId = clioObjectId;
        // this.removeClioCustomActionUrlParams();
        this.handleDocumentAction(custom_action, clioDocumentId, user_id, { target, clioObjectId });
        break;
      case 'contact/show':
      case 'activities/show':
        this.uiMessaging_$.toastMessage(`This action is not supported yet`, 'ERROR');
        break;
      default:
        break;
    }
  }
}
