import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { UIMessagingService } from './uimessaging.service';
import { FirebaseUtilitiesService } from './firebase-utilities.service';
const color1 = '#c5f0f6';
@Injectable({
  providedIn: 'root',
})
export class FoldersService {
  constructor(
    private auth_$: AuthService,
    private uiMessaging_$: UIMessagingService,
    private firebase_utilities_$: FirebaseUtilitiesService,
  ) {}

  checkDuplicatedFolder(folderName: string, caseFolders): boolean {
    return Boolean(caseFolders.find(folder => folder.name.toLowerCase().trim() === folderName.toLowerCase().trim()));
  }

  async getFolderById(docId: string) {
    return await this.firebase_utilities_$.getFolderByDocId(docId);
  }

  createClioFolder(caseName: any, user_id: any) {
    // Validate the parameters
    if (!caseName) {
      console.error('Matter ID is required');
      return;
    }
    if (!user_id) {
      console.error('User ID is required');
      return;
    }

    const options = {
      color: color1,
      type: 'clio',
      predefined: true,
      forDelete: false,
      modal: false,
      createdBy: user_id,
    };

    return this.createFolder('Clio', caseName, options, []);
  }

  getFoldersByClioMatterId(clioMatterId: string) {
    return this.firebase_utilities_$.getFoldersByClioMatterId(clioMatterId);
  }

  async createFolder(
    folderName: string,
    caseName: string,
    options = {
      color: color1,
      type: 'folder',
      predefined: false,
      forDelete: false,
      modal: false,
      createdBy: this.auth_$.userData.getValue()['uid'],
    },
    caseFolders: any[],
  ) {
    // Validate the parameters
    if (!folderName) {
      console.error('Folder name is required');
      return;
    }

    if (!caseName) {
      console.error('Case name is required');
      return;
    }

    if (!options.createdBy) {
      console.error('Created by is required');
      return;
    }

    if (this.checkDuplicatedFolder(folderName, caseFolders)) {
      if (options.modal) {
        console.error('Folder name already taken');
        this.uiMessaging_$.toastMessage(`Folder name ${folderName} is already taken`, ``);
      }
      return;
    }

    this.auth_$.showLoader(`Creating folder ${folderName}...`);
    const folderId = this.getRandomString(20);
    const folderObject = {
      children: ``,
      parentFolder: ``,
      folderId: folderId,
      belongsTo: caseName,
      name: folderName.trim(),
      color: options.color,
      type: options.type,
      predefined: options.predefined,
      forDelete: options.forDelete,
      createdBy: options.createdBy,
    };

    const result = await this.firebase_utilities_$.createFolder(folderObject);
    this.auth_$.hideLoader();
    return result;
  }

  getRandomString(stringLength: number = 10) {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    let randomstring = '';
    for (let i = 0; i < stringLength; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }
}
