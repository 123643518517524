// NOTE: Compare with PROD-download-disc branch.

import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { UserRoles } from 'src/app/dictionaries/UserRoles';
import { AuthService } from 'src/app/services/auth.service';
import { GapiOperationsService } from 'src/app/services/gapi-operations.service';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from 'src/app/dialog.service';
import { FilesComparisonComponent } from '../ui/files-comparison/files-comparison.component';
import { FileTransferV2Component } from '../file-transfer-v2/file-transfer-v2.component';
import { FileTransferComponent } from '../file-transfer/file-transfer.component';

const dicomdirFileName = 'DICOMDIR';
@Component({
  selector: 'app-sysadmin',
  templateUrl: './sysadmin.component.html',
})
export class SysadminComponent implements OnInit {
  ownersList = [];
  roleselected: string;
  usersList: any[] = [];
  dataTableSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['email', 'name', 'recurlyaccountcode'];

  constructor(
    private gAPI_$: GapiOperationsService,
    private auth_$: AuthService,
    private dialog_$: DialogService,
  ) {}

  roles = [
    {
      value: UserRoles.admin,
      label: UserRoles.admin,
    },
    {
      value: UserRoles.associate,
      label: UserRoles.associate,
    },
    {
      value: UserRoles.consultant,
      label: UserRoles.consultant,
    },
    {
      value: UserRoles.client,
      label: UserRoles.client,
    },
    {
      value: UserRoles.owner,
      label: UserRoles.owner,
    },
    {
      value: UserRoles.superuser,
      label: UserRoles.superuser,
    },
  ];

  filterText = '';

  get filteredOwnersList() {
    return this.ownersList.filter(owner =>
      `${owner.name || owner.firstName + owner.lastName} `.toLowerCase().includes(this.filterText.toLowerCase()),
    );
  }

  onRoleSelectedChange() {
    this.roleselected = this.roleselected;
  }

  listUsersByRole() {
    if (!this.roleselected) {
      alert('Please select a role');
      return;
    }
    // List all users with the provided role.
    firebase
      .firestore()
      .collection('users')
      .where('role', '==', this.roleselected)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const { name, recurlyaccountcode, email } = doc.data();
          const user = { name, recurlyaccountcode, email };
          this.usersList.push(user);
        });
        this.dataTableSource = new MatTableDataSource(this.usersList);
        this.usersList = [];
      })
      .catch(err => console.error(err));
  }

  addClioCustomAction(jsonString: string): void {
    const json = JSON.parse(jsonString);
    const { label, target_url, ui_reference, id } = json;
    console.log('json: ', json);
    firebase
      .firestore()
      .collection('users')
      .doc('qLAWUzBw7tBCBCFASKET')
      .update({
        clioCustomActions: firebase.firestore.FieldValue.arrayUnion({
          id: parseInt(id),
          target_url,
          label,
          ui_reference,
          updated_at: new Date().toISOString(),
          created_at: new Date().toISOString(),
        }),
      })
      .then(() => console.log('Custom action added successfully'));
  }

  listAllFilesWithSharedUsers() {
    firebase
      .firestore()
      .collection('files')
      .where('sharedUsers', '>', [])
      .get()
      .then(querySnapshot => {
        let contador = 0;
        querySnapshot.forEach(doc => {
          const docid = doc.id;
          const sharedUsers = doc.data().sharedUsers;
          if (sharedUsers.length > 0) {
            // console.log('doc :', doc.data());
            const needAction = sharedUsers.some((su, index) => {
              if (su.userEmail > '' && su.userName > '') {
                return true;
              } else {
                return false;
              }
            });
            if (needAction) {
              contador++;
              const newSharedUsers = sharedUsers.map(item => ({
                email: item.userEmail.toLowerCase(),
                name: item.userName,
              }));
              firebase.firestore().collection('files').doc(docid).update({ sharedUsers: newSharedUsers });
              console.log('doc :', doc.data());
            }
          }
        });
        console.log('contador :', contador);
      });
  }

  deleteAllFilesForDelete() {
    firebase
      .firestore()
      .collection('files')
      .where('forDelete', '==', true)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          doc.ref.delete();
        });
      });
  }

  ngOnInit() {
    this.auth_$.preventRedirections = true;
  }

  getReportedSizeByOwnerId(id: string) {
    firebase
      .functions()
      .httpsCallable('admin-getReportedSizeByOwnerId')({ ownerId: id })
      .then(({ data }) => {
        console.log('Files total size (MB) :', data);
      });
  }

  handleListClioUsers() {
    console.log('===========================================================');
    firebase
      .firestore()
      .collection('users')
      .where('lpm', '==', 'clio')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // console.log(doc.data());
          const userData = doc.data();
          if (!userData.clioAccessToken) console.log('no Clio Access Token: ', userData.email);
          else if (JSON.parse(userData.clioAccessToken).error)
            console.log('errored Clio Access Token: ', userData.email);
        });
        console.log('===========================================================');
      });
  }

  getStorageSizeByOwnerId(id: string) {
    firebase
      .functions()
      .httpsCallable('admin-getStorageSizeByOwnerId')({ ownerId: id })
      .then(({ data }) => {
        console.log('Files total size (MB) :', data);
      });
  }

  deleteNotifications(notificationType, userRole) {
    firebase
      .firestore()
      .collection('notifications')
      .where('type', '==', notificationType)
      .where('userRole', '==', userRole)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          doc.ref.delete();
        });
      });
  }

  deleteNotificationsByFileId(event, fileId) {
    console.log('fileId :', fileId.value);
    event.preventDefault();
    firebase
      .firestore()
      .collection('notifications')
      .where('fileId', '==', fileId.value)
      .get()
      .then(querySnapshot => {
        console.log('querySnapshot size :', querySnapshot.size);
        querySnapshot.forEach(doc => {
          console.log('doc :', doc.data());
          doc.ref.delete();
        });
      })
      .catch(err => console.error(err));
  }

  cleanNotificationsIWithoutNodeId() {
    firebase
      .firestore()
      .collection('notifications')
      .where('noteId', '==', '')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log('doc :', doc.data());
          doc.ref.delete();
        });
      });
  }

  checkingData() {
    firebase
      .firestore()
      .collection('users')
      .where('email', '==', 'jandbee14+consultantorr@gmail.com')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log('doc :', doc.data());
        });
      });
  }

  async removeSharingOfUsersDeleted() {
    const listOfUsers = (await firebase.firestore().collection('users').get()).docs.map(doc => doc.data().email);

    firebase
      .firestore()
      .collection('files')
      .where('sharedUsers', '>', [])
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const sharedUsers = doc.data().sharedUsers;
          if (sharedUsers && sharedUsers.length > 0) {
            const newSharedUsers = sharedUsers.filter(item => listOfUsers.includes(item.email));
            if (newSharedUsers.length !== sharedUsers.length) {
              firebase.firestore().collection('files').doc(doc.id).update({ sharedUsers: newSharedUsers });
            }
          }
        });
      });
  }

  async clearDuplicatedFolders(foldername: string, caseName?: string) {
    this.auth_$.showLoader('Clearing duplicated folders...');
    const folders = await this.getFoldersData(foldername);
    console.log('folders: ', folders);
    folders.sort((a, b) => (a['belongsTo'] < b['belongsTo'] ? 1 : -1));

    const result = [];
    let lastFolderName = '';
    let index = 0;

    for (const folder of folders) {
      const belongsTo = folder['belongsTo'];
      const forDelete = folder['forDelete'];
      const folderId = folder['id'];

      if (belongsTo !== lastFolderName && forDelete === false) {
        lastFolderName = belongsTo;
        index++;
        result[index] = [];
        result[index]['name'] = lastFolderName;
      }

      if (Object.keys(folder).length > 0) result[index].push({ ...folder, empty: await this.isEmptyFolder(folderId) });
      else result[index].push({ ...folder, empty: true });

      // result[index].push({ ...folder, empty: await this.isEmptyFolder(folderId) });
      // console.log('sysAdmin:result :', result);
    }

    const duplicatedFolders = result.filter(item => item.length > 1);
    console.log('duplicated ' + foldername + ' folders :', duplicatedFolders);

    // for (const foldersGroup of duplicatedFolders) {
    //   const emptyCount = foldersGroup.filter(folder => folder.empty).length;
    //   const notEmptyCount = foldersGroup.filter(folder => !folder.empty).length;
    //   console.log(foldersGroup.name, { emptyCount: emptyCount, notEmptyCount: notEmptyCount });
    //   if (notEmptyCount > 1) {
    //     console.log('PROBLEMS :', foldersGroup.name);
    //     await this.relocateFiles(foldersGroup.filter(folder => !folder.empty));
    //   } else if (emptyCount > 1) {
    //     console.log('Empties :', foldersGroup.name);
    //   }

    //   if (notEmptyCount > 0 && emptyCount > 0) {
    //     this.deleteEmpties(foldersGroup.filter(folder => folder.empty));
    //   }

    //   if (emptyCount > 1 && notEmptyCount === 0) {
    //     await this.deleteAllButOneEmpty(foldersGroup.filter(folder => folder.empty));
    //   }
    // }
    this.auth_$.hideLoader();
  }

  compareOpenFileTransfer(caseName) {
    this.auth_$.showLoader('Comparing open files...');
    this.dialog_$.open(FileTransferComponent, {
      id: 'compare-open-files-dialog',
      width: '70%',
    });
  }

  compareOpenFileTransferV2(caseName) {
    this.auth_$.showLoader('Comparing open files...');
    this.dialog_$.open(FileTransferV2Component, {
      id: 'compare-open-files-dialog',
      width: '70%',
      data: {
        clioFolderId: '1350664924',
      },
    });
  }

  compareOpenFiles(caseName: string) {
    this.auth_$.showLoader('Comparing open files...');
    this.dialog_$.open(FilesComparisonComponent, {
      id: 'compare-open-files-dialog',
      data: {
        sourceDocuments: [
          {
            id: 8011674066,
            created_at: '2022-11-03T13:58:14-07:00',
            parent: {
              id: 4964635219,
            },
            type: 'Document',
            name: 'XRAY-N0293842.jpg',
            filename: 'dog.jpg',
            size: 423598,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 8011677951,
            created_at: '2022-11-03T13:58:28-07:00',
            parent: {
              id: 4964635219,
            },
            type: 'Document',
            name: 'pelota.png',
            filename: 'pelota.png',
            size: 1429638,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: true,
          },
          {
            id: 8308834641,
            created_at: '2022-12-06T19:36:08-08:00',
            parent: {
              id: 4964635219,
            },
            type: 'Document',
            name: '4596381001.pdf',
            filename: '4596381001.pdf',
            size: 28335,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 8332292691,
            created_at: '2022-12-08T20:06:24-08:00',
            parent: {
              id: 4964635219,
            },
            type: 'Document',
            name: 'request_result_example.png',
            filename: 'request_result_example.png',
            size: 36491,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: true,
          },
          {
            id: 8595145370,
            created_at: '2023-01-06T15:35:54-08:00',
            parent: {
              id: 4964635219,
            },
            type: 'Document',
            name: 'insect.jpg',
            filename: 'insect.jpg',
            size: 308069,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 8748552020,
            created_at: '2023-01-23T05:41:04-08:00',
            parent: {
              id: 4964635219,
            },
            type: 'Document',
            name: 'broken-wrist.jpg',
            filename: 'broken-wrist.jpg',
            size: 43718,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 8762600539,
            created_at: '2023-01-24T00:58:46-08:00',
            parent: {
              id: 4964635219,
            },
            type: 'Document',
            name: 'Colorlol.jpg',
            filename: 'Colorlol.jpg',
            size: 27056,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: true,
          },
          {
            id: 8920386169,
            created_at: '2023-02-07T09:55:33-08:00',
            parent: {
              id: 4964635219,
            },
            type: 'Document',
            name: 'medical-test.jpg',
            filename: 'medical-test.jpg',
            size: 27364,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759756007,
            created_at: '2025-01-29T14:21:53-08:00',
            parent: {
              id: 17759755947,
            },
            type: 'Document',
            name: 'AUTORUN.INF',
            filename: 'AUTORUN.INF',
            size: 28,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759756262,
            created_at: '2025-01-29T14:21:54-08:00',
            parent: {
              id: 17759755947,
            },
            type: 'Document',
            name: 'COMMAND.TXT',
            filename: 'COMMAND.TXT',
            size: 2497,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759756487,
            created_at: '2025-01-29T14:21:55-08:00',
            parent: {
              id: 17759755947,
            },
            type: 'Document',
            name: 'DCSSTART.EXE',
            filename: 'DCSSTART.EXE',
            size: 45568,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759756712,
            created_at: '2025-01-29T14:21:57-08:00',
            parent: {
              id: 17759755947,
            },
            type: 'Document',
            name: 'DCSVALID',
            filename: 'DCSVALID',
            size: 82,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759756862,
            created_at: '2025-01-29T14:21:58-08:00',
            parent: {
              id: 17759755947,
            },
            type: 'Document',
            name: 'DICOMDIR',
            filename: 'DICOMDIR',
            size: 334310,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759757072,
            created_at: '2025-01-29T14:21:59-08:00',
            parent: {
              id: 17759755947,
            },
            type: 'Document',
            name: 'ENCRYPTEDDISC_README.PDF',
            filename: 'ENCRYPTEDDISC_README.PDF',
            size: 266633,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759757207,
            created_at: '2025-01-29T14:22:00-08:00',
            parent: {
              id: 17759755947,
            },
            type: 'Document',
            name: 'README.TXT',
            filename: 'README.TXT',
            size: 308,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759757327,
            created_at: '2025-01-29T14:22:02-08:00',
            parent: {
              id: 17759757297,
            },
            type: 'Document',
            name: 'DEFRES.HTM',
            filename: 'DEFRES.HTM',
            size: 610,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759757477,
            created_at: '2025-01-29T14:22:03-08:00',
            parent: {
              id: 17759757297,
            },
            type: 'Document',
            name: 'FRAMESET.HTM',
            filename: 'FRAMESET.HTM',
            size: 358,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759757627,
            created_at: '2025-01-29T14:22:04-08:00',
            parent: {
              id: 17759757297,
            },
            type: 'Document',
            name: 'LIST.HTM',
            filename: 'LIST.HTM',
            size: 3616,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759757792,
            created_at: '2025-01-29T14:22:05-08:00',
            parent: {
              id: 17759757297,
            },
            type: 'Document',
            name: 'R29156.HTM',
            filename: 'R29156.HTM',
            size: 7722,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759757912,
            created_at: '2025-01-29T14:22:06-08:00',
            parent: {
              id: 17759757297,
            },
            type: 'Document',
            name: 'R29157.HTM',
            filename: 'R29157.HTM',
            size: 8018,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759758062,
            created_at: '2025-01-29T14:22:07-08:00',
            parent: {
              id: 17759757297,
            },
            type: 'Document',
            name: 'R29158.HTM',
            filename: 'R29158.HTM',
            size: 7696,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759758197,
            created_at: '2025-01-29T14:22:08-08:00',
            parent: {
              id: 17759757297,
            },
            type: 'Document',
            name: 'R29159.HTM',
            filename: 'R29159.HTM',
            size: 7812,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759758437,
            created_at: '2025-01-29T14:22:09-08:00',
            parent: {
              id: 17759757297,
            },
            type: 'Document',
            name: 'R29160.HTM',
            filename: 'R29160.HTM',
            size: 8326,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759758692,
            created_at: '2025-01-29T14:22:10-08:00',
            parent: {
              id: 17759757297,
            },
            type: 'Document',
            name: 'R29161.HTM',
            filename: 'R29161.HTM',
            size: 13412,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759758962,
            created_at: '2025-01-29T14:22:12-08:00',
            parent: {
              id: 17759757297,
            },
            type: 'Document',
            name: 'RESULTS.XML',
            filename: 'RESULTS.XML',
            size: 39076,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759759202,
            created_at: '2025-01-29T14:22:13-08:00',
            parent: {
              id: 17759757297,
            },
            type: 'Document',
            name: 'START.HTM',
            filename: 'START.HTM',
            size: 606,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759759412,
            created_at: '2025-01-29T14:22:14-08:00',
            parent: {
              id: 17759759352,
            },
            type: 'Document',
            name: 'DATCARD.GIF',
            filename: 'DATCARD.GIF',
            size: 5973,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759759652,
            created_at: '2025-01-29T14:22:16-08:00',
            parent: {
              id: 17759759352,
            },
            type: 'Document',
            name: 'HOSPITAL.JPG',
            filename: 'HOSPITAL.JPG',
            size: 7350,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759759892,
            created_at: '2025-01-29T14:22:17-08:00',
            parent: {
              id: 17759759352,
            },
            type: 'Document',
            name: 'LOGO.BMP',
            filename: 'LOGO.BMP',
            size: 150838,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759760102,
            created_at: '2025-01-29T14:22:19-08:00',
            parent: {
              id: 17759759352,
            },
            type: 'Document',
            name: 'LOGO.GIF',
            filename: 'LOGO.GIF',
            size: 2390,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759760252,
            created_at: '2025-01-29T14:22:20-08:00',
            parent: {
              id: 17759759352,
            },
            type: 'Document',
            name: 'LOGO.JPG',
            filename: 'LOGO.JPG',
            size: 9944,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759760372,
            created_at: '2025-01-29T14:22:21-08:00',
            parent: {
              id: 17759759352,
            },
            type: 'Document',
            name: 'LOGOOLD.BMP',
            filename: 'LOGOOLD.BMP',
            size: 57354,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759760477,
            created_at: '2025-01-29T14:22:22-08:00',
            parent: {
              id: 17759759352,
            },
            type: 'Document',
            name: 'PACSCUBE.GIF',
            filename: 'PACSCUBE.GIF',
            size: 6603,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759760672,
            created_at: '2025-01-29T14:22:23-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'AXINTEROP.ACROPDFLIB.DLL',
            filename: 'AXINTEROP.ACROPDFLIB.DLL',
            size: 9216,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759760882,
            created_at: '2025-01-29T14:22:24-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'BOUNCYCASTLE.6.40.DLL',
            filename: 'BOUNCYCASTLE.6.40.DLL',
            size: 2222528,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759761077,
            created_at: '2025-01-29T14:22:26-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DATCARD.COMMON.APPPATHS.DLL',
            filename: 'DATCARD.COMMON.APPPATHS.DLL',
            size: 7168,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759761167,
            created_at: '2025-01-29T14:22:27-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DATCARD.COMMON.LOGGER.DLL',
            filename: 'DATCARD.COMMON.LOGGER.DLL',
            size: 59904,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759761302,
            created_at: '2025-01-29T14:22:28-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DATCARD.COMMON.LOGGER.DLL.CONFIG',
            filename: 'DATCARD.COMMON.LOGGER.DLL.CONFIG',
            size: 1077,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759761497,
            created_at: '2025-01-29T14:22:29-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DATCARD.CONFIG.DLL',
            filename: 'DATCARD.CONFIG.DLL',
            size: 241664,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759761587,
            created_at: '2025-01-29T14:22:31-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DATCARD.DICOM.DICTIONARY.DLL',
            filename: 'DATCARD.DICOM.DICTIONARY.DLL',
            size: 212992,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759761722,
            created_at: '2025-01-29T14:22:32-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DATCARD.DICOM.OBJECTS.J2K.CODEC.40.DLL',
            filename: 'DATCARD.DICOM.OBJECTS.J2K.CODEC.40.DLL',
            size: 28672,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759761902,
            created_at: '2025-01-29T14:22:33-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DATCARD.DICOM.OBJECTS.J2K.FACTORY.40.DLL',
            filename: 'DATCARD.DICOM.OBJECTS.J2K.FACTORY.40.DLL',
            size: 5632,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759762082,
            created_at: '2025-01-29T14:22:34-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DATCARD.MESSAGING.DICOMSERVER.DLL',
            filename: 'DATCARD.MESSAGING.DICOMSERVER.DLL',
            size: 24064,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759762307,
            created_at: '2025-01-29T14:22:35-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DATCARD.UTILITIES.FORMATTER.DLL',
            filename: 'DATCARD.UTILITIES.FORMATTER.DLL',
            size: 9216,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759762487,
            created_at: '2025-01-29T14:22:36-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DATCARD.UTILITIES.LOGGER.DLL',
            filename: 'DATCARD.UTILITIES.LOGGER.DLL',
            size: 12288,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759762772,
            created_at: '2025-01-29T14:22:38-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DATCARD.VIEWER.DATABASE.DLL',
            filename: 'DATCARD.VIEWER.DATABASE.DLL',
            size: 51712,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759762967,
            created_at: '2025-01-29T14:22:39-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DATCARD.VIEWER.USERCONTROLS.DLL',
            filename: 'DATCARD.VIEWER.USERCONTROLS.DLL',
            size: 13312,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759763192,
            created_at: '2025-01-29T14:22:40-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DATCARD.VIEWER.USERCONTROLS.DLL.CONFIG',
            filename: 'DATCARD.VIEWER.USERCONTROLS.DLL.CONFIG',
            size: 381,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759763402,
            created_at: '2025-01-29T14:22:41-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DCSLITEVIEWER.CHM',
            filename: 'DCSLITEVIEWER.CHM',
            size: 519423,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759763777,
            created_at: '2025-01-29T14:22:42-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DCSVIEW.EXE',
            filename: 'DCSVIEW.EXE',
            size: 1071616,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759764227,
            created_at: '2025-01-29T14:22:44-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DCSVIEW.EXE.CONFIG',
            filename: 'DCSVIEW.EXE.CONFIG',
            size: 1974,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759764617,
            created_at: '2025-01-29T14:22:45-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DCSVIEW.EXE.LIC',
            filename: 'DCSVIEW.EXE.LIC',
            size: 544,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759764902,
            created_at: '2025-01-29T14:22:46-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DEVCOMPONENTS.DOTNETBAR2.DLL',
            filename: 'DEVCOMPONENTS.DOTNETBAR2.DLL',
            size: 5210112,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759765427,
            created_at: '2025-01-29T14:22:50-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DEVCOMPONENTS.DOTNETBAR2.XML',
            filename: 'DEVCOMPONENTS.DOTNETBAR2.XML',
            size: 3841730,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759765802,
            created_at: '2025-01-29T14:22:53-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DICOMOBJECTS.CONFIG',
            filename: 'DICOMOBJECTS.CONFIG',
            size: 28,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759765967,
            created_at: '2025-01-29T14:22:54-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DICOMOBJECTS.NET.6.40.DLL',
            filename: 'DICOMOBJECTS.NET.6.40.DLL',
            size: 2357696,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759766207,
            created_at: '2025-01-29T14:22:56-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'DICOMOBJECTS.NET.6.40.XMLSERIALIZERS.DLL',
            filename: 'DICOMOBJECTS.NET.6.40.XMLSERIALIZERS.DLL',
            size: 25536,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759766297,
            created_at: '2025-01-29T14:22:57-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'EXECUTE.ICO',
            filename: 'EXECUTE.ICO',
            size: 7886,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759766372,
            created_at: '2025-01-29T14:22:58-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'INTEROP.ACROPDFLIB.DLL',
            filename: 'INTEROP.ACROPDFLIB.DLL',
            size: 10752,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759766462,
            created_at: '2025-01-29T14:23:00-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'POWERCOLLECTIONS.DLL',
            filename: 'POWERCOLLECTIONS.DLL',
            size: 189952,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759766777,
            created_at: '2025-01-29T14:23:01-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'POWERCOLLECTIONS.DLL.CONFIG',
            filename: 'POWERCOLLECTIONS.DLL.CONFIG',
            size: 117,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759766987,
            created_at: '2025-01-29T14:23:02-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'POWERCOLLECTIONS.XML',
            filename: 'POWERCOLLECTIONS.XML',
            size: 759024,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759767332,
            created_at: '2025-01-29T14:23:03-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'PROGRESSEX.DLL',
            filename: 'PROGRESSEX.DLL',
            size: 20480,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759767527,
            created_at: '2025-01-29T14:23:05-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'SECURITY.6.40.DLL',
            filename: 'SECURITY.6.40.DLL',
            size: 43968,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759767632,
            created_at: '2025-01-29T14:23:06-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'TRANSFORM.XSL',
            filename: 'TRANSFORM.XSL',
            size: 6347,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759767797,
            created_at: '2025-01-29T14:23:07-08:00',
            parent: {
              id: 17759760627,
            },
            type: 'Document',
            name: 'WINDOWLEVELPRESETSDEFAULT.XML',
            filename: 'WINDOWLEVELPRESETSDEFAULT.XML',
            size: 2047,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759768052,
            created_at: '2025-01-29T14:23:08-08:00',
            parent: {
              id: 17759768007,
            },
            type: 'Document',
            name: 'KDU_X86.DLL',
            filename: 'KDU_X86.DLL',
            size: 906240,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759768247,
            created_at: '2025-01-29T14:23:10-08:00',
            parent: {
              id: 17759768007,
            },
            type: 'Document',
            name: 'MSVCP100.DLL',
            filename: 'MSVCP100.DLL',
            size: 421200,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759768397,
            created_at: '2025-01-29T14:23:11-08:00',
            parent: {
              id: 17759768007,
            },
            type: 'Document',
            name: 'MSVCR100.DLL',
            filename: 'MSVCR100.DLL',
            size: 773968,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759768637,
            created_at: '2025-01-29T14:23:13-08:00',
            parent: {
              id: 17759768592,
            },
            type: 'Document',
            name: 'KDU_X64.DLL',
            filename: 'KDU_X64.DLL',
            size: 1044992,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759768997,
            created_at: '2025-01-29T14:23:14-08:00',
            parent: {
              id: 17759768592,
            },
            type: 'Document',
            name: 'MSVCP100.DLL',
            filename: 'MSVCP100.DLL',
            size: 608080,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759769237,
            created_at: '2025-01-29T14:23:16-08:00',
            parent: {
              id: 17759768592,
            },
            type: 'Document',
            name: 'MSVCR100.DLL',
            filename: 'MSVCR100.DLL',
            size: 829264,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759769822,
            created_at: '2025-01-29T14:23:18-08:00',
            parent: {
              id: 17759769702,
            },
            type: 'Document',
            name: '16134',
            filename: '16134',
            size: 1312076,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759770137,
            created_at: '2025-01-29T14:23:19-08:00',
            parent: {
              id: 17759769702,
            },
            type: 'Document',
            name: '16135',
            filename: '16135',
            size: 1458108,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759770692,
            created_at: '2025-01-29T14:23:21-08:00',
            parent: {
              id: 17759769702,
            },
            type: 'Document',
            name: '16136',
            filename: '16136',
            size: 1569894,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759771232,
            created_at: '2025-01-29T14:23:24-08:00',
            parent: {
              id: 17759771187,
            },
            type: 'Document',
            name: '16137',
            filename: '16137',
            size: 67040,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759771502,
            created_at: '2025-01-29T14:23:25-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161100',
            filename: '161100',
            size: 174562,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759771712,
            created_at: '2025-01-29T14:23:26-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161101',
            filename: '161101',
            size: 175584,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759771967,
            created_at: '2025-01-29T14:23:27-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161102',
            filename: '161102',
            size: 175776,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759772312,
            created_at: '2025-01-29T14:23:29-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161103',
            filename: '161103',
            size: 175392,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759772567,
            created_at: '2025-01-29T14:23:30-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161104',
            filename: '161104',
            size: 175836,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759772762,
            created_at: '2025-01-29T14:23:31-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161105',
            filename: '161105',
            size: 174956,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759773122,
            created_at: '2025-01-29T14:23:32-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161106',
            filename: '161106',
            size: 174418,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759773467,
            created_at: '2025-01-29T14:23:33-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161107',
            filename: '161107',
            size: 174614,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759773752,
            created_at: '2025-01-29T14:23:34-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161108',
            filename: '161108',
            size: 174960,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759773992,
            created_at: '2025-01-29T14:23:36-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161109',
            filename: '161109',
            size: 174464,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759774322,
            created_at: '2025-01-29T14:23:37-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161110',
            filename: '161110',
            size: 175848,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759774547,
            created_at: '2025-01-29T14:23:38-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161111',
            filename: '161111',
            size: 176162,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759774727,
            created_at: '2025-01-29T14:23:39-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161112',
            filename: '161112',
            size: 175052,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759774997,
            created_at: '2025-01-29T14:23:40-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161113',
            filename: '161113',
            size: 175434,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759775357,
            created_at: '2025-01-29T14:23:42-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161114',
            filename: '161114',
            size: 175336,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759775552,
            created_at: '2025-01-29T14:23:43-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161115',
            filename: '161115',
            size: 175918,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759775822,
            created_at: '2025-01-29T14:23:44-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161116',
            filename: '161116',
            size: 174818,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759776032,
            created_at: '2025-01-29T14:23:45-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161117',
            filename: '161117',
            size: 175246,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759776197,
            created_at: '2025-01-29T14:23:46-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161118',
            filename: '161118',
            size: 177290,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759776422,
            created_at: '2025-01-29T14:23:48-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161119',
            filename: '161119',
            size: 178214,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759776857,
            created_at: '2025-01-29T14:23:49-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161120',
            filename: '161120',
            size: 180870,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759777202,
            created_at: '2025-01-29T14:23:50-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161121',
            filename: '161121',
            size: 180426,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759777532,
            created_at: '2025-01-29T14:23:51-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161122',
            filename: '161122',
            size: 179640,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759777712,
            created_at: '2025-01-29T14:23:53-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161123',
            filename: '161123',
            size: 180634,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759777922,
            created_at: '2025-01-29T14:23:54-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161124',
            filename: '161124',
            size: 180546,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759778162,
            created_at: '2025-01-29T14:23:55-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161125',
            filename: '161125',
            size: 179946,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759778402,
            created_at: '2025-01-29T14:23:56-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161126',
            filename: '161126',
            size: 180642,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759778642,
            created_at: '2025-01-29T14:23:57-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161127',
            filename: '161127',
            size: 180360,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759778927,
            created_at: '2025-01-29T14:23:59-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161128',
            filename: '161128',
            size: 180606,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759779122,
            created_at: '2025-01-29T14:24:00-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161129',
            filename: '161129',
            size: 180924,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759779362,
            created_at: '2025-01-29T14:24:01-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161130',
            filename: '161130',
            size: 178990,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759779497,
            created_at: '2025-01-29T14:24:02-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161131',
            filename: '161131',
            size: 180206,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759779602,
            created_at: '2025-01-29T14:24:03-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161132',
            filename: '161132',
            size: 177510,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759779827,
            created_at: '2025-01-29T14:24:05-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161133',
            filename: '161133',
            size: 178170,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759780007,
            created_at: '2025-01-29T14:24:06-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161134',
            filename: '161134',
            size: 178134,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759780127,
            created_at: '2025-01-29T14:24:07-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161135',
            filename: '161135',
            size: 176736,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759780352,
            created_at: '2025-01-29T14:24:09-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161136',
            filename: '161136',
            size: 176480,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759780517,
            created_at: '2025-01-29T14:24:10-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161137',
            filename: '161137',
            size: 175392,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759780727,
            created_at: '2025-01-29T14:24:11-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161138',
            filename: '161138',
            size: 175916,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759781012,
            created_at: '2025-01-29T14:24:12-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161139',
            filename: '161139',
            size: 177430,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759781267,
            created_at: '2025-01-29T14:24:13-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161140',
            filename: '161140',
            size: 177560,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759781567,
            created_at: '2025-01-29T14:24:15-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161141',
            filename: '161141',
            size: 176992,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759781687,
            created_at: '2025-01-29T14:24:16-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161142',
            filename: '161142',
            size: 174812,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759781837,
            created_at: '2025-01-29T14:24:17-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161143',
            filename: '161143',
            size: 175762,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759782167,
            created_at: '2025-01-29T14:24:18-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161144',
            filename: '161144',
            size: 175514,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759782302,
            created_at: '2025-01-29T14:24:19-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161145',
            filename: '161145',
            size: 176264,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759782482,
            created_at: '2025-01-29T14:24:21-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161146',
            filename: '161146',
            size: 175876,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759782677,
            created_at: '2025-01-29T14:24:22-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161147',
            filename: '161147',
            size: 176080,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759782932,
            created_at: '2025-01-29T14:24:23-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161148',
            filename: '161148',
            size: 174688,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759783157,
            created_at: '2025-01-29T14:24:25-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161149',
            filename: '161149',
            size: 175532,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759783487,
            created_at: '2025-01-29T14:24:26-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161150',
            filename: '161150',
            size: 176324,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759783757,
            created_at: '2025-01-29T14:24:27-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161151',
            filename: '161151',
            size: 176918,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759783907,
            created_at: '2025-01-29T14:24:29-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161152',
            filename: '161152',
            size: 177042,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759784042,
            created_at: '2025-01-29T14:24:30-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161153',
            filename: '161153',
            size: 177630,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759784312,
            created_at: '2025-01-29T14:24:31-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161154',
            filename: '161154',
            size: 176398,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759784567,
            created_at: '2025-01-29T14:24:32-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161155',
            filename: '161155',
            size: 175762,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759784867,
            created_at: '2025-01-29T14:24:33-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161156',
            filename: '161156',
            size: 178842,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759785092,
            created_at: '2025-01-29T14:24:34-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161157',
            filename: '161157',
            size: 177352,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759785347,
            created_at: '2025-01-29T14:24:35-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161158',
            filename: '161158',
            size: 177956,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759785662,
            created_at: '2025-01-29T14:24:37-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161159',
            filename: '161159',
            size: 178506,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759786022,
            created_at: '2025-01-29T14:24:38-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161160',
            filename: '161160',
            size: 177834,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759786187,
            created_at: '2025-01-29T14:24:39-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161161',
            filename: '161161',
            size: 178984,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759786472,
            created_at: '2025-01-29T14:24:41-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161162',
            filename: '161162',
            size: 180600,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759786622,
            created_at: '2025-01-29T14:24:42-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161163',
            filename: '161163',
            size: 179980,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759786697,
            created_at: '2025-01-29T14:24:43-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161164',
            filename: '161164',
            size: 180168,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759786802,
            created_at: '2025-01-29T14:24:44-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161165',
            filename: '161165',
            size: 180352,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759786877,
            created_at: '2025-01-29T14:24:45-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161166',
            filename: '161166',
            size: 180792,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759787027,
            created_at: '2025-01-29T14:24:47-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161167',
            filename: '161167',
            size: 182012,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759787162,
            created_at: '2025-01-29T14:24:48-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161168',
            filename: '161168',
            size: 181618,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759787327,
            created_at: '2025-01-29T14:24:49-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161169',
            filename: '161169',
            size: 180042,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759787492,
            created_at: '2025-01-29T14:24:50-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161170',
            filename: '161170',
            size: 181092,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759787657,
            created_at: '2025-01-29T14:24:51-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161171',
            filename: '161171',
            size: 179834,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759787852,
            created_at: '2025-01-29T14:24:53-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161172',
            filename: '161172',
            size: 179926,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759788137,
            created_at: '2025-01-29T14:24:54-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '161173',
            filename: '161173',
            size: 181288,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759788287,
            created_at: '2025-01-29T14:24:55-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16138',
            filename: '16138',
            size: 164644,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759788452,
            created_at: '2025-01-29T14:24:56-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16139',
            filename: '16139',
            size: 164482,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759788647,
            created_at: '2025-01-29T14:24:57-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16140',
            filename: '16140',
            size: 165032,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759788842,
            created_at: '2025-01-29T14:24:58-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16141',
            filename: '16141',
            size: 167802,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759788947,
            created_at: '2025-01-29T14:25:00-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16142',
            filename: '16142',
            size: 169114,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759789157,
            created_at: '2025-01-29T14:25:01-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16143',
            filename: '16143',
            size: 169160,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759789337,
            created_at: '2025-01-29T14:25:03-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16144',
            filename: '16144',
            size: 168780,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759789442,
            created_at: '2025-01-29T14:25:04-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16145',
            filename: '16145',
            size: 168818,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759789547,
            created_at: '2025-01-29T14:25:05-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16146',
            filename: '16146',
            size: 170050,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759789667,
            created_at: '2025-01-29T14:25:07-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16147',
            filename: '16147',
            size: 171258,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759789817,
            created_at: '2025-01-29T14:25:08-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16148',
            filename: '16148',
            size: 170864,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759790102,
            created_at: '2025-01-29T14:25:10-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16149',
            filename: '16149',
            size: 170042,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759790327,
            created_at: '2025-01-29T14:25:11-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16150',
            filename: '16150',
            size: 169438,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759790762,
            created_at: '2025-01-29T14:25:12-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16151',
            filename: '16151',
            size: 169848,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759791032,
            created_at: '2025-01-29T14:25:13-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16152',
            filename: '16152',
            size: 170716,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759791257,
            created_at: '2025-01-29T14:25:14-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16153',
            filename: '16153',
            size: 170388,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759791527,
            created_at: '2025-01-29T14:25:15-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16154',
            filename: '16154',
            size: 169664,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759791782,
            created_at: '2025-01-29T14:25:17-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16155',
            filename: '16155',
            size: 169184,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759792112,
            created_at: '2025-01-29T14:25:18-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16156',
            filename: '16156',
            size: 168776,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759792442,
            created_at: '2025-01-29T14:25:19-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16157',
            filename: '16157',
            size: 168686,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759792742,
            created_at: '2025-01-29T14:25:20-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16158',
            filename: '16158',
            size: 168072,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759792997,
            created_at: '2025-01-29T14:25:21-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16159',
            filename: '16159',
            size: 166834,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759793207,
            created_at: '2025-01-29T14:25:22-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16160',
            filename: '16160',
            size: 166194,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759793462,
            created_at: '2025-01-29T14:25:23-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16161',
            filename: '16161',
            size: 166090,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759793717,
            created_at: '2025-01-29T14:25:25-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16162',
            filename: '16162',
            size: 166680,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759794167,
            created_at: '2025-01-29T14:25:26-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16163',
            filename: '16163',
            size: 166522,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759794452,
            created_at: '2025-01-29T14:25:27-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16164',
            filename: '16164',
            size: 165560,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759794707,
            created_at: '2025-01-29T14:25:28-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16165',
            filename: '16165',
            size: 164294,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759794842,
            created_at: '2025-01-29T14:25:30-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16166',
            filename: '16166',
            size: 163744,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759795007,
            created_at: '2025-01-29T14:25:31-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16167',
            filename: '16167',
            size: 164598,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759795232,
            created_at: '2025-01-29T14:25:32-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16168',
            filename: '16168',
            size: 165778,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759795307,
            created_at: '2025-01-29T14:25:33-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16169',
            filename: '16169',
            size: 165704,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759795382,
            created_at: '2025-01-29T14:25:34-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16170',
            filename: '16170',
            size: 165052,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759795532,
            created_at: '2025-01-29T14:25:35-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16171',
            filename: '16171',
            size: 165278,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759795592,
            created_at: '2025-01-29T14:25:37-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16172',
            filename: '16172',
            size: 166022,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759795712,
            created_at: '2025-01-29T14:25:38-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16173',
            filename: '16173',
            size: 167184,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759795877,
            created_at: '2025-01-29T14:25:39-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16174',
            filename: '16174',
            size: 167766,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759796027,
            created_at: '2025-01-29T14:25:40-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16175',
            filename: '16175',
            size: 166868,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759796147,
            created_at: '2025-01-29T14:25:41-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16176',
            filename: '16176',
            size: 165808,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759796312,
            created_at: '2025-01-29T14:25:42-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16177',
            filename: '16177',
            size: 165642,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759796432,
            created_at: '2025-01-29T14:25:44-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16178',
            filename: '16178',
            size: 166044,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759796582,
            created_at: '2025-01-29T14:25:45-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16179',
            filename: '16179',
            size: 166514,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759796852,
            created_at: '2025-01-29T14:25:46-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16180',
            filename: '16180',
            size: 166302,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759797032,
            created_at: '2025-01-29T14:25:47-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16181',
            filename: '16181',
            size: 166000,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759797257,
            created_at: '2025-01-29T14:25:48-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16182',
            filename: '16182',
            size: 166706,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759797437,
            created_at: '2025-01-29T14:25:50-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16183',
            filename: '16183',
            size: 167142,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759797797,
            created_at: '2025-01-29T14:25:51-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16184',
            filename: '16184',
            size: 168402,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759798082,
            created_at: '2025-01-29T14:25:52-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16185',
            filename: '16185',
            size: 168326,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759798322,
            created_at: '2025-01-29T14:25:53-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16186',
            filename: '16186',
            size: 167986,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
          {
            id: 17759798697,
            created_at: '2025-01-29T14:25:55-08:00',
            parent: {
              id: 17759771427,
            },
            type: 'Document',
            name: '16187',
            filename: '16187',
            size: 168084,
            matter: {
              id: 1350664924,
              display_number: '00002-Mayerlen',
              etag: '"508182dc618169726a68f6d0d2c36334"',
            },
            existing: false,
          },
        ],
        localDocuments: [
          {
            forDelete: false,
            parentFolderName: 'Images',
            uploadedDate: '2025-02-27T19:24:53.744Z',
            fileDesc: '',
            fdate: '2025-02-27T19:24:53.744Z',
            scanAnalysisId: '',
            creator: '',
            parentFolder: '17991637032',
            notes: [],
            ftype: 'File',
            belongsTo: '00002_Mayerlen',
            filePath: 'patient/00002_Mayerlen/Clio/Images/10.5923.j.ajbe.20160602.02_012.gif',
            viewerurl: '',
            type: 'file',
            ftime: '19:24:53 GMT+0000 (Coordinated Universal Time)',
            fileName: '10.5923.j.ajbe.20160602.02_012.gif',
            storename: '',
            origin: 'clio',
            fileId: '17991650517_00002_Mayerlen_460',
            lastModified: '2025-02-27T19:24:53.744Z',
            clioDocId: 17991650517,
          },
          {
            uploadedDate: '2025-02-27T19:20:31.546Z',
            fileDesc: '',
            fileId: '17991651387_00002_Mayerlen_73',
            clioDocId: 17991651387,
            scanAnalysisId: '',
            filePath: 'patient/00002_Mayerlen/Clio/Images/cardiovascular-health.jpg',
            belongsTo: '00002_Mayerlen',
            ftype: 'File',
            parentFolder: '17991637032',
            forDelete: false,
            type: 'file',
            fdate: '2025-02-27T19:20:31.546Z',
            lastModified: '2025-02-27T19:20:31.546Z',
            fileName: 'cardiovascular-health.jpg',
            storename: '',
            ftime: '19:20:31 GMT+0000 (Coordinated Universal Time)',
            notes: [],
            creator: '',
            parentFolderName: 'Images',
            origin: 'clio',
            viewerurl: '',
          },
          {
            scanAnalysisId: '',
            viewerurl: '',
            parentFolderName: 'Images',
            creator: '',
            parentFolder: '17991637032',
            origin: 'clio',
            type: 'file',
            uploadedDate: '2025-02-26T18:45:42.907Z',
            notes: [],
            ftime: '18:45:42 GMT+0000 (Coordinated Universal Time)',
            clioDocId: 17991651642,
            belongsTo: '00002_Mayerlen',
            storename: '',
            forDelete: false,
            filePath: 'patient/00002_Mayerlen/Clio/Images/F1.png',
            lastModified: '2025-02-26T18:45:42.907Z',
            ftype: 'File',
            fileId: '17991651642_00002_Mayerlen_546',
            fdate: '2025-02-26T18:45:42.907Z',
            fileName: 'F1.png',
            fileDesc: '',
          },
          {
            clioDocId: 17991651972,
            ftype: 'File',
            scanAnalysisId: '',
            parentFolderName: 'Images',
            parentFolder: '17991637032',
            fileId: '17991651972_00002_Mayerlen_896',
            filePath: 'patient/00002_Mayerlen/Clio/Images/Multiple-Myeloma.jpg',
            viewerurl: '',
            type: 'file',
            belongsTo: '00002_Mayerlen',
            fdate: '2025-02-27T19:20:58.147Z',
            creator: '',
            forDelete: false,
            ftime: '19:20:58 GMT+0000 (Coordinated Universal Time)',
            uploadedDate: '2025-02-27T19:20:58.147Z',
            fileDesc: '',
            storename: '',
            notes: [],
            lastModified: '2025-02-27T19:20:58.147Z',
            fileName: 'Multiple-Myeloma.jpg',
            origin: 'clio',
          },
          {
            uploadedDate: '2025-02-26T18:46:48.508Z',
            parentFolder: 'Simple_00002_Mayerlen_0idS9H7ARq6X4unT6rG1_775',
            filePath: 'patient/00002_Mayerlen/Clio/Simple/Colorlol.jpg',
            ftime: '18:46:48 GMT+0000 (Coordinated Universal Time)',
            clioDocId: 18044432232,
            forDelete: false,
            fileName: 'Colorlol.jpg',
            notes: [],
            type: 'file',
            parentFolderName: 'Simple',
            fdate: '2025-02-26T18:46:48.507Z',
            scanAnalysisId: '',
            belongsTo: '00002_Mayerlen',
            fileId: '18044432232_00002_Mayerlen_549',
            origin: 'clio',
            viewerurl: '',
            creator: '',
            lastModified: '2025-02-26T18:46:48.508Z',
            fileDesc: '',
            storename: '',
            ftype: 'File',
          },
          {
            type: 'file',
            scanAnalysisId: '',
            belongsTo: '00002_Mayerlen',
            forDelete: false,
            clioDocId: 18044434677,
            fileName: 'broken-wrist-1.jpg',
            fileId: '18044434677_00002_Mayerlen_50',
            origin: 'clio',
            fdate: '2025-02-26T18:47:09.309Z',
            filePath: 'patient/00002_Mayerlen/Clio/Simple/broken-wrist-1.jpg',
            fileDesc: '',
            parentFolderName: 'Simple',
            storename: '',
            lastModified: '2025-02-26T18:47:09.309Z',
            viewerurl: '',
            parentFolder: 'Simple_00002_Mayerlen_0idS9H7ARq6X4unT6rG1_775',
            creator: '',
            notes: [],
            uploadedDate: '2025-02-26T18:47:09.309Z',
            ftype: 'File',
            ftime: '18:47:09 GMT+0000 (Coordinated Universal Time)',
          },
          {
            parentFolderName: 'Simple',
            uploadedDate: '2025-02-26T18:47:25.908Z',
            forDelete: false,
            storename: '',
            fileId: '18044434692_00002_Mayerlen_321',
            filePath: 'patient/00002_Mayerlen/Clio/Simple/head injuty victim.jpg',
            type: 'file',
            ftype: 'File',
            fileName: 'head injuty victim.jpg',
            origin: 'clio',
            parentFolder: 'Simple_00002_Mayerlen_0idS9H7ARq6X4unT6rG1_775',
            ftime: '18:47:25 GMT+0000 (Coordinated Universal Time)',
            belongsTo: '00002_Mayerlen',
            scanAnalysisId: '',
            creator: '',
            notes: [],
            lastModified: '2025-02-26T18:47:25.908Z',
            fdate: '2025-02-26T18:47:25.908Z',
            fileDesc: '',
            viewerurl: '',
            clioDocId: 18044434692,
          },
          {
            scanAnalysisId: '',
            parentFolderName: 'Complex',
            type: 'file',
            uploadedDate: '2025-02-26T17:36:39.254Z',
            creator: '',
            belongsTo: '00002_Mayerlen',
            notes: [],
            fdate: '2025-02-26T17:36:39.254Z',
            origin: 'clio',
            fileId: '18044434842_00002_Mayerlen_639',
            storename: '',
            viewerurl: '',
            forDelete: false,
            filePath: 'patient/00002_Mayerlen/Clio/Complex/farmacia.jpg',
            fileDesc: '',
            ftime: '17:36:39 GMT+0000 (Coordinated Universal Time)',
            fileName: 'farmacia.jpg',
            lastModified: '2025-02-26T17:36:39.254Z',
            parentFolder: 'Complex_00002_Mayerlen_0idS9H7ARq6X4unT6rG1_632',
            clioDocId: 18044434842,
            ftype: 'File',
          },
          {
            belongsTo: '00002_Mayerlen',
            filePath: 'patient/00002_Mayerlen/Clio/Complex/master-gastroenterologia-hepatologia-pediatrica1.jpg',
            ftime: '17:36:39 GMT+0000 (Coordinated Universal Time)',
            uploadedDate: '2025-02-26T17:36:39.915Z',
            parentFolderName: 'Complex',
            scanAnalysisId: '',
            fileDesc: '',
            fileId: '18044434857_00002_Mayerlen_635',
            lastModified: '2025-02-26T17:36:39.915Z',
            notes: [],
            creator: '',
            origin: 'clio',
            forDelete: false,
            fdate: '2025-02-26T17:36:39.915Z',
            clioDocId: 18044434857,
            viewerurl: '',
            storename: '',
            type: 'file',
            ftype: 'File',
            fileName: 'master-gastroenterologia-hepatologia-pediatrica1.jpg',
            parentFolder: 'Complex_00002_Mayerlen_0idS9H7ARq6X4unT6rG1_632',
          },
          {
            parentFolder: '4964635219',
            scanAnalysisId: '',
            uploadedDate: '2025-02-26T11:38:20.536Z',
            creator: '',
            notes: [],
            fdate: '2025-02-26T11:38:20.536Z',
            clioDocId: 8011677951,
            forDelete: false,
            parentFolderName: 'Clio',
            fileId: '8011677951_00002_Mayerlen_147',
            filePath: 'patient/00002_Mayerlen/Clio/pelota.png',
            ftype: 'File',
            ftime: '11:38:20 GMT+0000 (Coordinated Universal Time)',
            belongsTo: '00002_Mayerlen',
            lastModified: '2025-02-26T11:38:20.536Z',
            origin: 'clio',
            fileName: 'pelota.png',
            viewerurl: '',
            type: 'file',
            fileDesc: '',
            storename: '',
          },
          {
            ftime: '12:03:49 GMT+0000 (Coordinated Universal Time)',
            notes: [],
            clioDocId: 8011677951,
            filePath: 'patient/00002_Mayerlen/Clio/pelota.png',
            uploadedDate: '2025-02-26T12:03:49.367Z',
            forDelete: false,
            type: 'file',
            lastModified: '2025-02-26T12:03:49.367Z',
            origin: 'clio',
            ftype: 'File',
            belongsTo: '00002_Mayerlen',
            scanAnalysisId: '',
            creator: '',
            parentFolder: '4964635219',
            parentFolderName: 'Clio',
            fileDesc: '',
            fileId: '8011677951_00002_Mayerlen_242',
            viewerurl: '',
            fdate: '2025-02-26T12:03:49.366Z',
            storename: '',
            fileName: 'pelota.png',
          },
          {
            notes: [],
            forDelete: false,
            parentFolder: '4964635219',
            uploadedDate: '2025-02-26T11:56:14.356Z',
            filePath: 'patient/00002_Mayerlen/Clio/pelota.png',
            fileDesc: '',
            fileName: 'pelota.png',
            fdate: '2025-02-26T11:56:14.356Z',
            ftime: '11:56:14 GMT+0000 (Coordinated Universal Time)',
            belongsTo: '00002_Mayerlen',
            clioDocId: 8011677951,
            type: 'file',
            viewerurl: '',
            origin: 'clio',
            parentFolderName: 'Clio',
            fileId: '8011677951_00002_Mayerlen_265',
            ftype: 'File',
            storename: '',
            creator: '',
            scanAnalysisId: '',
            lastModified: '2025-02-26T11:56:14.356Z',
          },
          {
            parentFolderName: 'Clio',
            belongsTo: '00002_Mayerlen',
            type: 'file',
            fileId: '8011677951_00002_Mayerlen_487',
            storename: '',
            fileName: 'pelota.png',
            viewerurl: '',
            ftime: '11:59:35 GMT+0000 (Coordinated Universal Time)',
            parentFolder: '4964635219',
            forDelete: false,
            ftype: 'File',
            fileDesc: '',
            clioDocId: 8011677951,
            filePath: 'patient/00002_Mayerlen/Clio/pelota.png',
            uploadedDate: '2025-02-26T11:59:35.067Z',
            origin: 'clio',
            lastModified: '2025-02-26T11:59:35.067Z',
            fdate: '2025-02-26T11:59:35.066Z',
            notes: [],
            creator: '',
            scanAnalysisId: '',
          },
          {
            forDelete: false,
            notes: [],
            storename: '',
            creator: '',
            parentFolderName: 'Clio',
            belongsTo: '00002_Mayerlen',
            type: 'file',
            uploadedDate: '2025-02-26T11:42:45.885Z',
            scanAnalysisId: '',
            lastModified: '2025-02-26T11:42:45.885Z',
            ftime: '11:42:45 GMT+0000 (Coordinated Universal Time)',
            parentFolder: '4964635219',
            filePath: 'patient/00002_Mayerlen/Clio/pelota.png',
            fileName: 'pelota.png',
            ftype: 'File',
            fileDesc: '',
            origin: 'clio',
            clioDocId: 8011677951,
            fdate: '2025-02-26T11:42:45.885Z',
            fileId: '8011677951_00002_Mayerlen_767',
            viewerurl: '',
          },
          {
            notes: [],
            uploadedDate: '2025-02-26T17:25:49.537Z',
            ftype: 'File',
            clioDocId: 8011677951,
            origin: 'clio',
            creator: '',
            storename: '',
            lastModified: '2025-02-26T17:25:49.537Z',
            fileName: 'pelota.png',
            parentFolder: '4964635219',
            parentFolderName: 'Clio',
            ftime: '17:25:49 GMT+0000 (Coordinated Universal Time)',
            filePath: 'patient/00002_Mayerlen/Clio/pelota.png',
            fdate: '2025-02-26T17:25:49.537Z',
            fileId: '8011677951_00002_Mayerlen_926',
            type: 'file',
            viewerurl: '',
            scanAnalysisId: '',
            forDelete: false,
            fileDesc: '',
            belongsTo: '00002_Mayerlen',
          },
          {
            fileId: '8011677951_00002_Mayerlen_93',
            creator: '',
            forDelete: false,
            scanAnalysisId: '',
            clioDocId: 8011677951,
            ftype: 'File',
            notes: [],
            viewerurl: '',
            type: 'file',
            fileName: 'pelota.png',
            uploadedDate: '2025-02-26T11:42:01.779Z',
            origin: 'clio',
            lastModified: '2025-02-26T11:42:01.779Z',
            fileDesc: '',
            ftime: '11:42:01 GMT+0000 (Coordinated Universal Time)',
            storename: '',
            parentFolderName: 'Clio',
            parentFolder: '4964635219',
            filePath: 'patient/00002_Mayerlen/Clio/pelota.png',
            fdate: '2025-02-26T11:42:01.779Z',
            belongsTo: '00002_Mayerlen',
          },
          {
            origin: 'clio',
            fileDesc: '',
            clioDocId: 8332292691,
            viewerurl: '',
            uploadedDate: '2025-02-27T19:23:51.779Z',
            filePath: 'patient/00002_Mayerlen/Clio/request_result_example.png',
            fileName: 'request_result_example.png',
            type: 'file',
            fdate: '2025-02-27T19:23:51.779Z',
            belongsTo: '00002_Mayerlen',
            ftime: '19:23:51 GMT+0000 (Coordinated Universal Time)',
            fileId: '8332292691_00002_Mayerlen_582',
            storename: '',
            ftype: 'File',
            scanAnalysisId: '',
            forDelete: false,
            parentFolderName: 'Clio',
            lastModified: '2025-02-27T19:23:51.779Z',
            notes: [],
            parentFolder: '4964635219',
            creator: '',
          },
          {
            scanAnalysisId: '',
            lastModified: '2025-02-27T19:24:34.342Z',
            belongsTo: '00002_Mayerlen',
            creator: '',
            forDelete: false,
            viewerurl: '',
            fdate: '2025-02-27T19:24:34.342Z',
            ftime: '19:24:34 GMT+0000 (Coordinated Universal Time)',
            notes: [],
            clioDocId: 8762600539,
            parentFolder: '4964635219',
            filePath: 'patient/00002_Mayerlen/Clio/Colorlol.jpg',
            storename: '',
            uploadedDate: '2025-02-27T19:24:34.342Z',
            fileDesc: '',
            fileName: 'Colorlol.jpg',
            type: 'file',
            fileId: '8762600539_00002_Mayerlen_507',
            ftype: 'File',
            parentFolderName: 'Clio',
            origin: 'clio',
          },
        ],
        matterData: {
          name: 'David Mayerlen',
          origin: 'clio',
          clioDocId: 1350664924,
          defaultFolders: false,
          lpmFields: {
            matter: {
              id: 1350664924,
              number: 2,
              display_number: '00002_Mayerlen',
              description: 'Test matter description',
            },
            client: {
              name: 'David Mayerlen',
              type: 'Person',
              initials: 'DM',
              first_name: 'David',
              last_name: 'Mayerlen',
            },
          },
          caseName: '00002_Mayerlen',
          description: 'Test matter description',
          type: 'Person',
          initials: 'DM',
          FirstName: 'David',
          LastName: 'Mayerlen',
          createdAt: {
            _delegate: {
              _methodName: 'FieldValue.serverTimestamp',
            },
          },
          updatedAt: {
            _delegate: {
              _methodName: 'FieldValue.serverTimestamp',
            },
          },
          uidOfCreator: 'RjBCjO4x7iQnV11kTa5j7JgXEs13',
          ownerID: 'RjBCjO4x7iQnV11kTa5j7JgXEs13',
          forDelete: false,
          clioData: {
            client: {
              id: 1577594974,
              name: 'David Mayerlen',
              first_name: 'David',
              last_name: 'Mayerlen',
              type: 'Person',
              title: null,
              primary_email_address: null,
              primary_phone_number: null,
              initials: 'DM',
              middle_name: null,
              clio_connect_email: null,
            },
            id: 1350664924,
            number: 2,
            display_number: '00002-Mayerlen',
            description: 'Test matter description',
          },
          clioFolderId: '0idS9H7ARq6X4unT6rG1',
        },
        options: {
          target: 'open_clio_matter',
          clioObjectId: '1350664924',
          avoidFilesCompare: false,
          targetFolder: 'Clio',
          clioFolderId: '0idS9H7ARq6X4unT6rG1',
          parentFolderId: '0idS9H7ARq6X4unT6rG1',
          parentFolderName: 'Clio',
          caseName: '00002_Mayerlen',
          matterData: {
            name: 'David Mayerlen',
            origin: 'clio',
            clioDocId: 1350664924,
            defaultFolders: false,
            lpmFields: {
              matter: {
                id: 1350664924,
                number: 2,
                display_number: '00002_Mayerlen',
                description: 'Test matter description',
              },
              client: {
                name: 'David Mayerlen',
                type: 'Person',
                initials: 'DM',
                first_name: 'David',
                last_name: 'Mayerlen',
              },
            },
            caseName: '00002_Mayerlen',
            description: 'Test matter description',
            type: 'Person',
            initials: 'DM',
            FirstName: 'David',
            LastName: 'Mayerlen',
            createdAt: {
              _delegate: {
                _methodName: 'FieldValue.serverTimestamp',
              },
            },
            updatedAt: {
              _delegate: {
                _methodName: 'FieldValue.serverTimestamp',
              },
            },
            uidOfCreator: 'RjBCjO4x7iQnV11kTa5j7JgXEs13',
            ownerID: 'RjBCjO4x7iQnV11kTa5j7JgXEs13',
            forDelete: false,
            clioData: {
              client: {
                id: 1577594974,
                name: 'David Mayerlen',
                first_name: 'David',
                last_name: 'Mayerlen',
                type: 'Person',
                title: null,
                primary_email_address: null,
                primary_phone_number: null,
                initials: 'DM',
                middle_name: null,
                clio_connect_email: null,
              },
              id: 1350664924,
              number: 2,
              display_number: '00002-Mayerlen',
              description: 'Test matter description',
            },
            clioFolderId: '0idS9H7ARq6X4unT6rG1',
          },
        },
        userEmail: 'alexisrengifo+us.owner.19@gmail.com',
        matterContent: [
          {
            id: 8011674066,
            name: 'XRAY-N0293842.jpg',
            type: 'Document',
            parent: {
              id: 4964635219,
              name: '00002-Mayerlen',
              root: false,
            },
          },
          {
            id: 8011677951,
            name: 'pelota.png',
            type: 'Document',
            parent: {
              id: 4964635219,
              name: '00002-Mayerlen',
              root: false,
            },
          },
          {
            id: 8308834641,
            name: '4596381001.pdf',
            type: 'Document',
            parent: {
              id: 4964635219,
              name: '00002-Mayerlen',
              root: false,
            },
          },
          {
            id: 8332292691,
            name: 'request_result_example.png',
            type: 'Document',
            parent: {
              id: 4964635219,
              name: '00002-Mayerlen',
              root: false,
            },
          },
          {
            id: 8595145370,
            name: 'insect.jpg',
            type: 'Document',
            parent: {
              id: 4964635219,
              name: '00002-Mayerlen',
              root: false,
            },
          },
          {
            id: 8748552020,
            name: 'broken-wrist.jpg',
            type: 'Document',
            parent: {
              id: 4964635219,
              name: '00002-Mayerlen',
              root: false,
            },
          },
          {
            id: 8762600539,
            name: 'Colorlol.jpg',
            type: 'Document',
            parent: {
              id: 4964635219,
              name: '00002-Mayerlen',
              root: false,
            },
          },
          {
            id: 8920386169,
            name: 'medical-test.jpg',
            type: 'Document',
            parent: {
              id: 4964635219,
              name: '00002-Mayerlen',
              root: false,
            },
          },
          {
            id: 17680293717,
            name: 'TestFolder',
            type: 'Folder',
            parent: {
              id: 4964635219,
              name: '00002-Mayerlen',
              root: false,
            },
          },
          {
            id: 17991637032,
            name: 'Images',
            type: 'Folder',
            parent: {
              id: 4964635219,
              name: '00002-Mayerlen',
              root: false,
            },
          },
          {
            id: 18033269517,
            name: 'Simple',
            type: 'Folder',
            parent: {
              id: 4964635219,
              name: '00002-Mayerlen',
              root: false,
            },
          },
          {
            id: 18044434812,
            name: 'Complex',
            type: 'Folder',
            parent: {
              id: 4964635219,
              name: '00002-Mayerlen',
              root: false,
            },
          },
        ],
      },
    });
  }

  async clearDuplicatedFoldersByCaseName(foldername: string, caseName: string) {
    console.log('clearDuplicatedFoldersByCaseName: ');
    this.auth_$.showLoader('Clearing duplicated folders...');
    const folders = await this.getFoldersDataByCaseName(foldername, caseName);
    console.log('folders: ', folders);

    // Group folders by belongsTo
    const foldersByBelongsTo = {};
    for (const folder of folders) {
      const belongsTo = folder['belongsTo'];
      if (!foldersByBelongsTo[belongsTo]) {
        foldersByBelongsTo[belongsTo] = [];
      }
      foldersByBelongsTo[belongsTo].push(folder);
    }

    // Process each group
    for (const belongsTo of Object.keys(foldersByBelongsTo)) {
      const folderGroup = foldersByBelongsTo[belongsTo];

      // Check if all folders in this group are empty
      const emptyStatuses = await Promise.all(folderGroup.map(folder => this.isEmptyFolder(folder['folderId'])));

      const allEmpty = emptyStatuses.every(isEmpty => isEmpty);

      if (allEmpty && folderGroup.length > 1) {
        // Keep the first one, delete the rest
        for (let i = 1; i < folderGroup.length; i++) {
          await firebase
            .firestore()
            .collection('files')
            .doc(folderGroup[i]['id'])
            .delete()
            .then(() => {
              console.log('deleted (kept one):', folderGroup[i]['id']);
            });
        }
      } else {
        // Delete only empty folders (but leave at least one if all are empty)
        for (let i = 0; i < folderGroup.length; i++) {
          if (emptyStatuses[i] && (allEmpty ? i > 0 : true)) {
            await firebase
              .firestore()
              .collection('files')
              .doc(folderGroup[i]['id'])
              .delete()
              .then(() => {
                console.log('deleted:', folderGroup[i]['id']);
              });
          }
        }
      }
    }

    this.auth_$.hideLoader();
  }

  async listOwners() {
    this.ownersList = await firebase
      .functions()
      .httpsCallable('admin-listOwners')()
      .then(({ data }) => {
        console.log('data :', data);
        return data;
      });
  }

  private async deleteEmpties(foldersToDelete: any[]) {
    console.log('foldersToDelete :', foldersToDelete);
    for (const folder of foldersToDelete) {
      await firebase.firestore().collection('files').doc(folder.id).update({ forDelete: true });
    }
  }

  private async deleteAllButOneEmpty(folders: any[]) {
    console.log('deleteAllButOneEmpty:', folders);
    const folderChoosen = folders[0];
    for (const folder of folders) {
      if (folder.id !== folderChoosen.id) {
        await firebase.firestore().collection('files').doc(folder.id).update({ forDelete: true });
      }
    }
  }

  private async relocateFiles(folders: any[]) {
    const choosenFolder = folders[0];
    for (const folder of folders) {
      if (folder.id !== choosenFolder.id) {
        const files = await firebase.firestore().collection('files').where('parentFolder', '==', folder.id).get();
        for (const file of files.docs) {
          console.log('file: ', file.data());
          await firebase.firestore().collection('files').doc(file.id).update({ parentFolder: choosenFolder.id });
        }
        await firebase.firestore().collection('files').doc(folder.id).update({ forDelete: true });
      }
    }
  }

  async removeSharingReferencesOfFilesDeletedInUsers() {
    const users = (await firebase.firestore().collection('users').get()).docs
      .map(doc => ({
        filesSharedWith: doc.data().filesSharedWith ? doc.data().filesSharedWith.map(j => j.fileId) : [],
        id: doc.id,
        email: doc.data().email,
      }))
      .filter(u => u.filesSharedWith && u.filesSharedWith.length > 0);

    console.log('users :', users);

    for (const user of users) {
      for (const fileId of user.filesSharedWith) {
        const fileDeleted = await firebase
          .firestore()
          .collection('files')
          .where('fileId', '==', fileId)
          .where('forDelete', '==', true)
          .get();
        if (fileDeleted.docs.length > 0) {
          const sharedUsers = fileDeleted.docs[0].data().sharedUsers;
          if (sharedUsers && sharedUsers.length > 0) {
            const newSharedUsers = sharedUsers.filter(f => f.email !== user.email);

            await firebase
              .firestore()
              .collection('files')
              .doc(fileDeleted.docs[0].id)
              .update({ sharedUsers: newSharedUsers })
              .then(() => {
                console.log('sharedUsers email: ', user.email);
                console.log('sharedUsers deleted: ', fileId);
              });

            const filesSharedWith = await firebase
              .firestore()
              .collection('users')
              .where('email', '==', user.email)
              .limit(1)
              .get();

            const newFilesSharedWith = filesSharedWith.docs[0]
              .data()
              ['filesSharedWith'].filter(f => f.fileId !== fileId);

            await firebase
              .firestore()
              .collection('users')
              .doc(filesSharedWith.docs[0].id)
              .update({ filesSharedWith: newFilesSharedWith })
              .then(() => {
                console.log('sharedUsers deleted: ', fileId);
                console.log('User affected', user.email);
              });
          }
        }
      }
    }
  }

  private async isEmptyFolder(folderId: string): Promise<boolean> {
    const files = await firebase.firestore().collection('files').where('parentFolder', '==', folderId).get();
    if (files.docs.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  async getFoldersData(foldername: string) {
    return (await firebase.firestore().collection('files').where('name', '==', foldername).get()).docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));
  }

  async getFoldersDataByCaseName(foldername: string, caseName: string) {
    return (
      await firebase
        .firestore()
        .collection('files')
        .where('name', '==', foldername)
        .where('belongsTo', '==', caseName)
        .where('type', '==', 'folder')
        .get()
    ).docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));
  }

  removeSharingReferencesOfFilesDeleted() {
    firebase
      .firestore()
      .collection('files')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if (doc.data().sharedUsers && doc.data().sharedUsers.length > 0 && doc.data().forDelete === true) {
            doc.ref.update({ sharedUsers: firebase.firestore.FieldValue.delete() }).then(() => {
              console.log('sharedUsers deleted: ', doc.data().fileId);
            });
          }
        });
      });
  }

  usersFilesSharingFixing() {
    const unexistentFiles = [];
    firebase
      .firestore()
      .collection('users')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const filesSharedWith = doc.data().filesSharedWith;

          if (filesSharedWith && filesSharedWith.length > 0) {
            // const newFilesSharedWith = filesSharedWith.map(fsw => ({ ...fsw, email: fsw.email.toLowerCase() }));
            // firebase.firestore().collection('users').doc(doc.id).update({ filesSharedWith: newFilesSharedWith });
            filesSharedWith.forEach(fsw => {
              firebase
                .firestore()
                .collection('files')
                .where('fileId', '==', fsw.fileId)
                .limit(1)
                .get()
                .then(fileDoc => {
                  if (fileDoc.docs[0]) {
                    console.log('fileDoc[0] :', fileDoc.docs[0].data());
                    const sharedUsers = fileDoc.docs[0].data().sharedUsers;

                    if (!sharedUsers || sharedUsers.length === 0) {
                      firebase
                        .firestore()
                        .collection('files')
                        .doc(fileDoc.docs[0].id)
                        .update({ sharedUsers: [{ email: doc.data().email, name: doc.data().name }] })
                        .then(() => {
                          console.log('file updated: ', fsw.fileId);
                        });
                    }
                  } else {
                    console.log("file doesn't exist: ", fsw.fileId);
                    unexistentFiles.push(fsw.fileId);

                    const newFilesSharedWith = filesSharedWith.filter(fsw => fsw.fileId !== fsw.fileId);

                    firebase
                      .firestore()
                      .collection('users')
                      .doc(doc.id)
                      .update({ filesSharedWith: newFilesSharedWith })
                      .then(() => {
                        console.log('file removed from user: ' + doc.data()['uid'], fsw.fileId);
                      });
                  }
                });
            });
          }
        });
      });
  }

  showUserInfo(email: string) {
    firebase
      .firestore()
      .collection('users')
      .where('email', '==', email)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log(
            'doc :',
            doc.data().filesSharedWith.filter(r => r.patientCaseName === 'FlatzvUnionMedical'),
          );
        });
      });
  }

  updateFilesSharedUsers() {
    firebase
      .firestore()
      .collection('files')
      .where('ftype', '==', 'File')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const sharedUsers = doc.data().sharedUsers;
          // console.log('sharedUsers :', sharedUsers);
          if (sharedUsers && sharedUsers.length > 0) {
            let newSharedUsers;
            if (sharedUsers[0] === true) {
              newSharedUsers = [];
            } else {
              newSharedUsers = sharedUsers.map(item => ({ email: item.email.toLowerCase(), name: item.name }));
            }
            // console.log('newSharedUsers :', newSharedUsers);
            firebase.firestore().collection('files').doc(doc.id).update({ sharedUsers: newSharedUsers });
          }
        });
      });
  }

  showSpecificFile(fileDesc: string) {
    firebase
      .firestore()
      .collection('files')
      .where('fileDesc', '==', fileDesc)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log('doc :', doc.data().sharedUsers);
          // if (doc.data().sharedUsers) {
          //   const newSharedUsers = doc.data().sharedUsers.map(su => ({ email: su.email.toLowerCase(), name: su.name }));
          //   firebase.firestore().collection('files').doc(doc.id).update({ sharedUsers: newSharedUsers });
          // }
        });
      });
  }

  allUsersEmailToLowerCase() {
    firebase
      .firestore()
      .collection('users')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          firebase.firestore().collection('users').doc(doc.id).update({ email: doc.data().email.toLowerCase() });
        });
      });
  }

  userEmailsToLowerCase(): void {
    firebase
      .firestore()
      .collection('users')
      .get()
      .then(querySnapshot =>
        querySnapshot.forEach(doc =>
          firebase.firestore().collection('users').doc(doc.id).update({
            email: doc.data().email.toLowerCase(),
          }),
        ),
      );
  }

  updateFolderColors() {
    firebase
      .firestore()
      .collection('files')
      .where('type', '==', 'folder')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const folderName = doc.data().name;
          console.log('folderName :', folderName);
          switch (folderName) {
            case 'Damages':
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#ffc1c1' });
              break;
            case 'Discovery':
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#ffda81' });
              break;
            case 'Liability':
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#d1f3c3' });
              break;
            case 'Client Upload':
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#ccff00' });
              break;
            case 'Other':
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#e7e7e7' });
              break;
            default:
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#c5f0f6' });
              break;
          }
        });
      })
      .catch(err => console.error(err));
  }

  analyzeViewerURL(viewerurl: string): boolean {
    const i = viewerurl.indexOf('https://');
    const newstring = viewerurl.substring(i + 8);
    const stringSplitted = newstring.split('/');
    const part1 = stringSplitted[5];
    const part2 = stringSplitted[7];
    // console.log({ part1, part2 });
    return part1 === part2;
  }

  hasHTTPSprefix(viewerurl: string): boolean {
    return viewerurl.indexOf('https://') > -1;
  }

  listViewerURLs() {
    firebase
      .firestore()
      .collection('files')
      .where('fileName', '==', dicomdirFileName)
      .get()
      .then(querySnapshot => {
        let counter = 0;
        const data = [];
        querySnapshot.forEach(doc => {
          const viewerurl = doc.data().viewerurl;
          if (this.analyzeViewerURL(viewerurl)) {
            data.push({ data: doc.data(), id: doc.id });
          }
        });
        const belongsTo = data
          .map(item => item.data.belongsTo)
          .filter((item, index, array) => array.indexOf(item) === index);
        belongsTo.forEach(item => {
          this.gAPI_$.checkIfDataSetExists(item).then(res => {
            // console.log('Dataset exists?: ', res);
            if (!res) {
              counter++;
              console.log('Dataset does not exist, lets delete the file');
              console.log(`${data['id']}`);
              // firebase.firestore().collection('files').doc(data['id']).update({ forDelete: true });
            }
          });
        });
        console.log(`${counter} rows has been affected.`);
      })
      .catch(err => console.error(err));
  }

  seeAllUsersEmails(): void {
    firebase
      .firestore()
      .collection('users')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log(doc.data().email);
        });
      });
  }

  listSuperUsers() {
    firebase
      .firestore()
      .collection('users')
      .where('role', '==', 'Super User')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log(doc.data().email);
        });
      });
  }

  cleanNotificationsOfDeletedFiles() {
    firebase
      .firestore()
      .collection('notifications')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          firebase
            .firestore()
            .collection('files')
            .where('fileId', '==', doc.data().fileId)
            .get()
            .then(querySnapshot => {
              if (querySnapshot.size === 0) {
                // console.log(' does not exist > doc :', doc.data());
                doc.ref.delete();
              } else if (querySnapshot.docs[0].data().forDelete === true) {
                // console.log(' is deleted > doc :', doc.data());
                doc.ref.delete();
              }
            });
        });
      });
  }

  getStorageSizeByCaseName(caseName: string) {
    console.log('caseName :', caseName);
    firebase
      .functions()
      .httpsCallable('admin-getStorageSizeByCaseName')({ caseName: caseName })
      .then(res => {
        console.log('res :', res);
      })
      .catch(err => console.error(err));
  }

  getHealthcareSizeByCaseName(caseName: string) {
    console.log('caseName :', caseName);
    firebase
      .functions()
      .httpsCallable('admin-getHealthcareSizeByCaseName')({ caseName: caseName })
      .then(res => {
        console.log('res :', res);
      })
      .catch(err => console.error(err));
  }
}
