<div fxLayout="row"
     fxLayoutAlign="start center"
     [ngClass]="'mat-body'">
  <!-- Home button -->
  <div *ngIf="currentFolder === ''"
       class="breadcrumb-home-button no-link">
    <mat-icon>
      home
    </mat-icon>
    <span>
      &nbsp;Home
    </span>
  </div>

  <ng-container *ngIf="currentFolder !== ''">
    <button class="breadcrumb-home-button"
            aria-label="Back to home"
            mat-button
            (click)="handleGo('')">
      <mat-icon>
        home
      </mat-icon>
      &nbsp;
      <span class="mat-body">
        Home
      </span>
    </button>

    <!-- Path segments -->
    <ng-container *ngFor="let segment of pathSegments; let last = last; let first = first">
      <mat-icon>
        keyboard_arrow_right
      </mat-icon>

      <ng-container *ngIf="!last">
        <button class="breadcrumb-segment-button"
                mat-button
                (click)="handleGo(segment.id)">
          <span class="folder-name"
                [ngStyle]="getButtonStyle(segment.name)">
            {{ segment.label || segment.name }}
          </span>
        </button>
      </ng-container>

      <ng-container *ngIf="last">
        <span class="folder-name"
              [ngStyle]="getButtonStyle(segment.name)">
          {{ segment.label || segment.name }}
        </span>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
