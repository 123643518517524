import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MattersService } from 'src/app/services/matters.service';
import { environment } from 'src/environments/environment';

interface FileNode {
  id: string;
  name: string;
  type: 'file' | 'folder';
  parentFolder: string | null;
}
@Component({
  selector: 'app-files-comparison',
  styleUrls: ['./files-comparison.component.scss'],
  templateUrl: './files-comparison.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FilesComparisonComponent {
  authorizedUsers = environment.config.betaTesters;
  isAuthorized: boolean;
  userData: any;
  sourceDocuments: any[];
  localDocuments: any[];
  matterData = [];
  @ViewChild('list') list;
  @ViewChild('fileExplorer') fileExplorer: any; // Add this line to access FileExplorer component
  mockData2 = [];
  transformedFileData: FileNode[];
  userEmail: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matters_$: MattersService,
    private dialogRef: MatDialogRef<FilesComparisonComponent>,
  ) {
    this.userEmail = this.data.userEmail;
    this.isAuthorized = this.authorizedUsers.includes(this.data.userEmail);
    this.mockData2 = this.data.matterContent;
    this.initializeMatterData();
    this.initializeSourceDocuments();
    this.markExistingDocuments();
    this.closeIfAllDocumentsExist();
    this.localDocuments = data.localDocuments;
    this.transformedFileData = this.transformMockData2ToFileNodes(this.mockData2);
  }

  async getFilesAndFoldersFromFolderId(folderId: string) {
    const answer = await this.matters_$.getFilesAndFolderFromFolderId(folderId);
    console.log('data: ', answer.data);
    if (answer.data.code === 200) {
      const newChildren = answer.data.data.data;

      // Remove existing children of this folder
      this.transformedFileData = this.transformedFileData.filter(node => node.parentFolder !== folderId);

      // Transform and add new children
      const transformedChildren = newChildren.map(item => ({
        id: item.id.toString(),
        name: item.name,
        type: item.type === 'Document' ? 'file' : 'folder',
        parentFolder: folderId,
      }));

      // Add new children to the array
      this.transformedFileData = [...this.transformedFileData, ...transformedChildren];

      return transformedChildren;
    } else {
      return [];
    }
  }

  private transformMockData2ToFileNodes(data: any[]): FileNode[] {
    const nodes: FileNode[] = [];
    const parentFolderId = '4964635219';

    // Add parent folder first
    nodes.push({
      id: parentFolderId,
      name: '00002-Mayerlen',
      type: 'folder',
      parentFolder: null, // This is the root folder
    });

    // Add all files and subfolders
    data.forEach(item => {
      nodes.push({
        id: item.id.toString(),
        name: item.name,
        type: item.type === 'Document' ? 'file' : 'folder',
        parentFolder: parentFolderId, // All items are children of the parent folder
      });
    });

    return nodes;
  }

  private initializeMatterData(): void {
    this.matterData.push({
      label: 'Matter',
      value: this.data.matterData.lpmFields.matter.display_number,
    });
  }

  private initializeSourceDocuments(): void {
    const { sourceDocuments, options } = this.data;
    this.sourceDocuments = options.clioDocumentId
      ? sourceDocuments.filter(doc => doc.id === parseInt(options.clioDocumentId, 10))
      : sourceDocuments;
  }

  private markExistingDocuments(): void {
    this.sourceDocuments.forEach(file => {
      file.existing = this.data.localDocuments.some(localFile => localFile.clioDocId === file.id);
    });
  }

  private closeIfAllDocumentsExist(): void {
    const allExist = this.sourceDocuments.every(file =>
      this.data.localDocuments.some(localFile => localFile.clioDocId === file.id),
    );

    if (allExist) {
      console.log('All sourceDocuments are existing. Closing the dialog.');
      this.dialogRef.close();
    }
  }

  continueWithoutAction() {
    this.dialogRef.close();
  }

  handleImportSelected() {
    const selected = this.list.selectedOptions.selected.map((item: any) => {
      const { id, name } = this.sourceDocuments.find(file => file.id === item.value);
      return { id, name };
    });
    this.dialogRef.close(selected);
  }

  handleImportSelectedNew() {
    const selectedNodes = this.fileExplorer.selection.selected;
    const selected = selectedNodes.map(node => ({
      id: parseInt(node.id, 10), // Convert string id back to number
      name: node.name,
      type: node.type, // Include the type to distinguish between files and folders
      parentFolder: node.parentFolder,
    }));

    this.dialogRef.close({ selected, new: true });
  }

  handleImportAll() {
    this.list.selectAll();
    this.handleImportSelected();
  }
}
