<app-window-title subtitle="If your client/matter isn't listed, go to Matters in Clio and click 'Share to NuageDx' to share it with you."
                  [closable]="true"
                  [title]="'Client/Matter List'"></app-window-title>
<div fxLayout="column"
     mat-dialog-content>
  <!-- SEARCH FOR CLIENT/MATTER -->
  <div fxLayout="row"
       fxLayoutAlign="center center">
    <mat-form-field color="warn"
                    appearance="fill"
                    [ngClass]="'width-90p'">
      <mat-label>
        Search Client/Matter
      </mat-label>
      <input #searchClientsInput

             color="warn"
             name="searchClientsInput"
             placeholder="by name, Case name, Case ID"

             aria-label="Label"
             cdkFocusInitial
             matInput
             [value]="caseName"

             (input)="caseName = searchClientsInput.value"
             (keyup)="search($event)">
    </mat-form-field>
    <div class="mdc-notched-outline">
      <div class="mdc-notched-outline__leading"></div>
      <div class="mdc-notched-outline__trailing"></div>
    </div>
    <button color="warn"
            mat-icon-button
            matTooltip="Click to search."
            (click)="search($event)">
      <mat-icon>
        search
      </mat-icon>
    </button>
    <button color="warn"
            mat-icon-button
            matTooltip="Click to refresh."
            (click)="getFilesSharedWith()">
      <mat-icon>
        update
      </mat-icon>
    </button>
  </div>
  <i *ngIf="!ownerNeeded"
     class="criteria-message mat-small">
    You can search by: 'FirstName', 'LastName', 'caseName', 'LegalCaseId' and 'DateOfBirth'
  </i>
  <div *ngIf="!ownerNeeded"
       fxLayoutAlign="center center">
    <p *ngIf="!searchResult?.length">
      No clients/matters found
    </p>
  </div>
  <div *ngIf="searchResult?.length > 0"
       fxLayout="column"
       fxLayoutAlign="center center">
    <h4 *ngIf="searchResult.length > 1"
        id="profileFont"
        [ngStyle]="{ margin: '0' }">
      {{ searchResult.length }} Clients/Matters
    </h4>
    <h4 *ngIf="searchResult.length === 1"
        id="profileFont">
      1 Client/Matter
    </h4>
    <mat-list id="listStyle"
              [ngClass]="'client-list-container'">
      <mat-list-item *ngFor="let item of searchResult"
                     ngClass="client-list-item"
                     (click)="dialogdata ? handleSelectClient(item) : routeToProfile(item)">
        <app-client-list-item [client]="item"
                              [hasNotifications]="item.hasNotifications"
                              [permissions]="{ dialogdata, ableToEditClient, ableToDeleteClient }"
                              (deleteClient)="deleteClient($event)"
                              (editClient)="editClient($event)"></app-client-list-item>
      </mat-list-item>
    </mat-list>
  </div>
</div>
