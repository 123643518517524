<ul [ngStyle]="{ padding: 0, margin: 0, 'list-style': 'none' }">
  <li fxflexfill
      fxlayoutgap="0px grid"
      [ngClass]="['custom-folders-container']">
    <button *ngFor="let item of customFolders"

            mat-flat-button
            matTooltip="{{ item.name }}"

            [ngClass]="{
        'selected-folder': item.selected === true,
        'folder-boxed-button': true,
        'boxed-button-Custom': true
      }"
            (click)="select_folder({ folderId: item.folderId, folderName: item.name }); item.selected = true">
      <mat-icon class="material-icons">
        folder
      </mat-icon>
      <span class="folder-name">
        {{item.name}}
      </span>
      <mat-icon *ngIf="item.folderId === selectedFolder"
                [ngClass]="'check-icon'">
        check
      </mat-icon>
    </button>
  </li>
  <li class="create-folder-row">
    <input #foldername

           id="foldernameValue"
           placeholder="Create a folder"
           type="text"

           [ngClass]="'input-text'"
           (keyup)="handleCreateFolder(foldername.value, false, customFoldersColor, $event)">
    <button [ngClass]="'arrow-button'"
            (click)="handleCreateFolder(foldername.value, false, customFoldersColor, $event); foldername.value = ''">
      <mat-icon>
        keyboard_arrow_right
      </mat-icon>
    </button>
    <span *ngIf="validation.value"
          [ngClass]="['mat-body', 'message-box']">
      {{validation.text(foldername.value)}}
    </span>
  </li>
</ul>
