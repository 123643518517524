import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

const CLOUDFUNCTIONS_URL = environment.constants.cloudfunctionsURL;

@Injectable({
  providedIn: 'root',
})
export class MailerService {
  dialogConfig: MatDialogConfig;
  constructor(private http: HttpClient) {}

  notifyAdminByEmail(viewerurl: string, datasetId: string, dicomStoreId: string, studyId: string, useremail: string) {
    const sendEmailUrl = CLOUDFUNCTIONS_URL + `email-sendEmail`;

    const htmlBody = `<p>Study ${studyId} has been deleted.</p>
      <p>Study URL: ${viewerurl}</p>
      <p>Dataset ID: ${datasetId}</p>
      <p>DICOM Store ID: ${dicomStoreId}</p>
      <p>User: ${useremail}</p>`;

    this.http
      .post(sendEmailUrl, { dest: 'arengifo@nuagedx.com', subject: 'Study Deletion attempt', body: htmlBody })
      .toPromise();
  }

  sendClientDeletionEmail(casename:string, useremail: string) {
    const sendEmailUrl = CLOUDFUNCTIONS_URL + `email-sendEmail`;
    const subject = 'Client/Matter Deletion';
    const body = `Client/Matter ${casename} has been temporarily deleted by ${useremail}. It will be permanently removed in 30 days unless you respond to this message requesting to restore it.`;
    return this.http.post(sendEmailUrl, { dest: useremail, subject, body }).toPromise();
  }

  sendEmail(dest: string, subject: string, body: string) {
    const sendEmailUrl = CLOUDFUNCTIONS_URL + `email-sendEmail`;
    return this.http.post(sendEmailUrl, { dest, subject, body }).toPromise();
  }

  sendOperationStatusEmail(messageData, action, status, email) {
    console.log('sendOperationStatusEmail');
    const url = CLOUDFUNCTIONS_URL + 'email-simpleEmail';
    const dest = [email].join(',');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'secret-key',
      }),
    };

    const body = action + ': ' + status + '\n' + messageData;
    console.log('body :', body);
    const subject = `NuagedX has uploaded a disc.`;

    return this.http.post(url, { dest, body, subject }, httpOptions).subscribe({
      next: res => {
        if (res['data']['status'] === 200) console.log('Email sent');
      },
      complete: () => {
        console.log('Completed');
      },
      error: err => {
        console.error(err);
      },
    });
  }
}
