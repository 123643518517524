import firebase from 'firebase/app';
import 'firebase/firestore';

import packageJson from '../../package.json';
import env from './env';

const site_uris = ['app.nuagedx.com', 'qa.nuagedx.com', 'localhost:4200', 'nuagedx-dev.web.app'];
const site_uri = site_uris[0];
const version = packageJson['prod-version'];
const devVersion = packageJson['dev-version'];
const prodVersion = packageJson['prod-version'];
const projectId = 'nuagedx-dev';
const viewerUrls = ['https://viewer.nuagedx.com/', 'https://viewer-qa.web.app/', 'https://viewer-qa-1.web.app/'];
const viewerIndex = 0;

const localCloudFunctions = false;
const localIPAddress = '127.0.0.1';
const functionsPort = 5001;

let cloudFunctionsUrl = 'https://us-central1-nuagedx-dev.cloudfunctions.net/';

if (localCloudFunctions) {
  cloudFunctionsUrl = `http://${localIPAddress}:${functionsPort}/nuagedx-dev/us-central1/`;
}

export const environment = {
  production: false,
  maintenance: false,
  config: {
    betaTesters: ['alexisrengifo+us.owner.19@gmail.com', 'dmayerlen@nuagedx.com'],
    emailAddress: 'alexisrengifo@gmail.com',
    site_uri: site_uri,
    raygun: false,
    sessionTimeLimit: 30 * 60 * 1000, // 30 minutes.
    getNotificationsFrequency: 5 * 60 * 1000, //  20 seconds.
    notifications: 0,
    sysadmin: false,
    databaseURL: 'https://nuagedx-dev.firebaseio.com',
    healthcareApi: 'https://us-central1-nuagedx-dev.cloudfunctions.net/healthcareApi',
    healthcareApiV1: `${cloudFunctionsUrl}healthcareApiV1`,
    healthcareApiV5: `${cloudFunctionsUrl}healthcareApiV5`,
    location: 'us-central1',
    apiKey: 'AIzaSyBPYsY-89MlrB5w3-cQf92-ILGiHF46AZ0',
    authDomain: 'nuagedx-dev.firebaseapp.com',
    projectId: projectId,
    storageBucket: 'nuagedx-dev.appspot.com',
    messagingSenderId: '409596160399',
    appId: '1:409596160399:web:c94b8198a805007e',
    viewerIndex: viewerIndex,
    ohifViewerURL: viewerUrls[viewerIndex],
    host: `https://${site_uri}/`,
    version: version,
    devVersion: devVersion,
    prodVersion: prodVersion,
    algolia: {
      appId: '3RFWKF3793',
      apiKey: '5ff8756359c3dca2ad643a1284d7a26e',
      filesIndex: 'dev_FILES',
    },
    malwarescanner: { endpoint: 'malwarescanner' },
    gapi: {
      clientId: '409596160399-jvos1lrt8q6lqc1u2u9fi2vokn4upe4a.apps.googleusercontent.com',
      healthcareRest: 'https://content.googleapis.com/discovery/v1/apis/healthcare/v1/rest',
      healthcareRest_v1beta1: 'https://www.googleapis.com/discovery/v1/apis/healthcare/v1beta1/rest',
      platformScope: 'https://www.googleapis.com/auth/cloud-platform',
      apiKey: 'AIzaSyCaMW--IxXHWpzH9YsM_gxMOL9tFldan5o',
      location: 'us-central1',
      projectname: projectId,
    },
    clio: {
      client_id: 'rHRqUKxKFh1cQkiPJ9oK4ZCLktkil0V7JJbbWubk',
      client_secret: 'dA8CI7LZndm236U8zPtXO4BPyZEaZYdimv3NhcOB',
      redirect_uri: `https://${site_uri}/clioauth`,
      authorizeURL: 'https://app.clio.com/oauth/authorize',
      authorizeURL_eu: 'https://eu.app.clio.com/oauth/authorize',
      redirectsGroup: {
        meetings: `https://${site_uri}/meetings`,
        updateDefaultCalendar: `https://${site_uri}/clioauth/calendar`,
        updateDefaultCalendar_eu: `https://${site_uri}/clioauth/calendar_eu`,
        clientProfile: `https://${site_uri}/clioauth/client`,
        clientProfile_eu: `https://${site_uri}/clioauth/client_eu`,
      },
      SSO: env.config.clio.SSO,
    },
    clio_eu: env.config.clio_eu,
    clio_us: env.config.clio_us,
    snackBar: {
      duration: 7000,
      verticalPosition: 'top',
      panelClass: 'snackbar-error',
    },
    zoom: {
      clientId: '7AwbYkq9SUK2uqp6RQQfkg',
      clientSecret: 'i2Iu4J8tkhebMtEjJBnY8zr66TPDvZGN',
      redirectURL: `https://${site_uri}/meetings`,
      findLocalNumbersURL: 'https://nuagedx.zoom.us/u/ac0xN4965m',
      invitationOptions: {
        includeDateTime: true,
        host_invitation: false,
      },
    },
    microsoft: {
      oauth: {
        appId: '59617e48-485c-42ca-98ee-914b43b3999c',
        redirectUri: `https://${site_uri}/meetings`,
        scopes: ['user.read', 'calendars.readwrite', 'mailboxsettings.read'],
      },
    },
    email: { targets: { notifications: ['alexisrengifo@gmail.com'] } },
  },
  constants: {
    cloudfunctionsURL: 'https://us-central1-nuagedx-dev.cloudfunctions.net/',
    mailClient: {
      GOOGLE: 'Google',
      OFFICE365: 'Office365',
      OUTLOOK: 'Outlook',
      OTHER: 'Other',
      CLIO: 'Clio',
    },
    zoomDefaults: { DEFAULT_TOPIC: 'Nuagedx meeting' },
  },
  recurly: {
    publicKey: 'ewr1-BXefZtquoUuFjOkqpSHRaU',
  },
};

firebase.initializeApp(environment.config);
