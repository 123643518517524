import { Injectable } from '@angular/core';
import firebase from 'firebase';
import { Folder } from '../models/Folder';
import { AuthService } from './auth.service';

const FOLDER_TYPE = 'folder';
const dbPatients = firebase.firestore().collection('patients');
const dbFiles = firebase.firestore().collection('files');
const defaultFolders = [
  { name: 'Damages', label: '/Damages/', color: '#ffc1c1', type: 'predefined' },
  { name: 'Discovery', label: '/Discovery/', color: '#ffda81', type: 'predefined' },
  { name: 'Liability', label: '/Liability/', color: '#d1f3c3', type: 'predefined' },
  { name: 'Other', label: '/Other/', color: '#e7e7e7', type: 'predefined' },
];

@Injectable({
  providedIn: 'root',
})
export class ClientMatterService {
  constructor(private auth_$: AuthService) {}

  async getPatientData(caseName: string) {
    return (await dbPatients.where('caseName', '==', caseName).where('forDelete', '==', false).get()).docs[0].data();
  }

  markClientMatterForDelete(casename: string) {
    return dbPatients
      .doc(casename)
      .update({
        forDelete: true,
        deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
        deletedBy: firebase.auth().currentUser.uid,
      })
      .then(() => {
        return true;
      })
      .catch(err => {
        console.error('err :', err);
        return false;
      });
  }

  updateClientMatter(casename: string, email: any) {
    const tresults = dbPatients
      .doc(casename)
      .update({ clientUser: email })
      .catch(err => {
        console.error('err :', err);
        return false;
      })
      .then(() => {
        return email;
      });
    console.log('tresults :', tresults);
    return tresults;
  }

  generateDicomDirFile(params: any) {
    console.log('params :', params);
    return firebase.functions().httpsCallable('clio-generateDicomDirFile')({
      ...params,
      userdocid: this.auth_$.userData.getValue()['id'],
    });
  }

  async importClioDocument(clioDocumentId: any, casename: string, userdocid?: string, caseFolderId?: string) {
    const parentFolderId =
      caseFolderId ||
      (
        await dbFiles
          .where('belongsTo', '==', casename)
          .where('type', '==', FOLDER_TYPE)
          .where('name', '==', 'Clio')
          .get()
      ).docs[0].data()['folderId'];
    return firebase.functions().httpsCallable('clio-importClioDocument')({
      clioDocumentId,
      casename,
      parentFolderId,
      userdocid: userdocid || firebase.auth().currentUser.uid,
    });
  }

  async checkIfClioDocumentExists(
    clioDocumentId: any,
    caseName: string,
  ): Promise<firebase.firestore.DocumentData | false> {
    this.auth_$.showLoader(`Check if Clio document exists NuageDx...`);
    const fireStoreData = await dbFiles
      .where('clioDocId', '==', parseInt(clioDocumentId))
      .where('belongsTo', '==', caseName)
      .get();
    console.log('fireStoreData :', fireStoreData);
    if (fireStoreData.empty) {
      this.auth_$.hideLoader();
      return false;
    }
    const doc = fireStoreData.docs[0];
    this.auth_$.hideLoader();
    return { id: doc.id, data: doc.data() };
  }

  async getMatterInfoByDocumentId(documentId, userdocid) {
    const documentInfo = await this.getClioDocumentInfoById(documentId, userdocid);
    console.log('documentInfo :', documentInfo);
    if (!documentInfo['matter']) {
      return false;
    }
    return documentInfo['matter'];
  }

  async getClioDocumentInfoById(documentId, userdocid) {
    const documentInfo = await firebase
      .functions()
      .httpsCallable('clio-getMatterInfoByClioDocumentId')({ documentId, userdocid })
      .catch(err => {
        console.error('err :', err);
        return false;
      });
    return documentInfo['data'];
  }

  createClientMatter(data: {
    localData: any;
    matterData: { client: any; id: any; number: any; display_number: any; description: any };
    clientData: { name: any; type: any; initials: any; first_name: any; last_name: any };
  }) {
    console.log('matterData data :', data.matterData);
    console.log('clientData data :', data.clientData);
    const caseName = data.matterData.display_number;
    const newPatientObject = {
      name: data.clientData.name,
      origin: 'clio',
      clioDocId: data.matterData.id,
      defaultFolders: false,
      lpmFields: {
        matter: {
          id: data.matterData.id,
          number: data.matterData.number,
          display_number: data.matterData.display_number,
          description: data.matterData.description,
        },
        client: {
          name: data.clientData.name,
          type: data.clientData.type,
          initials: data.clientData.initials,
          first_name: data.clientData.first_name || '',
          last_name: data.clientData.last_name || '',
        },
      },
      caseName: caseName,
      description: data.matterData.description,
      type: data.clientData.type,
      initials: data.clientData.initials,
      FirstName: data.clientData.first_name || '',
      LastName: data.clientData.last_name || '',
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      uidOfCreator: firebase.auth().currentUser.uid || null,
      ownerID: firebase.auth().currentUser.uid || null,
      forDelete: false,
    };
    return dbPatients
      .doc(data.matterData.display_number)
      .set(newPatientObject)
      .then(() => {
        return newPatientObject;
      })
      .catch(error => {
        console.error(error);
      });
  }

  private async checkDefaultFolders(caseName: string): Promise<boolean> {
    const result = await dbPatients
      .where('caseName', '==', caseName)
      .where('forDelete', '==', false)
      .where('defaultFolders', '==', true)
      .get();
    const checkDefaultFoldersAnswer = !result.empty;
    console.log('checkDefaultFoldersAnswer: ', checkDefaultFoldersAnswer);
    return checkDefaultFoldersAnswer;
  }

  private async checkDefaultFoldersV2(caseName: string): Promise<boolean> {
    const _defaultFolders = defaultFolders.map(folder => folder.name);
    const result = await dbFiles
      .where('belongsTo', '==', caseName)
      .where('type', '==', FOLDER_TYPE)
      .where('name', 'in', _defaultFolders)
      .get();
    return !result.empty;
  }

  async createDefaultFolders(caseName: string) {
    const defaultFoldersCheck = await this.checkDefaultFoldersV2(caseName);
    if (defaultFoldersCheck) return true;

    this.auth_$.showLoader('Creating your default folders...');

    const promisesStack = [];
    defaultFolders.forEach(folder =>
      promisesStack.push(
        this.createFolder({
          children: '',
          name: folder.name,
          color: folder.color,
          parentFolder: '',
          type: FOLDER_TYPE,
          belongsTo: caseName,
          predefined: true,
          forDelete: false,
          folderId: this.getRandomString(20),
        }),
      ),
    );

    await Promise.all(promisesStack);
    return await this.updateDefaultFolders(true, { casename: caseName }).then(() => this.auth_$.hideLoader());
  }

  async getFolderId(caseName: string, folderName: string): Promise<string | false> {
    const result = await dbFiles
      .where('belongsTo', '==', caseName)
      .where('type', '==', FOLDER_TYPE)
      .where('name', '==', folderName)
      .get();
    if (result.empty) {
      return false;
    }
    return result.docs[0].data()['folderId'];
  }

  async createCustomFolder(caseName: string, folderName: string) {
    const _folderId = await this.getFolderId(caseName, folderName);
    if (_folderId) {
      console.log('Folder already exists');
      return _folderId;
    }

    const folderId = this.getRandomString(20);
    await this.createFolder({
      children: '',
      name: folderName,
      color: '#c5f0f6',
      parentFolder: '',
      type: FOLDER_TYPE,
      belongsTo: caseName,
      predefined: true,
      forDelete: false,
      folderId,
    });
    return folderId;
  }

  getRandomString(stringLength: number = 10) {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    let randomstring = '';
    for (let i = 0; i < stringLength; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }

  async createFolder(folderObject: Folder) {
    const { type, children, parentFolder, name, belongsTo, folderId, predefined, color } = folderObject;
    return dbFiles.add({
      type,
      children,
      parentFolder,
      name,
      color,
      predefined,
      belongsTo,
      forDelete: false,
      folderId,
    });
  }

  updateDefaultFolders(flag: boolean, contextParams): Promise<boolean> {
    const { casename } = contextParams;
    return dbPatients
      .where('caseName', '==', casename)
      .where('forDelete', '==', false)
      .limit(1)
      .get()
      .then(snapshot => {
        snapshot.forEach(item => {
          dbPatients.doc(item.id).update({ defaultFolders: flag });
        });
        return flag;
      });
  }
}
