<mat-tree [dataSource]="dataSource"
          [treeControl]="treeControl">
  <!-- Folder node -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChildren">
    <div class="tree-node">
      <button mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name"
              (click)="handleNodeExpand(node)">
        <mat-icon *ngIf="!isFolderLoading(node)"
                  class="expand-icon">
          {{treeControl.isExpanded(node) ? 'remove' : 'add'}}
        </mat-icon>
        <mat-spinner *ngIf="isFolderLoading(node)"
                     diameter="20">
        </mat-spinner>
      </button>
      <mat-checkbox *ngIf="!isFileShared(node)"
                    class="checkbox-margin"
                    [checked]="selection.isSelected(node)"
                    [indeterminate]="descendantsPartiallySelected(node)"
                    (change)="nodeSelectionToggle(node)">
      </mat-checkbox>
      <div *ngIf="isFileShared(node)" class="checkbox-margin"></div>
      <mat-icon class="type-icon">
        {{getNodeIcon(node)}}
      </mat-icon>
      <span class="node-name" [class.shared-file]="isFileShared(node)">
        {{node.name}}
      </span>
    </div>

    <!-- Child items container -->
    <div class="nested-tree-node"
         [class.tree-node-invisible]="!treeControl.isExpanded(node)">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>

  <!-- File node -->
  <mat-tree-node *matTreeNodeDef="let node">
    <div class="button-spacer"></div>
    <mat-checkbox *ngIf="!isFileShared(node)"
                  class="checkbox-margin"
                  [checked]="selection.isSelected(node)"
                  (change)="selection.toggle(node)">
    </mat-checkbox>
    <div *ngIf="isFileShared(node)" class="checkbox-margin"></div>
    <mat-icon class="type-icon">
      {{getNodeIcon(node)}}
    </mat-icon>
    <span class="node-name" [class.shared-file]="isFileShared(node)">
      {{node.name}}
    </span>
  </mat-tree-node>
</mat-tree>
