import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRoles } from 'src/app/dictionaries/UserRoles';
import { SimpleMessageWindowComponent } from './../components/ui/simple-message-window/simple-message-window.component';
import { LocalStorageService } from './../services/local-storage.service';
import { RedirectionService } from './../services/redirection.service';

import { AllusersComponent } from '../components/allusers/allusers.component';
import { SearchComponent } from '../components/search/search.component';
import { DialogService } from '../dialog.service';
import { SessionStorageService } from '../services/session-storage.service';
import { AuthService } from './../services/auth.service';

import { AttorneysLawFirmsComponent } from '../components/attorneys-law-firms/attorneys-law-firms.component';
import { CreatepatientComponent } from '../components/createpatient/createpatient.component';
import { CreateuserComponent } from '../components/createuser/createuser.component';
import { ContentHandlerService } from '../content-handler.service';
const DEFAULT_PLAN = 'fp';
const CLIO_NAME = 'clio';

@Component({
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  userData: {};
  userEnabled: boolean;
  loaded: boolean;
  userRole: any;
  homepageParagraph: string;

  constructor(
    private dialog_$: DialogService,
    private redirection_$: RedirectionService,
    private localStorage_$: LocalStorageService,
    private sessionStorage_$: SessionStorageService,
    private auth_$: AuthService,
    public ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private contentHandler_$: ContentHandlerService,
  ) {
    this.loaded = false;
    this.homepageParagraph = this.contentHandler_$.homePageComponent('homePageParagraph');
  }

  getOwnerPlanCode(ownerID: string) {
    return this.auth_$.getOwnerPlanCode(ownerID);
  }

  async handleRoute(): Promise<boolean> {
    if (!this.activatedRoute.snapshot.url[0]) {
      return false;
    }

    let role: string;
    let data: any;
    let rolesAllowed: string | any[];
    let component: any = AllusersComponent;
    let id = null;

    const buildOptions = (data: any, role: string, id: string | null) => {
      const options: any = { width: '600px', data: data || { role } };
      if (id) {
        options['id'] = id;
      }
      return options;
    };

    switch (this.activatedRoute.snapshot.url[0].path) {
      case 'consultants':
        rolesAllowed = [UserRoles.admin, UserRoles.owner, UserRoles.associate];
        role = UserRoles.consultant;
        break;
      case 'owners':
        rolesAllowed = [UserRoles.superuser];
        role = UserRoles.owner;
        break;
      case 'associates':
        rolesAllowed = [UserRoles.admin, UserRoles.owner, UserRoles.associate];
        role = UserRoles.associate;
        break;
      case 'admins':
        rolesAllowed = [UserRoles.owner];
        role = UserRoles.admin;
        break;
      case 'attorneys_lawfirms':
        rolesAllowed = [UserRoles.consultant];
        component = AttorneysLawFirmsComponent;
        break;
      case 'clients':
        rolesAllowed = [UserRoles.admin, UserRoles.owner, UserRoles.associate];
        role = UserRoles.client;
        id = 'clients';
        break;
      case 'createclientmatter':
        rolesAllowed = [UserRoles.admin, UserRoles.owner, UserRoles.associate, UserRoles.consultant];
        role = UserRoles.client;
        component = CreatepatientComponent;
        id = 'createclientmatter';
        break;
      case 'clientsmatters':
        rolesAllowed = [UserRoles.admin, UserRoles.owner, UserRoles.associate, UserRoles.consultant];
        role = this.auth_$.userData.value['role'];
        component = SearchComponent;
        id = 'search';

        const ownerID = [UserRoles.consultant, UserRoles.associate, UserRoles.admin].includes(this.userRole)
          ? this.auth_$.userData.getValue()['owners'][0]
          : this.auth_$.userData.value['uid'];
        const ownerPlanCode = await this.getOwnerPlanCode(ownerID);
        data = { role, id, ownerID, ownerPlanCode };
        break;
      case 'createclientsmatters':
        rolesAllowed = [UserRoles.admin, UserRoles.owner, UserRoles.associate, UserRoles.consultant];
        role = this.auth_$.userData.value['role'];
        component = CreatepatientComponent;
        id = 'create-patient';
        data = null;
        break;
      case 'createadminuser':
        rolesAllowed = [UserRoles.admin, UserRoles.owner, UserRoles.associate, UserRoles.consultant];
        role = this.auth_$.userData.value['role'];
        component = CreateuserComponent;
        id = 'create-admin-user';
        data = {
          userType: 'Admin',
          title: 'Create Admin',
          button: 'Create',
          screen: 'full',
          limits: { limits: await this.checkPlanLimits() },
        };
        break;
      case 'lpmwelcome':
        rolesAllowed = [UserRoles.owner];
        const options = buildOptions(
          {
            title: 'Welcome to Nuage Diagnostics',
            message: `We are excited to have you on board. Please feel free to reach out to us at any time.`,
            id: 'lpm-welcome',
          },
          null,
          id,
        );
        this.dialog_$
          .open(SimpleMessageWindowComponent, options)
          .afterClosed()
          .subscribe(() => this.router.navigate(['/']));
        return;
      default:
        break;
    }

    const goHome = () => {
      this.router.navigate(['']);
    };

    if (rolesAllowed?.includes(this.auth_$.userData.value['role'])) {
      console.log('Open All Users');

      // Check if the dialog is already open.
      const dialogOpen = this.dialog_$.getOpenDialogs().length > 0;

      if (dialogOpen) {
        console.log('Dialog already open', dialogOpen);
        goHome();
        return;
      }

      const dialog = this.dialog_$.open(component, buildOptions(data, role, id));
      dialog.afterClosed().subscribe(answer => {
        if (answer?.type === 'owner') {
          // Open search component with owner id.
          goHome();
          const searchComponentData = { data: { ownerID: answer.value } };
          this.dialog_$.open(SearchComponent, searchComponentData);
        } else if (!(answer && answer.client)) {
          goHome();
        }
      });
      return true;
    } else {
      const options = buildOptions(
        { title: 'Access Denied', message: `You do not have access to this page.` },
        role,
        'access-denied',
      );

      const dialog = this.dialog_$.open(SimpleMessageWindowComponent, options).updatePosition({ top: '5%' });
      dialog.afterClosed().subscribe(() => this.router.navigate(['']));
      return true;
    }
  }

  ngOnInit(): void {
    this.auth_$.userData
      .subscribe(userData => {
        if (Object.keys(userData).length > 0) {
          this.userData = userData;
          this.userRole = userData['role'];
          this.loaded = true;
          this.yesUserAction();
        } else {
          this.noUserAction();
        }
      })
      .unsubscribe();
  }

  goToLoginClio() {
    const eu = this.sessionStorage_$.getAddToClioEU();
    if (eu) this.redirection_$.goToLoginClio(true);
    else this.redirection_$.goToLoginClio();
  }

  async yesUserAction() {
    if (this.sessionStorage_$.getAddToClioStarted() === 'true') {
      await this.auth_$.logout();
      this.sessionStorage_$.setAddToClioStarted(true);
      return;
    }
    this.userEnabled = true;

    // If this comes from Clio Open App action, and user is not fully registered, redirect to complete registration page.
    if (this.activatedRoute.snapshot.queryParams.from === CLIO_NAME) {
      this.fromClioAction();
    } else {
      this.notFromClioAction();
    }
  }

  async notFromClioAction() {
    const routeHandled = await this.handleRoute();
    const { testaccount, lpm, plancode, clioregistered } = <
      { testaccount; lpm: string; plancode: string; clioregistered: boolean }
    >this.auth_$.userData.getValue();

    if (!routeHandled) {
      if (!testaccount && !clioregistered && lpm === CLIO_NAME && plancode === DEFAULT_PLAN) {
        this.redirection_$.redirectToCompleteClioRegistration();
        return;
      }
    }
  }

  fromClioAction() {
    this.sessionStorage_$.setSSO('0');
    this.auth_$.userData.subscribe(userData => {
      if (Object.keys(userData).length > 0) {
        const notTestAccount = !userData['testaccount'];
        const notClioSSO = !userData['clioSSO'];
        const notClioRegistered = !this.auth_$.isClioRegistered();
        const hasFPPlan = this.auth_$.userData.getValue()['plancode'] === DEFAULT_PLAN;

        if (notClioRegistered && notClioSSO && notTestAccount && hasFPPlan) {
          this.redirection_$.redirectToCompleteClioRegistration();
          return;
        } else {
          if (this.localStorage_$.getLPMWelcome()) {
            this.router.navigate(['/']);
            return;
          } else {
            this.router.navigate(['/', 'lpmwelcome']);
            this.localStorage_$.setLPMWelcome('true');
          }
        }
      } else {
        this.goToLoginClio();
      }
    });
  }

  noUserAction() {
    if (this.activatedRoute.snapshot.queryParams.from === CLIO_NAME) {
      this.goToLoginClio();
    } else {
      // this.router.navigate(['/login']);
    }
  }

  async checkPlanLimits() {
    const [limits, users] = await Promise.all([
      this.getPlanLimits(this.auth_$.userData.value['plancode']),
      this.getUsersCount(),
    ]);
    return { limits, users };
  }

  private getUsersCount() {
    return this.auth_$.getUsersCount();
  }

  private getPlanLimits(planCode: string) {
    return this.auth_$.getPlanLimits(planCode).then(limits => {
      return limits;
    });
  }
}
