<app-window-title title="Select which documents to share"
                  subtitle
                  [closable]="true"></app-window-title>
<app-client-matter-info [matterData]="matterData"></app-client-matter-info>
<div fxLayout="row">
  <app-file-explorer *ngIf="isAuthorized; else notOwner"
                     #fileExplorer
                     [data]="transformedFileData"
                     [localDocuments]="localDocuments"
                     (getFolderContents)="getFilesAndFoldersFromFolderId($event)">
  </app-file-explorer>

  <ng-template #notOwner>
    <mat-selection-list #list
                        class="mat-list-container"
                        fxFlex="50">
      <div mat-subheader>
        Clio Matter documents not shared to NuageDx
      </div>
      <div class="mat-list-items-container">
        <ng-container *ngFor="let file of sourceDocuments">
          <mat-list-option *ngIf="!file.existing"
                           [value]="file.id">
            {{file.name}}
          </mat-list-option>
        </ng-container>
      </div>
    </mat-selection-list>
  </ng-template>

  <mat-list fxFlex="50"
            role="list">
    <div mat-subheader>
      NuageDx Client/Matter documents shared from Clio
    </div>
    <div class="mat-list-items-container">
      <mat-list-item *ngFor="let file of localDocuments"
                     class="item-exists"
                     role="listitem">
        {{file.fileName}}
      </mat-list-item>
    </div>
    <mat-list-item *ngIf="localDocuments?.length === 0"
                   class="item-exists"
                   role="listitem">
      No documents shared yet.
    </mat-list-item>
  </mat-list>
</div>
<div class="buttons-row"
     fxLayout="row"
     fxLayoutAlign="space-evenly center">
  <button *ngIf="isAuthorized; else shareSelectedOld"
          color="warn"
          mat-flat-button
          (click)="handleImportSelectedNew()">
    <mat-icon>
      share
    </mat-icon>
    SHARE SELECTED
  </button>
  <ng-template #shareSelectedOld>
    <button color="warn"
            mat-flat-button
            (click)="handleImportSelected()">
      <mat-icon>
        done
      </mat-icon>
      SHARE SELECTED
    </button>
  </ng-template>
  <button color="warn"
          mat-flat-button
          (click)="handleImportAll()">
    <mat-icon>
      done_all
    </mat-icon>
    SHARE ALL
  </button>
  <button color="warn"
          mat-flat-button
          (click)="continueWithoutAction()">
    <mat-icon>
      block
    </mat-icon>
    CONTINUE WITHOUT SHARING
  </button>
</div>
