<!-- Destination panel overlay -->
<div *ngIf="showDestinationPanel" class="destination-overlay">
  <mat-card class="destination-panel file-panel">
    <mat-card-header>
      <mat-card-title>
        Clio Files in NuageDx
      </mat-card-title>
      <!-- Close button -->
      <button class="close-destination-button nuage-button small"
              mat-icon-button
              (click)="closeDestinationPanel()">
        <mat-icon>
          close
        </mat-icon>
      </button>
    </mat-card-header>
    <mat-card-content>
      <div class="file-tree">
        <ng-container *ngFor="let item of nuageDxFiles">
          <!-- Folder item with add icon -->
          <div class="folder-item">
            <mat-icon class="add-icon">
              add
            </mat-icon>
            <mat-icon class="folder-icon">
              folder
            </mat-icon>
            <span class="folder-name">
              {{item.name}}
            </span>
          </div>

          <!-- Folder children: show only if folder or any parent folder is not selected on the left panel -->
          <div *ngIf="!isFolderSelectedInLeftPanel(item)"
               class="folder-children">
            <div *ngFor="let child of item.children"
                 class="file-item">
              <mat-icon *ngIf="child.type === 'file'"
                        class="file-icon">
                description
              </mat-icon>
              <mat-icon *ngIf="child.type === 'image'"
                        class="file-icon">
                image
              </mat-icon>
              <span class="file-name">
                {{child.name}}
              </span>
              <mat-icon class="remove-icon">
                close
              </mat-icon>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-card-content>
  </mat-card>
</div>
