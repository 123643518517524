import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FilesService } from 'src/app/services/files.service';

interface FileMenuItem {
  icon: string;
  label: string;
  class?: string;
  condition: () => boolean;
  action: (event: MouseEvent) => void;
}

@Component({
  selector: 'app-fn-file-row',
  templateUrl: './fn-file-row.component.html',
  styleUrls: ['./fn-file-row.component.scss'],
})
export class FnFileRowComponent {
  @Input() file: any;
  @Input() isSelected: boolean = false;
  @Output() selectionChanged = new EventEmitter<boolean>();
  @Output() rowClicked = new EventEmitter<any>();

  constructor(private files_$: FilesService) {
    // Initialize any required properties here
    this.isSelected = false;
  }

  fileActionsMenuItems: FileMenuItem[] = [
    {
      icon: 'download',
      label: 'Download',
      condition: () => true,
      action: event => this.downloadFile(event),
    },
    {
      icon: 'delete',
      label: 'Delete',
      class: 'danger-action',
      condition: () => true,
      action: event => this.deleteFile(event),
    },
    {
      icon: 'edit',
      label: 'Rename',
      condition: () => true,
      action: event => this.renameFile(event),
    },
    {
      icon: 'info',
      label: 'Properties',
      condition: () => true,
      action: event => this.showProperties(event),
    },
  ];

  onSelectionChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    this.selectionChanged.emit(checkbox.checked);
    this.files_$.cp_selectionChanged(this.file, checkbox.checked);
  }

  handleFileActions(event: Event): void {
    event.stopPropagation();
  }

  onRowClick(event: MouseEvent): void {
    this.rowClicked.emit(this.file);
  }

  private downloadFile(event: MouseEvent): void {
    console.log('Download file:', this.file.fileName);
    // Implement download logic
  }

  private deleteFile(event: MouseEvent): void {
    console.log('Delete file:', this.file.fileName);
    // Implement delete logic
  }

  private renameFile(event: MouseEvent): void {
    console.log('Rename file:', this.file.fileName);
    // Implement rename logic
  }

  private showProperties(event: MouseEvent): void {
    console.log('Show properties for:', this.file.fileName);
    // Implement properties display logic
  }
}
