<div *ngIf="!userEnabled"
     class="homepage-container">
  <a class="logo-link"
     href="/">
    <img class="logo-image"
         alt="NuageDX Logo"
         src="../../assets/Nuage-Logo.png">
  </a>
  <p class="homepage-paragraph"
     [innerHTML]="homepageParagraph"></p>
</div>
