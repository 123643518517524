// file-transfer.component.ts
import { Component, OnInit } from '@angular/core';

interface FileItem {
  name: string;
  type: 'file' | 'folder' | 'image';
  size?: string;
  date?: string;
  selected?: boolean;
  isDuplicate?: boolean;
  path?: string;
  children?: FileItem[];
  expanded?: boolean;
  count?: number;
  parentFolder?: string;
}

@Component({
  selector: 'app-file-transfer',
  templateUrl: './file-transfer.component.html',
  styleUrls: ['./file-transfer.component.scss'],
})
export class FileTransferComponent implements OnInit {
  matterId: string = '00002_Mayerlen';

  // Source files from Clio
  clioFiles: FileItem[] = [
    { name: 'File1.pdf', type: 'file', selected: false, size: '56KB', date: '11/15/2023' },
    {
      name: 'Folder 1',
      type: 'folder',
      expanded: true,
      children: [
        { name: 'Document.pdf', type: 'file', selected: false, size: '14KB', date: '08/04/2023' },
        { name: 'Book.pdf', type: 'file', selected: false, size: '2.3MB', date: '09/12/2023' },
        { name: 'Evidence.jpg', type: 'image', selected: false, size: '1.4MB', date: '10/23/2023' },
      ],
    },
    {
      name: 'Other',
      type: 'folder',
      expanded: false,
      children: [
        { name: 'Document.pdf', type: 'file', selected: false, size: '14KB', date: '08/04/2023' },
        { name: 'Report.pdf', type: 'file', selected: false, size: '4KB', date: '10/04/2023' },
      ],
    },
  ];

  // Selected files for transfer - initialize as empty
  selectedFiles: FileItem[] = [];

  // Destination files in NuageDx
  nuageDxFiles: FileItem[] = [
    {
      name: 'Folder 1',
      type: 'folder',
      children: [
        { name: 'Evidence.jpg', type: 'image', size: '1.4MB', date: '10/23/2023' },
        { name: 'Document.pdf', type: 'file', size: '14KB', date: '08/04/2023' }, // Added Document.pdf to match the one in Clio
      ],
    },
  ];

  // Duplicated files
  duplicatedFiles: FileItem[] = [
    { name: 'Document.pdf', type: 'file', selected: true },
    { name: 'Document_1.pdf', type: 'file', selected: true },
  ];

  // Resolution option
  resolutionOptions = [
    { value: 'skip', label: 'Skip duplicates' },
    { value: 'rename', label: 'Rename' },
    { value: 'keep', label: 'Keep both', checked: true },
  ];

  // Selected resolution option - initialize with the checked option
  selectedResolutionOption: string = 'keep';

  // Track if there are exact duplicates
  hasExactDuplicates: boolean = false;

  // Flag to toggle visibility of duplicates panel and warning icons
  showDuplicatesPanel: boolean = false;

  // Sample account number for renaming preview
  accountNumber: string = '2023-4567';

  // Debug flag
  showDebugInfo: boolean = false;

  constructor() {}

  ngOnInit(): void {
    // Make sure only one folder is expanded initially
    this.ensureSingleExpandedFolder();

    // Ensure selectedFiles is empty initially
    this.selectedFiles = [];

    // Check for duplicates
    this.updateDuplicatesState();

    // Initialize selected resolution option
    this.selectedResolutionOption = this.resolutionOptions.find(opt => opt.checked)?.value || 'keep';

    // Sync folder selection states with their children
    this.updateAllFolderSelectionStates();

    // Initialize showDuplicatesPanel to false
    this.showDuplicatesPanel = false;
  }

  /**
   * Toggles the visibility of the duplicates panel and warning icons
   */
  toggleShowDuplicates(): void {
    this.showDuplicatesPanel = !this.showDuplicatesPanel;
    console.log(`Duplicates panel visibility: ${this.showDuplicatesPanel}`);
  }

  /**
   * Toggles folder expansion state
   * Collapses any other expanded folders to ensure only one is open at a time
   * No longer selects files - only handles expansion/collapse
   */
  toggleFolder(folder: FileItem): void {
    // Handle folder expansion
    if (folder.expanded) {
      // If the folder is already expanded, just collapse it
      folder.expanded = false;
    } else {
      // If the folder is collapsed, expand it and collapse all others
      this.collapseAllFolders();
      folder.expanded = true;
    }

    // No longer select files here - that's now only handled by toggleFolderSelection
  }

  /**
   * Toggles folder selection and selects/deselects all child files
   * Only handles selection state, not expansion
   */
  toggleFolderSelection(folder: FileItem, newSelectionState: boolean): void {
    // The newSelectionState parameter now contains the new checkbox state from ngModelChange
    
    console.log(`Folder ${folder.name} selection changed to: ${newSelectionState}`);

    // Skip processing if no children
    if (!folder.children || folder.children.length === 0) return;
    
    // Update all children to match the folder's selection state
    folder.children.forEach(child => {
      // Set child selection state to match folder
      child.selected = newSelectionState;
      
      console.log(`Setting child ${child.name} selection to: ${child.selected}`);
      
      // Handle selection changes for each child
      if (newSelectionState) {
        // Add to selected files if not already there
        this.addFileToSelectedList(child, folder.name);
      } else {
        // If folder is deselected, find and remove its children
        this.selectedFiles = this.selectedFiles.filter(item => 
          !(item.name === child.name && item.parentFolder === folder.name)
        );
      }
    });
    
    // Log the state after changes for debugging
    console.log(`Folder "${folder.name}" selection state: ${folder.selected}`);
    console.log(`Children selection states: ${folder.children.map(c => c.selected).join(', ')}`);
    
    // Update duplicates state
    this.updateDuplicatesState();
  }

  /**
   * Helper method to add a file to the selected files list
   * This ensures consistent handling of files when added from folders
   */
  private addFileToSelectedList(file: FileItem, parentFolderName: string): void {
    // Check if file is already in the selected list
    if (!this.isFileInSelectedList(file)) {
      // Create a copy with additional info
      const selectedFile = { 
        ...file, 
        size: file.size || this.getFileSize(), 
        date: file.date || this.getCurrentDate(),
        isDuplicate: this.checkIfDuplicate(file.name),
        parentFolder: parentFolderName
      };
      
      this.selectedFiles.push(selectedFile);
      console.log(`Added file to selected list: ${selectedFile.name}`);
    }
  }

  /**
   * Removes all files from a specific folder from the selectedFiles array
   */
  removeSelectedFilesFromFolder(folder: FileItem): void {
    if (!folder.children) return;
    // Get the folder name for identification
    const folderName = folder.name;
    // Remove all files from this folder
    this.selectedFiles = this.selectedFiles.filter(item => item.parentFolder !== folderName);
    // Update duplicates state
    this.updateDuplicatesState();
  }

  /**
   * Check if all files in a folder are selected
   */
  areAllFolderFilesSelected(folder: FileItem): boolean {
    if (!folder.children || folder.children.length === 0) return false;
    return folder.children.every(child => child.selected);
  }

  /**
   * Check if any files in a folder are selected
   */
  areSomeFolderFilesSelected(folder: FileItem): boolean {
    if (!folder.children || folder.children.length === 0) return false;
    return folder.children.some(child => child.selected) && !folder.children.every(child => child.selected);
  }

  /**
   * Collapses all folders in the clioFiles list
   */
  collapseAllFolders(): void {
    this.collapseAllFoldersInList(this.clioFiles);
    this.collapseAllFoldersInList(this.nuageDxFiles);
  }

  /**
   * Collapses all folders in a given file list
   */
  collapseAllFoldersInList(fileList: FileItem[]): void {
    fileList.forEach(item => {
      if (item.type === 'folder') {
        item.expanded = false;
        // Recursively collapse nested folders if any
        if (item.children) {
          this.collapseAllFoldersInList(item.children.filter(child => child.type === 'folder') as FileItem[]);
        }
      }
    });
  }

  /**
   * Ensures only a single folder is expanded initially
   */
  ensureSingleExpandedFolder(): void {
    let foundExpanded = false;
    // Process clioFiles
    for (let i = 0; i < this.clioFiles.length; i++) {
      const item = this.clioFiles[i];
      if (item.type === 'folder') {
        if (item.expanded && !foundExpanded) {
          foundExpanded = true;
        } else {
          item.expanded = false;
        }
      }
    }
    // Process nuageDxFiles only if no expanded folder was found in clioFiles
    if (!foundExpanded) {
      for (let i = 0; i < this.nuageDxFiles.length; i++) {
        const item = this.nuageDxFiles[i];
        if (item.type === 'folder') {
          if (item.expanded && !foundExpanded) {
            foundExpanded = true;
          } else {
            item.expanded = false;
          }
        }
      }
    }
  }

  selectAll(): void {
    // Get the current selection state (if any files are selected)
    const hasSelectedFiles = this.clioFiles.some(item => {
      if (item.type === 'file') return item.selected;
      if (item.type === 'folder' && item.children) {
        return item.children.some(child => child.selected);
      }
      return false;
    });
    // Toggle selection state
    const newSelectionState = !hasSelectedFiles;
    // Update all files selection state
    this.updateSelectionForAllFiles(this.clioFiles, newSelectionState);
    // Update selected files list
    if (newSelectionState) {
      // Add all files to selectedFiles
      this.addAllFilesToSelected(this.clioFiles);
    } else {
      // Clear selected files
      this.selectedFiles = [];
    }
    // Update duplicates state
    this.updateDuplicatesState();
  }

  /**
   * Updates selection state for all files in a list
   */
  updateSelectionForAllFiles(fileList: FileItem[], selected: boolean): void {
    fileList.forEach(item => {
      if (item.type === 'file' || item.type === 'image') {
        item.selected = selected;
      } else if (item.type === 'folder' && item.children) {
        item.selected = selected;
        this.updateSelectionForAllFiles(item.children, selected);
      }
    });
  }

  /**
   * Adds all files from a list to the selected files list
   */
  addAllFilesToSelected(fileList: FileItem[]): void {
    fileList.forEach(item => {
      if ((item.type === 'file' || item.type === 'image') && !this.isFileInSelectedList(item)) {
        this.selectedFiles.push({
          ...item,
          size: item.size || this.getFileSize(),
          date: item.date || this.getCurrentDate(),
          isDuplicate: this.checkIfDuplicate(item.name),
        });
      } else if (item.type === 'folder' && item.children) {
        this.addAllFilesToSelected(item.children);
      }
    });
  }

  /**
   * Removes a file from the selected files list
   * Modified to handle files with the same name from different folders
   */
  removeSelectedFile(file: FileItem): void {
    // Remove specific file instance from selectedFiles array
    this.selectedFiles = this.selectedFiles.filter(
      item => !(item.name === file.name && item.parentFolder === file.parentFolder),
    );
    // Also update the selection state in the original file list
    this.updateSourceFileSelectionState(file, false);
    // Update duplicates state
    this.updateDuplicatesState();
  }

  /**
   * Updates a file's selection state in the original file list
   * Modified to handle files with the same name from different folders
   */
  updateFileSelectionInList(fileList: FileItem[], fileToUpdate: FileItem): void {
    const parentFolderName = fileToUpdate.parentFolder;
    // If we know the parent folder, use it to find the exact file
    if (parentFolderName) {
      // Find the parent folder first
      for (const item of fileList) {
        if (item.type === 'folder' && item.name === parentFolderName && item.children) {
          // Find and update the file in this folder
          const fileInFolder = item.children.find(child => child.name === fileToUpdate.name);
          if (fileInFolder) {
            fileInFolder.selected = false;
            return;
          }
        }
      }
    }
    // Fallback to the old method if parent folder approach fails
    fileList.forEach(item => {
      if (
        (item.type === 'file' || item.type === 'image') &&
        item.name === fileToUpdate.name &&
        item.size === fileToUpdate.size &&
        item.date === fileToUpdate.date
      ) {
        item.selected = false;
      } else if (item.type === 'folder' && item.children) {
        this.updateFileSelectionInList(item.children, fileToUpdate);
      }
    });
  }

  /**
   * Toggle file selection
   * Modified to handle files with the same name from different folders
   */
  toggleFileSelection(file: FileItem): void {
    // Find the parent folder for proper identification - do this regardless of selection state
    const parentFolder = this.findParentFolder(this.clioFiles, file);
    const parentFolderName = parentFolder ? parentFolder.name : null;
    if (file.selected) {
      // If file is selected, add it to the selectedFiles array if not already there
      // Create a copy with additional info to track its origin
      const selectedFile = {
        ...file,
        size: file.size || this.getFileSize(),
        date: file.date || this.getCurrentDate(),
        isDuplicate: this.checkIfDuplicate(file.name),
        parentFolder: parentFolderName, // Add parent folder info
      };
      // Check if this exact file is already in selected list
      if (!this.selectedFiles.some(item => item.name === file.name && item.parentFolder === parentFolderName)) {
        this.selectedFiles.push(selectedFile);
        // Debug log
        console.log(
          `Selected file: ${selectedFile.name} from ${parentFolderName || 'root'} (${selectedFile.size}, ${selectedFile.date})`,
        );
      }
    } else {
      // Remove this specific file instance from selectedFiles
      this.selectedFiles = this.selectedFiles.filter(
        item => !(item.name === file.name && item.parentFolder === parentFolderName),
      );
    }
    // Always update parent folder selection state if there is a parent folder
    if (parentFolder) {
      this.updateFolderSelectionState(parentFolder);
    }
    // Update duplicates state
    this.updateDuplicatesState();
  }

  /**
   * Checks if a file is already in the selected files list
   * Modified to check by reference instead of just by name
   */
  isFileInSelectedList(file: FileItem): boolean {
    // Create a unique identifier for the file including path information if available
    const fileId = file.path ? `${file.path}/${file.name}` : file.name;
    // For files in folders, we need to track which folder they came from
    if (!file.path && file.type !== 'folder') {
      // Get all parent folders
      const parentFolder = this.findParentFolder(this.clioFiles, file);
      if (parentFolder) {
        const folderPath = parentFolder.name;
        return this.selectedFiles.some(item => item.name === file.name && item.parentFolder === folderPath);
      }
    }
    // If no path or parent folder, just check by name and content equality
    return this.selectedFiles.some(
      item =>
        item.name === file.name &&
        item.size === file.size &&
        item.date === file.date &&
        item.parentFolder === file.parentFolder,
    );
  }

  /**
   * Find the parent folder of a file
   */
  findParentFolder(fileList: FileItem[], targetFile: FileItem): FileItem | null {
    for (const item of fileList) {
      if (item.type === 'folder' && item.children) {
        for (const child of item.children) {
          if (child === targetFile) {
            return item;
          }
        }
        // Recursive search in nested folders
        const foundInNestedFolder = this.findParentFolder(
          item.children.filter(child => child.type === 'folder') as FileItem[],
          targetFile,
        );
        if (foundInNestedFolder) {
          return foundInNestedFolder;
        }
      }
    }
    return null;
  }

  /**
   * Generates a random file size for demo purposes
   */
  getFileSize(): string {
    const size = Math.floor(Math.random() * 100);
    return `${size}KB`;
  }

  /**
   * Gets the current date formatted as MM/DD/YYYY
   */
  getCurrentDate(): string {
    const date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  /**
   * Check if a file is a duplicate in NuageDx files
   * This checks only by name for the warning icon
   */
  checkIfDuplicate(fileName: string): boolean {
    // Check if the file exists in any of the NuageDx folders
    for (const folder of this.nuageDxFiles) {
      if (folder.children && folder.children.some(file => file.name === fileName)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check for exact duplicates (name, size, and date match)
   * This now correctly checks for duplicates across different folders
   */
  checkForExactDuplicates(): boolean {
    // Use the debug version for all checks to ensure consistency
    return this.checkForExactDuplicatesDebug();
  }

  /**
   * Check for exact duplicates with extended debugging
   */
  checkForExactDuplicatesDebug(): boolean {
    // Reset duplicate flags on all selected files
    this.selectedFiles.forEach(file => {
      file.isDuplicate = false;
    });
    // Update duplicatedFiles array with exact duplicates
    this.duplicatedFiles = [];
    console.log(`Checking ${this.selectedFiles.length} files for duplicates...`);
    // Early return if we don't have enough files to have duplicates
    if (this.selectedFiles.length < 2) {
      console.log('Not enough files to check for duplicates');
      return false;
    }
    // Group by name first for easier debugging
    const filesByName = new Map<string, FileItem[]>();
    for (const file of this.selectedFiles) {
      if (!filesByName.has(file.name)) {
        filesByName.set(file.name, []);
      }
      filesByName.get(file.name)?.push(file);
    }
    // Log files grouped by name
    console.log('Files grouped by name:');
    filesByName.forEach((files, name) => {
      console.log(`${name}: ${files.length} files`);
    });
    // Check each name group for exact duplicates
    filesByName.forEach((files, name) => {
      if (files.length > 1) {
        // Group by size and date
        const exactMatches = new Map<string, FileItem[]>();
        for (const file of files) {
          const key = `${file.size}_${file.date}`;
          if (!exactMatches.has(key)) {
            exactMatches.set(key, []);
          }
          exactMatches.get(key)?.push(file);
        }
        // Check each group for exact duplicates (same name, size, date)
        exactMatches.forEach((exactFiles, key) => {
          console.log(`Group ${name} with ${key}: ${exactFiles.length} files`);
          if (exactFiles.length > 1) {
            // We have duplicates!
            console.log(`FOUND DUPLICATES: ${exactFiles.length} files with name=${name}, size/date=${key}`);
            // Mark all files in group as duplicates
            exactFiles.forEach(file => {
              file.isDuplicate = true;
              // Add each duplicate file individually to the duplicatedFiles array
              this.duplicatedFiles.push({
                name: file.name,
                type: file.type,
                selected: true,
                size: file.size,
                date: file.date,
                parentFolder: file.parentFolder,
              });
            });
          }
        });
      }
    });
    return this.duplicatedFiles.length > 0;
  }

  /**
   * Check if two files are exact duplicates (name, size, date)
   * Not used anymore as we do direct comparison
   */
  isExactDuplicate(fileA: FileItem, fileB: FileItem): boolean {
    return fileA.name === fileB.name && fileA.size === fileB.size && fileA.date === fileB.date;
  }

  /**
   * Update the duplicates state whenever selection changes
   */
  updateDuplicatesState(): void {
    this.hasExactDuplicates = this.checkForExactDuplicates();
  }

  /**
   * Called when transferring files to verify duplicates state
   */
  transferSelectedFiles(): void {
    // Force update duplicates state before transfer
    this.updateDuplicatesState();
    console.log('Transferring files...', {
      selectedFiles: this.selectedFiles,
      hasDuplicates: this.hasExactDuplicates,
      duplicatedFiles: this.duplicatedFiles,
    });
  }

  cancelTransfer(): void {
    // Logic for canceling transfer
    console.log('Transfer canceled');
  }

  changeResolutionOption(option: string): void {
    this.resolutionOptions.forEach(opt => {
      opt.checked = opt.value === option;
    });
    this.selectedResolutionOption = option;
  }

  /**
   * Gets a preview of a renamed file for display purposes
   * Uses a simple pattern with underscore and number
   */
  getRenamingPreview(fileName: string): string {
    if (!fileName) return '';
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return `${fileName}_1`;
    } else {
      const name = fileName.substring(0, lastDotIndex);
      const extension = fileName.substring(lastDotIndex);
      return `${name}_1${extension}`;
    }
  }

  /**
   * Navigate back to Clio
   */
  navigateBackToClio(): void {
    console.log('Navigating back to Clio');
    // Implement actual navigation logic here
  }

  closeDialog(): void {
    console.log('Closing dialog');
  }

  /**
   * Toggle debug panel
   */
  toggleDebug(): void {
    this.showDebugInfo = !this.showDebugInfo;
    if (this.showDebugInfo) {
      this.forceCheckDuplicates();
    }
  }

  /**
   * Force check duplicates with console logs for debugging
   */
  forceCheckDuplicates(): void {
    console.clear();
    console.log('Force checking duplicates...');
    console.log('Selected Files:', JSON.stringify(this.selectedFiles, null, 2));
    // Force check duplicates
    this.hasExactDuplicates = this.checkForExactDuplicatesDebug();
    console.log('Duplicates check result:', this.hasExactDuplicates);
    console.log('Duplicated Files:', JSON.stringify(this.duplicatedFiles, null, 2));
  }

  /**
   * Called when a file's checkbox is changed in the selected files panel
   */
  onSelectedFileCheckboxChange(file: FileItem): void {
    if (!file.selected) {
      // When a file is unchecked in the selected panel, also uncheck it in the source panel
      this.updateSourceFileSelectionState(file, false);
      // Remove from selected files if completely unchecked
      this.removeSelectedFile(file);
    }
    // Update folder selection states to ensure UI consistency
    this.updateAllFolderSelectionStates();
  }

  /**
   * Updates the source file selection state after a selection in the selected files panel
   */
  updateSourceFileSelectionState(file: FileItem, selected: boolean): void {
    const parentFolderName = file.parentFolder;
    let fileFound = false;
    // First, try to find the file in a specific folder if we know the parent folder
    if (parentFolderName) {
      for (const item of this.clioFiles) {
        if (item.type === 'folder' && item.name === parentFolderName && item.children) {
          const fileInFolder = item.children.find(child => child.name === file.name);
          if (fileInFolder) {
            fileInFolder.selected = selected;
            fileFound = true;
            // Update folder selection state based on children
            this.updateFolderSelectionState(item);
            break;
          }
        }
      }
    }
    // If the file wasn't found by parent folder, try to find it by name, size, and date
    if (!fileFound) {
      this.findAndUpdateFileInList(this.clioFiles, file, selected);
    }
  }

  /**
   * Helper method to find and update a file's selection state in a file list
   */
  findAndUpdateFileInList(fileList: FileItem[], fileToUpdate: FileItem, selected: boolean): boolean {
    for (const item of fileList) {
      if (
        (item.type === 'file' || item.type === 'image') &&
        item.name === fileToUpdate.name &&
        (item.size === fileToUpdate.size || !item.size) &&
        (item.date === fileToUpdate.date || !item.date)
      ) {
        item.selected = selected;
        return true;
      } else if (item.type === 'folder' && item.children) {
        if (this.findAndUpdateFileInList(item.children, fileToUpdate, selected)) {
          // Update folder selection state based on children
          this.updateFolderSelectionState(item);
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Update folder selection state based on its children's selection states
   */
  updateFolderSelectionState(folder: FileItem): void {
    if (!folder.children || folder.children.length === 0) return;
    // Set folder selected state to true if all children are selected,
    // false if none are selected
    const allSelected = folder.children.every(child => child.selected);
    const noneSelected = folder.children.every(child => !child.selected);
    if (allSelected) {
      // When all children are selected, the folder must be selected
      folder.selected = true;
      console.log(`Folder ${folder.name} is fully selected`);
    } else if (noneSelected) {
      folder.selected = false;
      console.log(`Folder ${folder.name} is fully unselected`);
    } else {
      console.log(`Folder ${folder.name} is partially selected`);
      // Leave indeterminate state (handled in the template)
    }
  }

  /**
   * Sync folder selection states with their children
   */
  syncFolderWithChildrenSelection(): void {
    // Update all folder selection states based on their children
    this.clioFiles.forEach(item => {
      if (item.type === 'folder' && item.children) {
        this.updateFolderSelectionState(item);
      }
    });
  }

  /**
   * Update all folder selection states to match their children's selection
   * Called at important points to ensure UI consistency
   */
  updateAllFolderSelectionStates(): void {
    // Process all folders to sync their selected state with their children
    this.clioFiles.forEach(item => {
      if (item.type === 'folder' && item.children) {
        this.updateFolderSelectionState(item);
      }
    });
  }

  /**
   * Debug method to output folder state
   */
  logFolderState(folder: FileItem): void {
    console.log(`Folder: ${folder.name}, Selected: ${folder.selected}`);
    if (folder.children) {
      folder.children.forEach(child => {
        console.log(`  Child: ${child.name}, Selected: ${child.selected}`);
      });
    }
  }
}
