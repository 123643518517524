<div fxLayout="row">
  <div fxFlex>
    <h2 class="mat-headline">
      Create Client/Matter
    </h2>
  </div>

  <div fxFlex="none"
       fxFlexOffset="1">
    <button color="warn"
            mat-dialog-close
            mat-flat-button>
      X
    </button>
  </div>
</div>
<div mat-dialog-content>
  <app-loader *ngIf="!ownerID"
              message="please wait..."></app-loader>
  <div *ngIf="ownerID"
       fxLayout="column"
       fxLayoutAlign="center center">
    <form style="width: 100%">
      <div fxLayout="row">
        <div fxFlex>
          <mat-form-field appearance="fill">
            <mat-label>
              First name
            </mat-label>
            <input #firstNameInput

                   class="mdc-text-field__input"
                   placeholder="First Name"
                   required
                   type="text"
                   matInput

                   [value]="fname"
                   (input)="fname = firstNameInput.value">
          </mat-form-field>
          <div class="mdc-text-field-helper-line">
            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
              Client must have a first name
            </div>
          </div>
        </div>
        <div fxFlex>
          <!-- Column 2 content goes here -->

          <mat-form-field appearance="fill">
            <mat-label>
              Last name
            </mat-label>
            <input #lastNameInput

                   class="mdc-text-field__input"
                   placeholder="Last Name"
                   required
                   type="text"
                   matInput

                   [value]="lname"
                   (input)="lname = lastNameInput.value">
          </mat-form-field>
          <div class="mdc-text-field-helper-line">
            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
              Client must have a last name
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="50">
          <!-- Column 1 content goes here -->
          <mat-form-field appearance="fill">
            <mat-label>
              Matter name
            </mat-label>
            <input #patientIdInput

                   class="mdc-text-field__input"
                   placeholder="Matter Name"
                   required
                   type="text"
                   matInput

                   [value]="patientId"
                   (input)="patientId = patientIdInput.value">
          </mat-form-field>
          <div class="mdc-text-field-helper-line">
            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
              Client must have a Matter Name
            </div>
          </div>
        </div>
        <div fxFlex="50">
          <!-- Column 2 content goes here -->

          <mat-form-field appearance="fill">
            <mat-label>
              Legal Case Id
            </mat-label>
            <input #legalCaseIdInput

                   class="mdc-text-field__input"
                   placeholder="Legal Case Id"
                   required
                   type="text"
                   matInput

                   [value]="legalCaseId"
                   (input)="legalCaseId = legalCaseIdInput.value">
          </mat-form-field>
          <div class="mdc-text-field-helper-line">
            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
              Client must have a case id
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="50">
          <!-- Column 1 content goes here -->
          <mat-form-field appearance="fill">
            <mat-label>
              Cell phone number
            </mat-label>
            <input #cellPhoneNumberInput

                   class="mdc-text-field__input"
                   type="text"
                   matInput

                   [value]="cellPhoneNumber"
                   (input)="cellPhoneNumber = cellPhoneNumberInput.value">
          </mat-form-field>
          <div class="mdc-text-field-helper-line">
            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
              Client must have a cell phone number
            </div>
          </div>
        </div>
        <div fxFlex="50">
          <!-- Column 2 content goes here -->

          <mat-form-field appearance="fill">
            <mat-label>
              Email address
            </mat-label>
            <input #emailAddressInput

                   class="mdc-text-field__input"
                   type="text"
                   matInput

                   [value]="emailAddress"
                   (input)="emailAddress = emailAddressInput.value">
          </mat-form-field>
          <div class="mdc-text-field-helper-line">
            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
              Client must have an emailAddress
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="50">
          <!-- Column 1 content goes here -->
          <mat-form-field appearance="fill">
            <mat-label>
              Date of birth
            </mat-label>
            <input placeholder="Date of Birth"
                   matInput
                   [formControl]="date"
                   [matDatepicker]="picker"
                   [max]="maxDate">
            <mat-datepicker-toggle matSuffix
                                   [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <!-- Column 2 content goes here -->
          <mat-form-field appearance="fill">
            <mat-label>
              Description
            </mat-label>
            <input #descriptionInput

                   class="mdc-text-field__input"
                   type="text"
                   matInput

                   [value]="description"
                   (input)="description = descriptionInput.value">
          </mat-form-field>
        </div>
      </div>
    </form>
    <div fxLayout="column"
         fxLayoutAlign="center center">
      <h5 style="color: red; font-family: Arial">
        {{errorMessage}}
      </h5>
    </div>
  </div>
</div>
<mat-dialog-actions *ngIf="ownerID"
                    align="center">
  <button color="warn"
          mat-flat-button
          (click)="createPatient()">
    CREATE CLIENT/MATTER
  </button>
</mat-dialog-actions>
