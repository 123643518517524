import { Component, Input, Output, EventEmitter } from '@angular/core';

interface FileItem {
  name: string;
  type: 'file' | 'folder' | 'image';
  size?: string;
  date?: string;
  selected?: boolean;
  isDuplicate?: boolean;
  path?: string;
  children?: FileItem[];
  expanded?: boolean;
  count?: number;
  parentFolder?: string;
  content?: string;
  depth?: number;
  visible?: boolean;
  parentPath?: string;
  alreadyTransferred?: boolean;
  _fileCount?: number;
  _subfolderCount?: number;
  _needsCountRefresh?: boolean;
  _lastCountRefreshTime?: number;
}

@Component({
  selector: 'app-destination-panel',
  templateUrl: './destination-panel.component.html',
  styleUrls: ['./destination-panel.component.scss']
})
export class DestinationPanelComponent {
  @Input() showDestinationPanel: boolean = false;
  @Input() nuageDxFiles: FileItem[] = [];
  @Input() selectedFiles: FileItem[] = [];
  
  @Output() closePanel = new EventEmitter<void>();

  /**
   * Returns true if the given folder or any parent folder is selected in the left panel.
   * Used to determine if folder's children should be displayed
   */
  isFolderSelectedInLeftPanel(folder: FileItem): boolean {
    // Check if this specific folder is selected directly
    const isDirectlySelected = this.selectedFiles.some(
      item => item.type === 'folder' && item.name === folder.name
    );
    
    if (isDirectlySelected) {
      return true;
    }
    
    // Check if any parent folder of this folder is selected
    // This requires checking for parent-child relationships
    return this.isContainedInSelectedParent(folder);
  }
  
  /**
   * Checks if this folder is contained within any selected parent folder
   */
  private isContainedInSelectedParent(folder: FileItem): boolean {
    // Get all selected folders
    const selectedFolders = this.selectedFiles.filter(item => item.type === 'folder');
    
    // For each selected folder, check if it's a parent of our target folder
    for (const selectedFolder of selectedFolders) {
      // A selected root folder would contain all folders in NuageDx
      // that have the same name or are subfolders
      
      // If the folder is a direct child of a selected folder
      if (folder.parentFolder === selectedFolder.name) {
        return true;
      }
      
      // If the folder has the same name as a selected folder
      // and is not at the root level (has a parent)
      if (folder.name === selectedFolder.name && folder.parentFolder) {
        return true;
      }
    }
    
    return false;
  }
  
  /**
   * Close the destination panel
   */
  closeDestinationPanel(): void {
    this.closePanel.emit();
  }

  /**
   * Get folder size (sum of all child file sizes)
   * Returns a formatted string like "1.2MB"
   */
  getFolderSize(folder: FileItem): string {
    if (!folder.children || folder.children.length === 0) {
      return "0KB";
    }
    
    // Calculate total size in bytes (using simple estimation since we have formatted strings)
    let totalSize = 0;
    const collectSizes = (items: FileItem[]) => {
      items.forEach(item => {
        if (item.type === 'file' || item.type === 'image') {
          // Convert size string to bytes (approximate)
          if (item.size) {
            const size = parseFloat(item.size);
            if (item.size.includes('MB')) {
              totalSize += size * 1024 * 1024;
            } else if (item.size.includes('KB')) {
              totalSize += size * 1024;
            } else {
              totalSize += size;
            }
          }
        } else if (item.type === 'folder' && item.children) {
          collectSizes(item.children);
        }
      });
    };
    
    collectSizes(folder.children);
    
    // Format the size back to a readable string
    if (totalSize > 1024 * 1024) {
      return `${(totalSize / (1024 * 1024)).toFixed(1)}MB`;
    } else if (totalSize > 1024) {
      return `${(totalSize / 1024).toFixed(1)}KB`;
    } else {
      return `${totalSize}B`;
    }
  }
}
