<div fxLayout="column">
  <input #searchFolder
         class="search-folder"
         autocomplete="off"
         placeholder="Search for folder"
         (keyup)="handleSearchFolder($event, searchFolder.value)">
  <div class="folders-list-container">
    <div fxflexfill
         fxlayoutgap="0px grid"
         [ngClass]="'predefined-folders'">
      <button *ngFor="let folder of predefinedFolders"
              mat-button
              [class]="getButtonClassName(folder.label)"
              [ngClass]="{ 'selected-folder': selectedFolder === folder.folderId, 'folder-boxed-button': true }"
              (click)="
          handleSelectFolder(null, { folderId: folder.folderId, folderName: folder.label }); toggleCustomFolders = false
        ">
        <mat-icon class="material-icons">
          folder
        </mat-icon>
        {{ folder.label }}
        <mat-icon *ngIf="selectedFolder === folder.folderId"
                  class="check-icon">
          check
        </mat-icon>
      </button>
    </div>
    <div fxLayout="column">
      <app-custom-folders fxFill="100"

                          [casename]="casename"
                          [currentFolder]="currentFolder"
                          [filter]="customFolderFilter"
                          [selectedFolder]="selectedFolder"
                          [uid]="uid"

                          (createAndSelect)="handleCreateAndSelect($event)"
                          (selectFolder)="handleSelectFolder(null, $event)">
      </app-custom-folders>
    </div>
  </div>
</div>
