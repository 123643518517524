import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-files-navigator',
  templateUrl: './files-navigator.component.html',
  styleUrls: ['./files-navigator.component.scss'],
})
export class FilesNavigatorComponent implements OnInit {
  @Input() data: any;
  @Input() childrenData: any;
  @Output() loadFolderData: EventEmitter<any> = new EventEmitter();
  expandedIndex: number = -1;
  handleLoadFolderData(_t6: any) {
    this.loadFolderData.emit(_t6);
  }
  constructor() {}

  ngOnInit(): void {
    console.log(this.data);
  }
}
