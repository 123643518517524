import { Component, Inject, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { LogLevelsDictionary } from 'src/app/dictionaries/LogLevels';
import { UserRoles } from 'src/app/dictionaries/UserRoles';
import { LogService } from 'src/app/log.service';

import { DialogService } from 'src/app/dialog.service';
import { ClientMatterService } from 'src/app/services/client-matter.service';
import { MailerService } from 'src/app/services/mailer.service';
import { UIMessagingService } from 'src/app/services/uimessaging.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { SearchService } from '../../services/search.service';
import { WindowsManagerService } from '../../services/windows-manager.service';
import { ConfirmationDialogComponent } from '../ui/confirmation-dialog/confirmation-dialog.component';
import { DialogData } from '../ui/optionChooser/optionChooser.component';
import { EditClientFormComponent } from './../../edit-client-form/edit-client-form.component';
import { ClientProfileComponent } from './../clientprofile/clientprofile.component';

const dbPatients = firebase.firestore().collection('patients');
const allowedUsers = [UserRoles.admin, UserRoles.associate, UserRoles.owner, UserRoles.superuser, UserRoles.consultant];

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-search',
  styleUrls: ['./search.component.scss'],
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnInit {
  selectedOwner: any;
  ownerNeeded = false;
  nameSearch: string;
  searchResult = [];
  patientIdSearch: string;
  caseName: string;
  fileName: string;
  fileID: string;
  fileUploadUser: string;
  currentUser: firebase.User;
  data: any;
  userData: { realRole?: string; user?: any; filesSharedWith?: any; caseName?: string; uid?: string };
  UserRoles: { owner: string; admin: string; associate: string; consultant: string; superuser: string };
  ownerID: any;
  originalData: any[];
  savedData: any[];
  ableToEditClient = false;
  ableToDeleteClient = false;
  clientsWithNotifications: any[];
  owners = [
    { value: 'owner-0', label: 'Owner 1' },
    { value: 'owner-1', label: 'Owner 2' },
    { value: 'owner-2', label: 'Owner 3' },
    { value: 'owner-3', label: 'Owner 4' },
    { value: 'owner-4', label: 'Owner 5' },
  ];
  allowedCases: any[];

  constructor(
    public search_$: SearchService,
    private mailer_$: MailerService,
    public router: Router,
    public ng: NgZone,
    private dialog_$: DialogService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SearchComponent>,
    private log_$: LogService,
    public auth_$: AuthService,
    private uiMessaging_$: UIMessagingService,
    private clientMatter_$: ClientMatterService,
    private windowsManager_$: WindowsManagerService,
    @Inject(MAT_DIALOG_DATA) public dialogdata: DialogData,
  ) {
    this.ableToDeleteClient = this.auth_$.userData.getValue()['realRole'] === UserRoles.owner;
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };
    this.data = [];
    this.nameSearch = '';
    this.patientIdSearch = '';
    this.caseName = '';
    this.fileName = '';
    this.fileID = '';
    this.fileUploadUser = '';
    this.ownerID = this.dialogdata['ownerID'];
  }

  isOwner() {
    return this.auth_$.userData.getValue()['realRole'] === UserRoles.owner;
  }

  async ngOnInit() {
    this.auth_$.user.subscribe({ next: u => (this.currentUser = u) });

    this.auth_$.userData.subscribe({
      next: async userdata => {
        if (Object.keys(userdata).length > 0) {
          this.userData = userdata;
          const { realRole, user, filesSharedWith } = this.userData;

          if ([UserRoles.admin, UserRoles.owner, UserRoles.associate].includes(realRole)) {
            this.ableToEditClient = true;
          }

          // If user role is consultant, we need to get the full list of owners
          const isConsultant = realRole === UserRoles.consultant;

          if (isConsultant) {
            this.ownerNeeded = false;
            const allowedPatients = await this.getAllowedPatients(realRole, filesSharedWith, this.ownerID);
            this.allowedCases = Array.from(new Set(allowedPatients));
            this.getAllPatients(true, realRole.toString(), user.email.toLowerCase(), this.allowedCases);
          }
          // Else we can get the list of patients directly
          else {
            this.getAllPatientsByOwnerID(this.ownerID);
          }
        }
      },
      complete: () => console.log('completed'),
    });

    this.search_$.searchResults.subscribe({
      next: results => this.populateSearchResults(results),
      error: err => console.error(err),
      complete: () => console.log('Completed search', this.searchResult),
    });
  }

  private getAllPatientsByOwnerID(ownerID: string) {
    return dbPatients
      .where('ownerID', '==', ownerID)
      .where('forDelete', '==', false)
      .onSnapshot(snapshot => {
        this.originalData = snapshot.docs.map(doc => this.buildClientObject2(doc.data(), doc.id));
        this.searchResult = this.originalData;
      });
  }

  private async getOwnersData(): Promise<{ value: string; label: string }[]> {
    const { owners } = <{ owners: string[] }>this.auth_$.userData.getValue();
    const result = await Promise.all(
      owners.map(async owner => ({
        value: owner,
        label: await this.auth_$.getOwnerName(owner),
      })),
    );
    return result;
  }

  handleSelectClient(item) {
    const dialog = this.dialog_$.open(ClientProfileComponent, <MatDialogConfig>{
      width: '95%',
      minWidth: '95%',
      maxWidth: '95%',
      height: '90%',
      id: 'client-profile',
      data: {
        patient: {
          client: item.caseName,
          caseName: item.caseName,
          clientUser: item.clientUser,
          defaultFolders: item.defaultFolders,
          clientname: `${item.FirstName} ${item.LastName}`,
          FirstName: item.FirstName,
          LastName: item.LastName,
          ownerPlanCode: this.dialogdata['ownerPlanCode'],
          patientDocId: item.patientDocId,
          clioMatterId: item.clioMatterId || item.clioDocId,
          restrictedProfiles: item.restrictedProfiles,
          allowedProfiles: item.allowedProfiles,
          ownerID: item.ownerID,
        },
      },
    });

    dialog.afterOpened().subscribe(() => {
      this.dialogRef.close();
    });
  }

  editClient(item: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = item;

    const dialogRef = this.dialog_$.open(EditClientFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => (result === 1 ? this.ngOnInit() : false));
  }

  deleteClient(item: any) {
    const dialogConfig = new MatDialogConfig();
    const nameToDisplay = item.FirstName ? `${item.FirstName} ${item.LastName}` : item.name || item.caseName;

    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      ...item,
      title: 'Delete Client',
      message: `Are you sure you want to delete ${nameToDisplay}?`,
      btnOkText: 'Delete',
      btnCancelText: 'Cancel',
      options: null,
    };

    const dialogRef = this.dialog_$.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('🚀 ~ SearchComponent ~ dialogRef.afterClosed ~ result:', result);
      const { ok } = result;
      if (!ok) {
        return;
      }
      this.deleteClientConfirmed(item);
      result === 1 ? this.ngOnInit() : false;
    });
  }

  deleteClientConfirmed(item: any) {
    console.log('🚀 ~ SearchComponent ~ deleteClientConfirmed ~ item', item);
    this.clientMatter_$.markClientMatterForDelete(item.caseName).then(() => {
      console.log(
        '🚀 ~ SearchComponent ~ this.clientMatter_$.markClientMatterForDelete ~ item.caseName:',
        item.caseName,
      );
      this.uiMessaging_$.toastMessage('Client marked for deletion', 'INFO');
      this.originalData = this.originalData.filter(client => client.caseName !== item.caseName);
      this.searchResult = this.searchResult.filter(client => client.caseName !== item.caseName);
      this.mailer_$.sendClientDeletionEmail(item.caseName, this.auth_$.userData.getValue()['email']);
    });
  }

  getOwnerIdByRole(role): string {
    return role === UserRoles.owner ? this.currentUser.uid : this.auth_$.userData.getValue()['ownerID'];
  }

  private async filterAllowedPatientsByOwnerID(allowedPatients: string[], ownerID: string) {
    const patients = [];

    for (const patient of allowedPatients) {
      const res = await this.patientBelongsToOwner(patient, ownerID);
      if (res) patients.push(patient);
    }

    return patients;
  }

  private patientBelongsToOwner(patient, ownerID) {
    return dbPatients
      .where('caseName', '==', patient)
      .where('forDelete', '==', false)
      .where('ownerID', '==', ownerID)
      .limit(1)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.empty) {
          return false;
        } else {
          return true;
        }
      });
  }

  async getAllowedPatients(realRole: string, filesSharedWith: any, ownerID: string) {
    if (!filesSharedWith) {
      return [];
    }
    console.log('ownerID: ', ownerID);
    let allowedPatients = [];

    switch (realRole) {
      case UserRoles.consultant:
        console.log('filesSharedWith: ', filesSharedWith);
        allowedPatients = [...new Set(filesSharedWith.map(currentvalue => currentvalue.patientCaseName))];
        allowedPatients = await this.filterAllowedPatientsByOwnerID(allowedPatients, ownerID);
        break;
      case UserRoles.owner:
      case UserRoles.associate:
      case UserRoles.admin:
        const casesOwneredDocs = (
          await dbPatients.where('owners', 'array-contains', ownerID).where('forDelete', '==', false).get()
        ).docs;
        casesOwneredDocs.forEach(item => allowedPatients.push(item.data().caseName));
        break;
      default:
        break;
    }
    return allowedPatients;
  }

  refreshCurrentUserSharedFiles() {
    return this.auth_$.userReady(this.auth_$.userData.getValue()['user'], 'refreshCurrentUserSharedFiles');
  }

  async getFilesSharedWith() {
    await this.refreshCurrentUserSharedFiles();
    const dbUser = this.auth_$.userData.getValue();
    const filesSharedWith = dbUser['filesSharedWith'];
    const realRole = dbUser['role'];
    const userEmail = dbUser['email'].toLowerCase();
    const allowedPatients = await this.getAllowedPatients(realRole, filesSharedWith, this.ownerID);
    const isConsultant = realRole === UserRoles.consultant;
    this.getAllPatients(isConsultant, realRole, userEmail, allowedPatients);
  }

  matchSearchCriteria(item, text) {
    const criterias = ['FirstName', 'LastName', 'caseName', 'LegalCaseId', 'DateOfBirth'];
    for (const criteria of criterias) {
      if (item[criteria] && item[criteria].toLowerCase().indexOf(text.toLowerCase()) > -1) {
        return true;
      }
    }
    return false;
  }

  search(event) {
    this.searchResult = this.originalData;
    if (event.target.value && event.target.value.length > 0)
      this.searchResult = this.searchResult.filter(item => this.matchSearchCriteria(item, event.target.value));
  }

  searchRefine() {
    if (this.patientIdSearch.trim() !== '')
      this.searchResult = this.searchResult.filter(data => data['PatientId'] === this.patientIdSearch);
    if (this.nameSearch.trim() !== '')
      this.searchResult = this.searchResult.filter(
        data => data['LastName'].toLowerCase() === this.nameSearch.toLowerCase(),
      );
  }

  routeToProfile(patient) {
    this.log_$.storeLog(
      this.auth_$.userData.getValue(),
      LogLevelsDictionary.info,
      { message: 'A user has visited a client profile.', user: this.userData.user.uid },
      'ViewClientProfile',
      patient.caseName,
    );

    if (!patient.ownerID) {
      this.uiMessaging_$.toastMessage(
        `This profile does not have a valid ownerID, please contact support before retry.`,
        'ALERT',
      );
      return;
    }

    if (!this.windowsManager_$.clientProfileOpened) {
      this.windowsManager_$.profileDialogRef = this.dialog_$.open(ClientProfileComponent, {
        width: '100vw',
        data: { patient },
      });
    }
  }

  handleBackToClio(clioMatterId, event) {
    event.preventDefault();
    event.stopPropagation();
    window.open(`https://app.clio.com/matters/${clioMatterId}`, '_self');
  }

  async getInitialData(role) {
    const allPatientsOfThisOwner = await dbPatients.where('ownerID', '==', this.ownerID).get();
    return allPatientsOfThisOwner.docs
      .map(doc => this.buildClientObject2(doc.data(), doc.id))
      .sort((a, b) => (a.hasNotifications < b.hasNotifications ? 1 : -1));
  }

  private buildClientObject2(patient, docid) {
    return {
      caseName: patient.caseName,
      origin: patient.origin,
      DateOfBirth: patient.DateOfBirth,
      defaultFolders: patient.defaultFolders,
      FirstName: this.toCapitalCase(patient.FirstName),
      LastName: this.toCapitalCase(patient.LastName),
      clioMatterId: patient.lpmFields?.matter.id,
      name: patient.name,
      clientUser: patient.clientUser,
      LegalCaseId: patient.LegalCaseId,
      ownerID: patient.ownerID,
      owners: [patient.ownerID],
      patientDocId: docid,
      sharedUserFiles: patient.sharedUserFiles,
      hasNotifications: this.hasNotifications(patient.caseName),
      restrictedProfiles: patient.restrictedProfiles,
      allowedProfiles: patient.allowedProfiles,
    };
  }

  buildClientObject(patient) {
    return {
      FirstName: this.toCapitalCase(patient.FirstName),
      LastName: this.toCapitalCase(patient.LastName),
      caseName: patient.caseName,
      LegalCaseId: patient.LegalCaseId,
      DateOfBirth: patient.DateOfBirth,
      sharedUserFiles: patient.files,
      ownerID: patient.ownerID,
      owners: [patient.ownerID],
      defaultFolders: patient.defaultFolders,
      restrictedProfiles: patient.restrictedProfiles,
      allowedProfiles: patient.allowedProfiles,
      hasNotifications: this.hasNotifications(patient.caseName),
    };
  }

  async getAllPatients(isConsultant: boolean, realRole: string, email: string, allowedCaseNames?: String[]) {
    const noCases = allowedCaseNames.length === 0;

    if (!isConsultant && noCases) {
      console.log('No cases found');
      this.searchResult = [];
      return;
    }

    const chunkSize = 10;

    const roles = [UserRoles.admin, UserRoles.associate, UserRoles.associate];
    if (roles.includes(realRole)) {
      this.searchResult = this.originalData = await this.getInitialData(UserRoles.consultant);
    } else if ([UserRoles.consultant].includes(realRole)) {
      const patients = (
        allowedCaseNames.length > chunkSize
          ? await this.getPatientsByChunk(allowedCaseNames, chunkSize)
          : await this.getPatients(allowedCaseNames)
      ).map(doc => doc.data());

      this.searchResult = patients.map(patient => this.getSearchResult(email, patient, realRole));
      console.log('this.searchResult: ', this.searchResult);
    }
    return;
  }

  getSearchResult(email: string, patientData: any, realrole: string) {
    const filtered = patientData.files?.filter((file: { sharedWith: string[] }) =>
      file.sharedWith.map((e: string) => e.toLowerCase()).includes(email.toLowerCase()),
    );
    const condition1 = filtered?.length > 0;
    const condition2 = allowedUsers.indexOf(realrole) > -1;
    const condition3 =
      patientData.uidOfCreator === this.currentUser.uid &&
      patientData.ownerID !== '' &&
      this.ownerID === patientData.ownerID;

    if (condition1 || condition2 || condition3) return this.buildClientObject(patientData);
  }

  toCapitalCase(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private async getPatients(allowedCaseNames: String[]) {
    if (!allowedCaseNames || allowedCaseNames.length === 0) {
      return [];
    }
    return (await dbPatients.where('caseName', 'in', allowedCaseNames).where('forDelete', '==', false).get()).docs;
  }

  private async getPatientsByChunk(allowedCaseNames, chunkSize) {
    let uArr = [];
    for (let i = 0; i < allowedCaseNames.length; i += chunkSize) {
      const chunk = allowedCaseNames.slice(i, i + chunkSize);
      const patients = await this.getPatients(chunk);
      uArr = [...uArr, ...patients];
    }
    return uArr;
  }

  private hasNotifications(caseName: string): boolean {
    const isConsultant = this.auth_$.userData.getValue()['realRole'] === UserRoles.consultant;
    if (environment.config.notifications === 0) return false;
    let clientsWithNotifications: any[] =
      this.auth_$.userData
        .getValue()
        ['notifications']?.filter(n => n.userId === this.auth_$.userData.getValue()['uid'] && n.caseId === caseName) ||
      [];

    if (isConsultant) clientsWithNotifications = clientsWithNotifications?.filter(n => n.type !== 'newfile');
    if (!clientsWithNotifications || clientsWithNotifications.length === 0) return false;
    return clientsWithNotifications.map((u: { caseId: string }) => u.caseId).indexOf(caseName) > -1;
  }

  private getItem(data) {
    const { FirstName, LastName, caseName, LegalCaseId, DateOfBirth, files, ownerID, defaultFolders } = data();
    return {
      FirstName: this.toCapitalCase(FirstName),
      LastName: this.toCapitalCase(LastName),
      caseName: caseName,
      LegalCaseId: LegalCaseId,
      DateOfBirth: DateOfBirth,
      sharedUserFiles: files,
      ownerID: ownerID,
      owners: [ownerID],
      defaultFolders: defaultFolders,
      hasNotifications: this.hasNotifications(caseName),
    };
  }

  private populateSearchResults(results) {
    this.searchResult = [];
    results.docs.forEach(({ data }) => {
      const item = this.getItem(data);
      const result = [];
      result.push(item);

      if (
        this.data.realRole.toString() === UserRoles.superuser ||
        (data().uidOfCreator === this.currentUser.uid && !item.ownerID)
      ) {
        this.searchResult.push(item);
      } else {
        console.log('No results found');
        this.searchResult = [];
      }
    });
    console.log('Search results', this.searchResult);
  }
}
