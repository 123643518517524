import { SessionService } from './../session.service';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogData } from '../edit-client-form/edit-client-form.component';


@Component({
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  emailDisabled = false;
  editUserForm: any;
  clientForm: FormGroup;
  sessionTimeOutState: boolean;

  constructor (
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditProfileComponent>,
    private session$: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.sessionTimeOutState = this.session$.getSessionTimeOutState();
    console.log('sessionTimeOutState :', this.sessionTimeOutState);
    this.clientForm = this.fb.group({
      name: new FormControl(this.data['userName'], [Validators.required, Validators.minLength(4)]),
      email: new FormControl({ value: this.data['userEmail'], disabled: true }, [Validators.email, Validators.required]),
      // sessionTimeOut: sessionTimeOutState
    });
  }

  async onSubmit() {
    if (this.clientForm.invalid) { return; }
    console.log(this.clientForm.value);
  }

  getErrorMessage(formControl, controlname: string, num?) {
    if (formControl.invalid) {
      if (formControl.errors.required) { return (controlname === 'usertype') ? `You need to select a user type` : `You need to provide a ${controlname}.`; }
      if (formControl.errors.minlength) { return `The field ${controlname} requires at least ${formControl.errors.requiredLength} characters length.`; }
      if (formControl.errors.email) { return `A valid email address is required.`; }
    } else { return; }
  }

  handleChange(ev) {
    if (ev.checked) {
      this.session$.enableSessionTimeOut();
    } else {
      this.session$.disableSessionTimeOut();
    }
  }
}
