import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-files-navigator-data',
  templateUrl: './files-navigator-data.component.html',
  styleUrls: ['./files-navigator-data.component.scss'],
})
export class FilesNavigatorDataComponent implements OnInit {
  handleLoadFolderData(_t3: any) {
    throw new Error('Method not implemented.');
  }
  @Input() data: any;
  constructor() {}

  ngOnInit(): void {
    console.log(this.data);
  }

  handleSelectionChange(event: any): void {
    console.log(event);
  }

  handleRowClick(event: any): void {
    console.log(event);
  }
}
