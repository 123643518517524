import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fn-folder-row',
  templateUrl: './fn-folder-row.component.html',
  styleUrls: ['./fn-folder-row.component.scss'],
})
export class FnFolderRowComponent implements OnInit {
  handleClick(event, folder) {
    event.stopPropagation();
    console.log(folder);
  }
  @Input() data: any;
  constructor() {}

  ngOnInit(): void {
    console.log(this.data);
  }
}
