<div *ngFor="let item of data">
  <ng-container *ngIf="item.type === 'folder'; else fileTemplate">
    <app-fn-folder-row [data]="item"></app-fn-folder-row>
  </ng-container>
  <ng-template #fileTemplate>
    <app-fn-file-row [file]="item"
                     [isSelected]="item.isSelected"
                     (rowClicked)="handleRowClick($event)"
                     (selectionChanged)="handleSelectionChange($event)">
    </app-fn-file-row>
  </ng-template>
</div>
