import { Component, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { MDCTopAppBar } from '@material/top-app-bar';
import firebase from 'firebase/app';
import { UserRoles } from 'src/app/dictionaries/UserRoles';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { environment } from 'src/environments/environment';

import { AuthService } from '../../services/auth.service';
import { UIMessagingService } from '../../services/uimessaging.service';
import { UploadHelperService } from '../../services/upload-helper.service';
import { CreateclientComponent } from '../createclient/createclient.component';
import { CreatepatientComponent } from '../createpatient/createpatient.component';
import { CreateuserComponent } from '../createuser/createuser.component';
import { ReportComponent } from '../report/report.component';
import { UpdateUserPasswordComponent } from '../update-user-password/update-user-password.component';
import { AuditLogViewerComponent } from './../../audit-log-viewer/audit-log-viewer.component';
import { EditProfileComponent } from './../../edit-profile/edit-profile.component';
import { RedirectionService } from './../../services/redirection.service';
import { SessionService } from './../../session.service';
import { TwoFactorAuthenticationService } from './../../two-factor-authentication.service';
import { AllusersComponent } from './../allusers/allusers.component';
import { SimpleMessageWindowComponent } from './../ui/simple-message-window/simple-message-window.component';
import { UpgradePlanComponent } from './../upgrade-plan/upgrade-plan.component';
import { ClientProfileComponent } from '../clientprofile/clientprofile.component';
import { ClientMatterService } from 'src/app/services/client-matter.service';
import { DialogService } from 'src/app/dialog.service';

const clientRole = 'client';
const DEFAULT_PLAN = 'fp';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  lpmClio = false;
  url: string;
  topAppBar: any;
  user: any;
  uid: any;
  userDBRole: any;
  userEmail: string;
  userName: string;
  realRole: string;
  userPermissions: any;
  createuser = false;
  searchComponentRef: any;
  uploadProgressMaximized: any;
  uploadProgressVisibility: boolean;
  filesSharedWith: any;
  maintenance: boolean;
  userData: any;
  public userEnabled = false;
  UserRoles: { owner: string; admin: string; associate: string; consultant: string; superuser: string; client: string };
  noLoginLink: boolean;
  loaded: boolean;
  rDialog: any;
  timeOut: NodeJS.Timeout;
  @ViewChild('drawer', { static: true }) drawer: MatDrawer;
  currentUrl: any;
  clioregistered: any;
  noUserData = true;
  activateMenuItems = true;
  ownerNeeded = false;
  owners: { value: string; label: string }[];
  checkCompleteClioRegistrationVal: any;
  planText = {
    free: 'Free',
    fp: 'Free',
    goldyearly: 'Gold Annual',
    gold: 'Gold Monthly',
    silveryearly: 'Silver Annual',
    silver: 'Silver Monthly',
    lpmp: 'Clio Platinum',
    lpmyearly: 'Clio Platinum Annual',
    platinum: 'Platinum Monthly',
    platinumyearly: 'Platinum Annual',
  };
  isSysAdmin: boolean;
  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.handleSessionTimeOut();
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.handleSessionTimeOut();
  }

  constructor(
    private dialog_$: DialogService,
    private uploadHelper_$: UploadHelperService,
    public router: Router,
    private clientMatter_$: ClientMatterService,
    public dialog: MatDialog,
    public auth_$: AuthService,
    private session_$: SessionService,
    private sessionStorage_$: SessionStorageService,
    private ngZone: NgZone,
    public redirection_$: RedirectionService,
    private twoFactorAuthentication_$: TwoFactorAuthenticationService,
    private uiMessaging_$: UIMessagingService,
  ) {
    this.isSysAdmin = environment.config.sysadmin;
    this.userData = { userDBRole: '' };
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      client: UserRoles.client,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.ngZone.run(() => {
          this.currentUrl = event.url;
        });
      }
    });
  }

  openSysAdmin() {
    this.router.navigate(['sysadmin']);
  }

  navigateBackToClio() {
    const originUrl = localStorage.getItem('origin_url');
    const baseUrl = 'https://app.clio.com/nc/#/';

    if (originUrl) {
      localStorage.removeItem('origin_url');
      window.location.href = `${baseUrl}${originUrl}`;
    } else {
      window.location.href = baseUrl;
    }
  }

  getSessionTimeOutState() {
    const sessionTimeOutState = sessionStorage.getItem('sessionTimeOutState') || 'true';
    return sessionTimeOutState === 'true';
  }

  handleSessionTimeOut() {
    const sessionTimeOutState = this.getSessionTimeOutState();
    if (!sessionTimeOutState) return false;
    clearTimeout(this.session_$.sessionTimeout);
    if (this.auth_$.uid) this.setSessionTimeOut();
  }

  setSessionTimeOut() {
    if (!this.getSessionTimeOutState()) {
      return false;
    }
    this.session_$.clearSessionTimeOutState();
    this.session_$.sessionTimeout = setTimeout(() => {
      this.session_$.handleTimeEnd(v => {
        switch (v) {
          case 'session_end':
            this.dialog.closeAll();
            this.session_$.clearSessionTimeOutState();
            this.session_$.clearSessionTimeout();
            this.auth_$.logout();
            break;
          case 'session_continue':
            this.dialog.closeAll();
            this.setSessionTimeOut();
            break;
          default:
            this.dialog.closeAll();
            this.setSessionTimeOut();
            break;
        }
      });
    }, environment.config.sessionTimeLimit);
  }

  validatePermission(permission: string): boolean {
    if (!this.userPermissions || this.userPermissions.length === 0) {
      return false;
    }

    return this.userPermissions
      .filter(p => p.role === this.auth_$.userData.getValue()['role'])
      .filter(p => p.object === permission)['access'];
  }

  syncMyData($event) {
    this.auth_$.syncMyData().then(() => {
      const message = 'Your data has been synced';
      const label = 'OK';
      this.uiMessaging_$.toastMessage(message, label);
    });
  }

  public goToLogin() {
    this.redirection_$.goToLogin();
  }

  private async getOwnersData(): Promise<{ value: string; label: string }[]> {
    const { owners } = <{ owners: string[] }>this.auth_$.userData.getValue();
    const result = await Promise.all(
      owners.map(async owner => ({
        value: owner,
        label: await this.auth_$.getOwnerName(owner),
      })),
    );
    return result;
  }

  ngOnInit(): void {
    this.checkCompleteClioRegistrationVal = this.checkCompleteClioRegistration();
    if (this.sessionStorage_$.getAddToClioStarted() === 'true') return;

    this.auth_$.drawer = this.drawer;
    this.loaded = false;

    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        if (ev.urlAfterRedirects === '/login') this.noLoginLink = true;
        if (ev.urlAfterRedirects === '/logout') this.auth_$.logout();
      }
    });

    // Update userData (currentPlan included) each time Auth.UserData is updated.
    this.auth_$.userData.subscribe(userData => {
      if (userData && Object.keys(userData).length > 0) {
        this.userData = userData;
      }
    });

    this.maintenance = environment.maintenance;

    // As soon as the user is signed in Firebase...
    this.auth_$.auth.onAuthStateChanged(user => {
      if (this.sessionStorage_$.getSSO() === '0') {
        return;
      }

      if (user && Object.keys(user).length > 0) {
        if (this.sessionStorage_$.getAddToClioStarted() !== 'true') {
          this.handleSessionTimeOut(); // User is signed in.
        }

        // Execute userReady to get the user data from the DB.
        this.auth_$.userReady(user, 'onAuthStateChanged').then(async () => {
          this.userEnabled = true;
          this.loaded = true;
          this.userData = this.auth_$.userData.getValue();

          if (this.userData.userDBRole === UserRoles.consultant) {
            this.owners = await this.getOwnersData();

            if (this.owners.length === 1) {
              this.ownerNeeded = false;
            } else if (this.owners.length > 1) {
              this.ownerNeeded = true;
            } else if (this.owners.length === 0) {
              this.ownerNeeded = true;

              this.uiMessaging_$.toastMessage(
                `No owners found for this user, please contact support before retry.`,
                'ALERT',
              );
            }
          }

          this.checkPasswordExpiration();

          // If the user comes from SSO there is no need to ask for 2FA.
          if (this.userData.lpm === 'clio' && !this.auth_$.isClioRegistered()) {
            console.log('The user is not registered with Clio yet. Do not check for 2FA.');
          } else {
            this.twoFactorAuthentication_$.handleTwoFactorSignIn(user.email);
          }
        });

        this.auth_$.searchComponent = true;
      } else {
        // No user is signed in.
        this.ngZone.run(() => {
          this.userEnabled = false;
          this.loaded = true;
          // NOTE: This was causing the user on ResetPassword flow lands on login page.
          // this.router.navigate(['/login']);
        });
        this.userData = null;
      }
    });

    // When we have USERDATA completed, we can execute actions with its data.
    const observer = this.auth_$.userData.subscribe({
      next: async userData => {
        if (Object.keys(userData).length === 0) {
          // no user data yet.
          this.ngZone.run(() => (this.noUserData = true));
          return;
        }

        /*********** TAKEN FROM USERREADY inside AFTEAUTHSTATECHANGED ********** */
        this.userEnabled = true;
        this.loaded = true;
        this.userData = this.auth_$.userData.getValue();
        this.checkPasswordExpiration();
        /********************* */

        // There is user data.

        if (!userData['permissions']) return;

        this.userPermissions = userData['permissions'];
        this.createuser = this.validatePermission('createuser');
        this.noUserData = false;
        this.clioregistered = userData['clioregistered'] || this.sessionStorage_$.getClioRegistered();

        if (userData['user']) {
          this.lpmClio = userData['lpm'] === 'clio' || false;
          this.userEnabled = true;
          this.loaded = true;

          const { clioregistered, lpm, testaccount, clioSSO } = this.userData;

          //
          if (this.lpmClio && !this.auth_$.isClioRegistered() && testaccount && clioSSO === 1)
            this.activateMenuItems = false;

          if (!this.auth_$.preventRedirections) {
            // Only open the drawer if the user is fully registered with Clio.
            if (clioregistered) {
              this.auth_$.drawer.open().then(() => observer.unsubscribe());
              const referer = decodeURIComponent(decodeURIComponent(this.sessionStorage_$.getReferer()));
              if (!referer || referer === 'null') {
                this.router.navigate(['/']);
                this.sessionStorage_$.cleanSSO();

                if (this.sessionStorage_$.getAddToClioStarted() !== 'true') {
                  await this.notAddToClioStarted();
                } else {
                  console.log('Add to Clio process started');
                }

                console.log('No referer found, redirecting to /');
              } else {
                const dataURL = [referer.split('?')[0], ...referer.split('?')[1].split('&')];
                await this.router.navigate([dataURL[0]], {
                  replaceUrl: true,
                  queryParams: dataURL.slice(1).reduce((acc, cur) => {
                    const [key, value] = cur.split('=');
                    acc[key] = value;
                    return acc;
                  }, {}),
                });
                console.log('Redirecting to ' + referer);
              }
            } else {
              const hasFPlan = this.auth_$.userData.getValue()['plancode'] === DEFAULT_PLAN;
              const notClioSSO = !userData['clioSSO'];
              const notTestAccount = !userData['testaccount'];
              const lpmClio = lpm === 'clio';
              const notClioRegistered = !this.auth_$.isClioRegistered();
              const notAddToClioStarted = this.sessionStorage_$.getAddToClioStarted() !== 'true';

              const conditions =
                notClioRegistered && lpmClio && notTestAccount && notClioSSO && hasFPlan && notAddToClioStarted;
              if (conditions) {
                this.redirection_$.redirectToCompleteClioRegistration();
                return;
              }
              observer.unsubscribe();
            }
          } else console.log('Prevent redirections is true');
        }
      },
      error: err => console.error('Something went wrong: ' + err),
    });

    this.uploadHelper_$.scannedBlocksSource.subscribe(
      obs => (this.uploadProgressVisibility = this.uploadHelper_$.getScannedUploadBlocksList().length > 0),
    );

    this.topAppBar = MDCTopAppBar.attachTo(document.getElementById('app-bar')).setScrollTarget(
      document.getElementById('app-bar'),
    );

    this.uploadHelper_$.uploadProgressMaximizedO.subscribe(val => (this.uploadProgressMaximized = val));
  }

  async openAuditLog() {
    if (!this.checkCompleteClioRegistrationVal) {
      this.redirection_$.redirectToCompleteClioRegistration();
      this.drawer.close();
      return;
    }

    const logs = await this.getLogsByUID(this.userData.user.uid);
    const dialogConfig = {
      width: '950px',
      data: { user: this.userData, logs: logs },
    };
    this.dialog_$.open(AuditLogViewerComponent, dialogConfig);
  }

  async getLogsByUID(uid: string) {
    this.auth_$.showLoader('Getting logs');
    const ownerLogs = (await firebase.firestore().collection('logs').where('uid', '==', uid).get()).docs.map(doc =>
      doc.data(),
    );
    const ownerChildrenLogs = (
      await firebase.firestore().collection('logs').where('owners', 'array-contains', uid).get()
    ).docs.map(doc => doc.data());
    this.auth_$.hideLoader();
    return ownerLogs.concat(ownerChildrenLogs);
  }

  validatePermissions(permission: string) {
    return this.auth_$.validatePermission(permission);
  }

  checkPasswordExpiration() {
    const { id, lpm, clioregistered } = <any>this.auth_$.userData.getValue();
    if (!id) {
      console.log('No user id found');
      this.auth_$.logout();
    }

    if (lpm === 'clio') {
      console.log('The user is not registered with Clio yet. Do not check for password expiration.');
    } else {
      if (lpm === 'clio' && clioregistered && this.sessionStorage_$.getSSO() !== '1') {
        this.auth_$.checkIfPasswordIsExpired(id).then(passwordExpired => {
          if (passwordExpired.data) {
            this.handleUpdateUserPasswordComponent();
          } else {
            console.log('The password has not expired yet.');
          }
        });
      }
    }
  }

  handleUpdateUserPasswordComponent() {
    this.dialog
      .open(UpdateUserPasswordComponent, { width: '400px', disableClose: true })
      .afterClosed()
      .subscribe({ next: passwordData => this.handlePasswordData(passwordData) });
  }

  handlePasswordData(passwordData) {
    const { newPassword, oldPassword } = passwordData;
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);
    user
      .reauthenticateWithCredential(credential)
      .then(async () => {
        await this.auth_$.updateUserPassword(newPassword);
        this.dialog_$.open(SimpleMessageWindowComponent, {
          width: '300px',
          data: { message: 'Your password has been updated', title: 'Password updated' },
        });
      })
      .catch(err => {
        const { code } = err;
        if (code === 'auth/wrong-password') {
          const message = 'Your current password is wrong, please try again';
          const label = null;
          this.uiMessaging_$.toastMessage(message, label);
          this.checkPasswordExpiration();
        }
      });
  }

  editProfile(event) {
    const currentUser = this.auth_$.currentUser;
    const dialogConfig = {
      width: '600px',
      data: this.userData,
    };
    const dialogRef = this.dialog_$.open(EditProfileComponent, dialogConfig);
  }

  openAllUsersComponent(role: string) {
    console.log('Open All Users Component');
    this.dialog_$.open(AllusersComponent, {
      width: '600px',
      data: { role },
    });
  }

  navigateToConsultant() {
    if (this.checkCompleteClioRegistrationVal) {
      this.router.navigate(['consultants']);
    } else {
      this.redirection_$.redirectToCompleteClioRegistration();
      this.drawer.close();
      return;
    }
    // this.openAllUsersComponent(UserRoles.consultant);
  }

  navigateToOwner() {
    if (this.checkCompleteClioRegistrationVal) {
      this.router.navigate(['owners']);
    } else {
      this.redirection_$.redirectToCompleteClioRegistration();
      this.drawer.close();
      return;
    }
    // this.openAllUsersComponent(UserRoles.owner);
  }

  navigateToAssociate() {
    if (this.checkCompleteClioRegistrationVal) {
      this.router.navigate(['associates']);
    } else {
      this.redirection_$.redirectToCompleteClioRegistration();
      this.drawer.close();
      return;
    }
    // this.openAllUsersComponent(UserRoles.associate);
  }

  navigateToAdmin() {
    if (this.checkCompleteClioRegistrationVal) {
      this.router.navigate(['admins']);
    }
    // this.openAllUsersComponent(UserRoles.admin);
  }

  navigateToClientUsers() {
    if (this.checkCompleteClioRegistrationVal) {
      this.router.navigate(['clients']);
    } else {
      this.redirection_$.redirectToCompleteClioRegistration();
      this.drawer.close();
    }
    // this.openAllUsersComponent(UserRoles.admin);
  }

  navigateToClient() {
    if (this.checkCompleteClioRegistrationVal) {
      this.router.navigate(['clientsmatters']);
    } else {
      this.redirection_$.redirectToCompleteClioRegistration();
      this.drawer.close();
      return;
    }
  }

  navigateToAttorneysLawFirms() {
    if (this.checkCompleteClioRegistrationVal) {
      this.router.navigate(['attorneys_lawfirms']);
    } else {
      this.redirection_$.redirectToCompleteClioRegistration();
      this.drawer.close();
      return;
    }
  }

  private async getCaseInfo() {
    const patientData = await this.clientMatter_$.getPatientData(this.userData.clientMatter);
    const { ownerID } = patientData;
    const ownerPlanCode = await this.auth_$.getOwnerPlanCode(ownerID);
    return { ...patientData, ownerPlanCode };
  }

  async openClient() {
    const data = {
      patient: await this.getCaseInfo(),
      user_id: this.userData.user.uid,
      targetFolder: '',
    };

    if (this.getRole() === UserRoles.client) data.targetFolder = 'Client Upload';

    this.dialog_$.open(ClientProfileComponent, { data, width: '100vw', id: 'client-profile' });
  }

  private getRole() {
    return this.auth_$.userData.value['role'];
  }

  navigateToCreateClient() {
    if (this.checkCompleteClioRegistrationVal) {
      this.router.navigate(['createclientmatter']);
    } else {
      this.redirection_$.redirectToCompleteClioRegistration();
      this.drawer.close();
      return;
    }
  }

  upgradeYourPlan() {
    const userDataValue = this.auth_$.userData.getValue();
    const userPlan = userDataValue['plancode'] || 'fp';
    const data = { title: 'Upgrade Plan', currentPlan: userPlan, message: '<p>Go and upgrade your current plan.</p>' };
    this.dialog_$.open(UpgradePlanComponent, {
      width: '90vw',
      maxWidth: '90vw',
      data: data,
    });
  }

  VideoConferencing() {
    if (this.checkCompleteClioRegistrationVal) {
      this.router.navigate(['meetings']);
      this.drawer.close();
    } else {
      this.redirection_$.redirectToCompleteClioRegistration();
      this.drawer.close();
      return;
    }
  }

  ConferenceList() {
    this.dialog_$.open(CreateuserComponent, {
      width: '680px',
      data: {
        userType: 'None',
        title: 'List of Conferences',
        button: 'blank',
        screen: 'empty',
      },
    });
  }

  async createUser(role: string) {
    if (!this.checkCompleteClioRegistrationVal) {
      this.redirection_$.redirectToCompleteClioRegistration();
      this.drawer.close();
      return;
    }

    let r_dialog: any;
    switch (role) {
      case 'consultant':
        this.dialog_$.open(CreateuserComponent, {
          width: '680px',
          data: {
            userType: 'Consultant',
            title: 'Create Consultant',
            button: 'Create',
            screen: 'full',
          },
        });
        break;
      case 'admin':
        r_dialog = this.dialog_$.open(CreateuserComponent, {
          width: '680px',
          data: {
            userType: 'Admin',
            title: 'Create Admin',
            button: 'Create',
            screen: 'full',
            limits: { limits: await this.checkPlanLimits() },
          },
        });
        break;
      case clientRole:
        r_dialog = this.dialog_$.open(CreatepatientComponent, {
          width: '680px',
          data: null,
        });
        break;
      case UserRoles.owner:
        r_dialog = null;
        if (this.userData.userDBRole === UserRoles.superuser) {
          this.navigateToCreateOwner();
        } else {
          const message = 'You are not authorized to access this page';
          const label = null;
          this.uiMessaging_$.toastMessage(message, label);
        }
        break;
      default:
        break;
    }
    const dialogRef = r_dialog;
  }

  navigateToCreateUser() {
    this.dialog_$.open(CreatepatientComponent, {
      width: '680px',
      data: null,
    });
  }

  navigateToCreateOwner() {
    this.dialog_$.open(CreateclientComponent, {
      width: '680px',
      data: null,
    });
  }

  navigateGenerateReport() {
    this.dialog_$.open(ReportComponent, {
      width: '70vw',
      data: this.uid,
    });
  }

  private async clioRegistered(subscription) {
    this.auth_$.drawer.open().then(() => subscription.unsubscribe());
    const referer = this.sessionStorage_$.getReferer();
    if (!referer) this.noReferer();
    else await this.withReferer(referer);
  }

  private async noPreventRedirections(clioregistered, userData, subscription, lpm) {
    // Only open the drawer if the user is fully registered with Clio.
    if (clioregistered) await this.clioRegistered(subscription);
    else this.noClioRegistered(userData, subscription, lpm);
  }

  private noClioRegistered(userData, subscription, lpm) {
    const isClioNotRegistered = !this.auth_$.isClioRegistered();
    const isLpmClio = lpm === 'clio';
    const isNotTestAccount = !userData['testaccount'];
    const isNotClioSSO = !userData['clioSSO'];
    const isPlanCodeFP = this.auth_$.userData.getValue()['plancode'] === DEFAULT_PLAN;
    const isAddToClioNotStarted = this.sessionStorage_$.getAddToClioStarted() !== 'true';

    if (isClioNotRegistered && isLpmClio && isNotTestAccount && isNotClioSSO && isPlanCodeFP && isAddToClioNotStarted) {
      this.redirection_$.redirectToCompleteClioRegistration();
      return;
    }

    subscription.unsubscribe();
  }

  private async thereIsUserDataUser(userData, subscription) {
    this.lpmClio = userData['lpm'] === 'clio' || false;
    this.userEnabled = true;
    this.loaded = true;

    const { clioregistered, lpm, testaccount, clioSSO } = this.userData;

    //
    if (this.lpmClio && !this.auth_$.isClioRegistered() && testaccount && clioSSO === 1) this.activateMenuItems = false;

    if (!this.auth_$.preventRedirections) await this.noPreventRedirections(clioregistered, userData, subscription, lpm);
    else console.log('Prevent redirections is true');
  }

  private noReferer() {
    if (this.checkCompleteClioRegistrationVal) {
      this.router.navigate(['/']);
      this.sessionStorage_$.cleanSSO();
      if (this.sessionStorage_$.getAddToClioStarted() !== 'true')
        this.auth_$
          .updateClioCustomActions(this.auth_$.userData.getValue()['uid'], 'header.component.ts > ln 611 > noReferer')
          .catch(err => console.log('Clio custom actions update error', err));
      else console.log('Add to Clio process started');
      console.log('No referer found, redirecting to /');
    } else {
      this.redirection_$.redirectToCompleteClioRegistration();
      this.drawer.close();
      return;
    }
  }

  private async withReferer(referer) {
    const splitted1 = referer.split('?');
    const dataURL = [splitted1[0], ...splitted1[1]?.split('&')];
    let extras;

    if (splitted1.length > 1)
      extras = {
        replaceUrl: true,
        queryParams: dataURL.slice(1).reduce((acc, cur) => {
          const [key, value] = cur.split('=');
          acc[key] = value;
          return acc;
        }),
      };
    else extras = { replaceUrl: true };

    await this.router.navigate([dataURL[0]], extras);
    console.log('Redirecting to ' + referer);
  }

  hasNotifications() {
    if (environment.config.notifications === 0) {
      return false;
    }
    return this.auth_$.hasNotifications();
  }

  private async clioAccessTokenExists() {
    const clioAccessToken = await this.getClioAccessToken().catch(err => {
      // FIXME: Report error to Raygun
      // TASK: Report error to Raygun
      console.log('Error getting Clio Access token', err);

      // Reporting with Raygun
      if (environment.config.raygun) {
        const rg4js = require('raygun4js');
        rg4js('withCustomData', {
          error: err,
          tags: ['header.component.ts', 'clioAccessTokenExists', 'getClioAccessToken', 'auth.service.ts'],
          message: 'Error getting Clio access token.',
        });
      }

      return false;
    });
    return clioAccessToken;
  }

  private getClioAccessToken() {
    return this.auth_$.getClioAccessToken();
  }

  private async notAddToClioStarted() {
    if (await this.clioAccessTokenExists()) {
      this.auth_$
        .updateClioCustomActions(this.auth_$.userData.getValue()['uid'], 'header.component.ts > ln 276')
        .catch(err => console.log('Clio custom actions update error', err));
    } else {
      console.log('No Clio access token found');
    }
  }

  isNotSilver() {
    return this.auth_$.isNotSilverPlan();
  }

  roleCheck(n: number) {
    const userrole = this.userData.userDBRole;
    switch (n) {
      case 1:
        return ![UserRoles.superuser, UserRoles.consultant, UserRoles.client].includes(userrole);
      case 2:
        return [UserRoles.owner, UserRoles.associate].includes(userrole);
      case 3:
        return ![UserRoles.superuser, UserRoles.consultant, UserRoles.client, UserRoles.admin].includes(userrole);
      case 4:
        return ![
          UserRoles.admin,
          UserRoles.consultant,
          UserRoles.superuser,
          UserRoles.client,
          UserRoles.associate,
        ].includes(userrole);
      case 5:
        return userrole === UserRoles.owner;
      case 6:
        return userrole !== UserRoles.consultant;
      case 7:
        return ![UserRoles.superuser, UserRoles.consultant, UserRoles.client, UserRoles.associate].includes(userrole);
      default:
        return false;
    }
  }

  async checkPlanLimits() {
    const [limits, users] = await Promise.all([
      this.getPlanLimits(this.auth_$.userData.value['plancode']),
      this.getUsersCount(),
    ]);
    return { limits, users };
  }

  private getUsersCount() {
    return this.auth_$.getUsersCount();
  }

  private getPlanLimits(planCode: string) {
    return this.auth_$.getPlanLimits(planCode).then(limits => {
      return limits;
    });
  }

  checkCompleteClioRegistration() {
    const { lpm, clioregistered } = <{ lpm: string; clioregistered: boolean }>this.auth_$.userData.getValue();
    if (lpm === 'clio' && !clioregistered) {
      return;
    } else {
      return true;
    }
  }

  navigateToCreateClientMatter() {
    this.router.navigate(['createclientsmatters']);
  }

  navigateToCreateAdminUser() {
    this.router.navigate(['createadminuser']);
  }
}
