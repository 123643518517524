<!-- // NOTE: Compare with PROD-download-disc branch. -->


<!-- Open Files Comparison Section -->
<section>
  <h2>
    Open Files Comparison
  </h2>
  <div fxLayout="row wrap"
       fxLayoutAlign="center center"
       fxLayoutGap="20px">
    <input #caseName
           name="caseName"
           type="text">
    <button color="primary"
            mat-flat-button
            (click)="compareOpenFiles(caseName.value)">
      Compare Open Files
    </button>
    <button color="warn"
            mat-flat-button
            (click)="compareOpenFileTransfer(caseName.value)">
      Open FileTransfer
    </button>
    <button color="warn"
            mat-flat-button
            (click)="compareOpenFileTransferV2(caseName.value)">
      Open FileTransferV2
    </button>
  </div>
</section>

<div fxLayout="column"
     fxLayoutAlign="center center"
     fxLayoutGap="20px">
  <button color="primary"
          mat-button
          (click)="handleListClioUsers()">
    List users from Clio with wrong Clio Access Token
  </button>
  <!-- <button (click)="cleanNotificationsIWithoutNodeId()">Delete Notifications with no noteId</button> -->
  <!-- <button (click)="cleanNotificationsOfDeletedFiles()">Delete Notifications of Files Deleted</button> -->

  <section>
    <h2>
      Duplicated folders
    </h2>
    <input #caseName
           name="caseName"
           placeholder="Case Name"
           type="text">

    <div *ngIf="caseName.value"
         fxLayout="row wrap"
         fxLayoutAlign="center center"
         fxLayoutGap="30px">
      <button color="accent"
              mat-flat-button
              (click)="clearDuplicatedFoldersByCaseName('Clio', caseName.value)">
        CLIO
      </button>
      <button color="accent"
              mat-flat-button
              (click)="clearDuplicatedFoldersByCaseName('Discovery', caseName.value)">
        DISCOVERY
      </button>
      <button color="accent"
              mat-flat-button
              (click)="clearDuplicatedFoldersByCaseName('Client Upload', caseName.value)">
        CLIENT UPLOAD
      </button>
      <button color="accent"
              mat-flat-button
              (click)="clearDuplicatedFoldersByCaseName('Other', caseName.value)">
        OTHER
      </button>
      <button color="accent"
              mat-flat-button
              (click)="clearDuplicatedFoldersByCaseName('Damages', caseName.value)">
        DAMAGES
      </button>
      <button color="accent"
              mat-flat-button
              (click)="clearDuplicatedFoldersByCaseName('Liability', caseName.value)">
        LIABILITY
      </button>
    </div>
    <button color="accent"
            mat-flat-button
            (click)="deleteNotifications('newfile', 'Consultant')">
      DELETE NEWFILE NOTIFICATIONS for Consultants
    </button>
  </section>

  <div fxLayout="row wrap"
       fxLayoutAlign="center center"
       fxLayoutGap="30px">
    <!-- CREATE A BUTTON TO RUN listOwners() on click -->
    <button color="accent"
            mat-flat-button
            (click)="listOwners()">
      List Owners
    </button>
  </div>

  <!-- Show a list of the owners, scrolled with a maximum height -->
  <div fxLayout="column wrap"
       fxLayoutAlign="center center"
       fxLayoutGap="30px">
    <input placeholder="Filter owners"
           type="text"
           [(ngModel)]="filterText">

    <mat-list style="max-height: 300px; overflow: auto"
              fxLayout="column"
              fxLayoutAlign="center center">
      <mat-list-item *ngFor="let owner of filteredOwnersList">
        <h3 matLine>
          {{ owner.firstName }} {{ owner.lastName }} ({{ owner.name }})
        </h3>
        <!-- email -->
        <p matLine>
          {{owner.email}}
        </p>
        <!-- button Get Storage Size -->
        <button color="primary"
                mat-button
                (click)="getStorageSizeByOwnerId(owner.uid)">
          Get Storage Size
        </button>
        <!-- button Get Reported Size -->
        <button color="primary"
                mat-button
                (click)="getReportedSizeByOwnerId(owner.uid)">
          Get Reported Size
        </button>
      </mat-list-item>
    </mat-list>
    <!-- <button (click)="userEmailsToLowerCase()">make al users email to lowercase</button> -->
    <!-- <button (click)="seeAllUsersEmails()">See all users emails</button> -->
    <!-- <button (click)="listSuperUsers()">list superusers</button> -->
    <!-- <button (click)="listViewerURLs()">Check vieweruls</button> -->
    <!-- <button (click)="updateFolderColors()">Update folder colors</button> -->
    <!-- <button (click)="allUsersEmailToLowerCase()">allUsersEmailToLowerCase</button> -->
    <!-- <button mat-button (click)="updateFilesSharedUsers()">Update Files Shared Users</button> -->
    <!-- <button mat-flat-button color="warn" (click)="showSpecificFile('MR BRAIN NECK WO IV CONTRAST STROKE')">
      Show specific file
    </button> -->
    <!-- <button mat-flat-button color="warn" (click)="showUserInfo('dmayerlen1+mayer@gmail.com')">Show user info</button> -->
    <!-- <button mat-flat-button color="warn" (click)="updateFilesSharedUsers()">Update Files Shared Users</button> -->
    <!-- <button mat-flat-button color="warn" (click)="removeSharingReferencesOfFilesDeletedInUsers()">
      Fixing the sharing between users and file
    </button> -->
    <!-- <button mat-flat-button color="warn" (click)="checkingData()"> -->
    <!-- <button mat-flat-button color="warn" (click)="removeSharingReferencesOfFilesDeleted()"> -->
    <!-- <button
      mat-flat-button
      color="warn"
      (click)="removeSharingOfUsersDeleted()">
      removeSharingOfUsersDeleted
    </button> -->
    <!-- <button mat-flat-button color="warn" (click)="usersFilesSharingFixing()"> -->
  </div>

  <!-- Notifications Section -->
  <section>
    <h2>
      Notifications
    </h2>
    <div fxLayout="row wrap"
         fxLayoutAlign="center center"
         fxLayoutGap="20px">
      <input #fileId
             name="fileId"
             type="text">
      <button (click)="deleteNotificationsByFileId($event, fileId)">
        Delete Notifications by FileId
      </button>
      <input #fileId
             name="fileId"
             type="text">
      <button (click)="deleteNotificationsByFileId($event, fileId)">
        Delete Notifications by FileId
      </button>
    </div>
  </section>

  <!-- Storage Size Section -->
  <section>
    <h2>
      Storage Size
    </h2>
    <div fxLayout="row wrap"
         fxLayoutAlign="center center"
         fxLayoutGap="20px">
      <input #caseName
             #fileId
             name="caseName"
             type="text">
      <button (click)="getStorageSizeByCaseName(caseName.value)">
        Get Storage By Case Name
      </button>
      <button (click)="getHealthcareSizeByCaseName(caseName.value)">
        Get HealthCare Storage By Case Name
      </button>
    </div>
  </section>

  <!-- Clio Custom Action Section -->
  <section>
    <h2>
      Clio Custom Action
    </h2>
    <div fxLayout="row wrap"
         fxLayoutAlign="center center"
         fxLayoutGap="20px">
      <textarea #clioAction
                cols="50"
                name="clioAction"
                placeholder="Enter Clio Action"
                rows="10"
                style="font-family: monospace;"></textarea>
      <button color="primary"
              mat-flat-button
              (click)="addClioCustomAction(clioAction.value)">
        Perform Clio Custom Action
      </button>
    </div>
  </section>

  <!-- Users Section -->
  <section>
    <h2>
      Users
    </h2>
    <mat-radio-group [(ngModel)]="roleselected"
                     (ngModelChange)="onRoleSelectedChange()">
      <mat-radio-button *ngFor="let item of roles"
                        [value]="item.value">
        {{item.label}}
      </mat-radio-button>
    </mat-radio-group>
    <!-- mat-button -->
    <button color="accent"
            mat-flat-button
            (click)="listUsersByRole()">
      List User Roles by Role
    </button>
    // Show usersList inside a mat-table
    <mat-table [dataSource]="dataTableSource">
      <!-- Define the columns to be displayed -->
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef>
          Email
        </mat-header-cell>
        <mat-cell *matCellDef="let user">
          {{user.email}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>
          Name
        </mat-header-cell>
        <mat-cell *matCellDef="let user">
          {{user.name}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="recurlyaccountcode">
        <mat-header-cell *matHeaderCellDef>
          Recurly Account Code
        </mat-header-cell>
        <mat-cell *matCellDef="let user">
          {{user.recurlyaccountcode}}
        </mat-cell>
      </ng-container>

      <!-- Define the header row -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <!-- Define the data rows -->
      <mat-row *matRowDef="let user; columns: displayedColumns"></mat-row>
    </mat-table>
  </section>
</div>
