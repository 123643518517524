import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase';

import { environment } from '../../environments/environment';
import { ClioAccessTokenDialogComponent } from '../components/clio/clio-access-token-dialog/clio-access-token-dialog.component';
import { DialogService } from '../dialog.service';
import { AuthService } from './auth.service';
import { SessionStorageService } from './session-storage.service';
import { SimpleMessageWindowComponent } from '../components/ui/simple-message-window/simple-message-window.component';

// import { Storage } from '@google-cloud/storage';

interface ClioDocument {
  id: string;
  name: string;
  content_type: string;
  url: string;
}

interface GetClioFolderContentsOptions {
  folderId: string;
  folderPath: string;
  caseName: string;
  parentFolderId: string;
  parentFolderName: string;
  email?: string;
}

interface ClioFolder {
  id: string;
  name: string;
  parent_id?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClioService {
  private clioApiUrl = 'https://app.clio.com/api/v4';

  constructor(
    private dialog_$: DialogService,
    private http: HttpClient,
    private sessionStorage_$: SessionStorageService,
  ) {}

  handleClioGetAccessToken(dialog: MatDialog, auth_$: AuthService, redirectToClioAuthorize: () => void) {
    console.log('ClioService handleClioGetAccessToken');
    dialog
      .open(ClioAccessTokenDialogComponent, {
        width: '500px',
        data: {
          origin: 'updateDefaultCalendar',
          message: 'Your default calendar now is CLIO, but you need to Authorize its usage first.',
        },
      })
      .afterClosed()
      .subscribe(origin => {
        if (origin === 'updateDefaultCalendar') {
          firebase
            .firestore()
            .collection('users')
            .doc(auth_$.userData.value['id'])
            .update({ lastSession: { origin }, client: 'clio' })
            .then(() => {
              if (
                !auth_$.userData['clioAccessToken'] ||
                !JSON.parse(auth_$.userData['clioAccessToken'])['access_token']
              ) {
                redirectToClioAuthorize();
              } else {
                console.log('clioAccessToken: No need to get Authorization Code');
              }
            })
            .catch(err => console.log('err :', err));
          // await this.updateUserDefaultCalendar(predefinedCalendar);
        }
      });
  }

  async handleGetClioAuthorization(dialogConfig?, clioAccessToken?, eu = false) {
    this.dialog_$
      .open(ClioAccessTokenDialogComponent, {
        width: dialogConfig.width || '500px',
        closeOnNavigation: dialogConfig.closeOnNavigation || true,
        disableClose: dialogConfig.disableClose || false,
        data: {
          closable: dialogConfig.data.closable,
          origin: dialogConfig.data.origin || 'updateDefaultCalendar',
          message:
            dialogConfig.data.message ||
            'Your default calendar now is CLIO, but you need to Authorize its usage first.',
        },
      })
      .afterClosed()
      .subscribe(async (origin: string) => {
        if (origin === 'updateDefaultCalendar') {
          firebase
            .firestore()
            .collection('users')
            .doc(dialogConfig.data.userdocid)
            .update({ lastSession: { origin }, client: 'clio' })
            .then(() => {
              if (!clioAccessToken || !JSON.parse(clioAccessToken)['access_token']) {
                // NOTE: Is there a way to know this is EU or US?
                this.redirectToAuthorize(environment.config.clio.redirectsGroup.clientProfile, eu);
              } else {
                console.log('clioAccessToken: No need to get Authorization Code');
              }
            })
            .catch(err => console.log('err :', err));
        }
        if (origin === 'afterLogin') {
          firebase
            .firestore()
            .collection('users')
            .doc(dialogConfig.data.userdocid)
            .update({ lastSession: { origin } })
            .then(() => {
              // NOTE: Is there a way to know this is EU or US?
              this.redirectToAuthorize(environment.config.clio.redirectsGroup.clientProfile, eu);
            })
            .catch(err => console.log('err :', err));
        }
      });
  }
  // FIXME: Validate to use EU or US keys.
  redirectToAuthorize(callbackUrl: string, eu = false) {
    let client_id: string, client_secret: string;
    eu = eu || this.sessionStorage_$.getAddToClioEU();
    const { clio_eu, clio_us, clio } = environment.config;

    client_id = eu ? clio_eu.client_id : clio_us.client_id;
    client_secret = eu ? clio_eu.client_secret : clio_us.client_secret;

    const { authorizeURL } = clio;
    const params = new HttpParams()
      .set('response_type', 'code')
      .set('client_id', client_id)
      .set('redirect_uri', callbackUrl);
    const request = new HttpRequest('GET', authorizeURL, null, { params });
    window.location.href = request.urlWithParams;
  }

  validateClioAccessToken(clioAccessToken) {
    return clioAccessToken ? JSON.parse(clioAccessToken)['access_token'] : false;
  }

  public completeAddToClioAction() {
    console.log('completeAddToClioAction: ');
    const { appIntegrationsCallBackURL_eu, appIntegrationsCallBackURL } = environment.config.clio.SSO;
    window.location.href = this.sessionStorage_$.getAddToClioEU()
      ? appIntegrationsCallBackURL_eu
      : appIntegrationsCallBackURL;
  }

  public clioGetAuthorizationCodeV3({ userdocid, code, redirect_uri, eu }) {
    if (!userdocid) {
      console.error('No userdocid has been provided');
      return;
    }
    if (!code) {
      console.error('No code has been provided');
    }
    if (!redirect_uri) {
      console.error('No code has been provided');
    }

    const data = { userdocid, code, redirect_uri, eu };
    return this.http.post(`${environment.constants.cloudfunctionsURL}clio-getAuthorizationCodeV8`, data).toPromise();
  }

  public clioGetAuthorizationCode({ userdocid, code, redirect_uri }) {
    if (!userdocid) {
      console.error('No userdocid has been provided');
      return;
    }

    if (!code) {
      console.error('No code has been provided');
    }

    if (!redirect_uri) {
      console.error('No code has been provided');
    }

    return firebase.functions().httpsCallable('clio-getAuthorizationCode')({ userdocid, code, redirect_uri });
  }

  updateClioSSO(value, userdocid) {
    return firebase.functions().httpsCallable('clio-updateSSO')({ value, userdocid });
  }

  getAuthorizationCode({ userdocid, code, redirect_uri }) {
    return firebase.functions().httpsCallable('clio-getAuthorizationCode')({ userdocid, code, redirect_uri });
  }

  public clioGetAuthorizationCodeV7({ uid, code, redirect_uri, eu, updateCustomActions }) {
    if (!uid) {
      console.error('No uid has been provided');
      return;
    }
    if (!code) console.error('No code has been provided');
    if (!redirect_uri) console.error('No code has been provided');

    const params = { uid, code, redirect_uri, eu, updateCustomActions };
    return firebase.functions().httpsCallable('clio-getAuthorizationCodeV7')(params);
  }

  public async getClioFolderContents(
    options: GetClioFolderContentsOptions,
    clioAccessToken: string,
  ): Promise<{
    documents: ClioDocument[];
    folders: ClioFolder[];
  }> {
    const { folderId, folderPath, caseName, parentFolderId, parentFolderName } = options;

    if (!parentFolderId || !parentFolderName) {
      console.error('parentFolderId or parentFolderName is missing');
      return;
    }

    try {
      const uid = firebase.auth().currentUser.uid;
      // Call Firebase Function instead of direct API calls
      // const cloudFunctionName = 'clio-getFolderContentsV4-9';
      const cloudFunctionName = 'clio-getFolderContentsV4-8-1';
      // const cloudFunctionName = 'clio-getFolderContentsV4-8';
      // const cloudFunctionName = 'clio-getFolderContentsV4-2';
      // const cloudFunctionName = 'clio-getFolderContentsV4';
      const response = await firebase
        .functions()
        .httpsCallable(cloudFunctionName)({
          uid,
          folderId,
          clioAccessToken,
          folderPath,
          caseName,
          parentFolderId,
          parentFolderName,
          email: options.email,
        })
        .then(data => {
          console.log('====================');
          console.log('data: ', data);
          console.log('====================');
          this.dialog_$.open(SimpleMessageWindowComponent, {
            width: '500px',
            data: {
              title: 'Share Disc to NuageDx',
              message: "Disc contents have been fetched successfully, you'll receive an email shortly.",
              type: 'success',
            },
          });
          return data;
        });

      return {
        documents: response['data'].documents || [],
        folders: response['data'].folders || [],
      };
    } catch (error) {
      console.error('Error fetching Clio folder contents:', error);
      throw error;
    }
  }
}
