import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { UtilsService } from './../../services/utils.service';

interface FolderSegment {
  id: string;
  name: string;
  label?: string;
  color?: string;
  parentId?: string;
}

@Component({
  selector: 'app-breadcrumb', 
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnChanges, OnInit {
  @Input() currentFolder = ''; 
  @Input() folderColor = '';
  @Input() currentFolderName = '';
  @Input() caseFolders: any[] = []; // Add input for folder hierarchy
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  
  folderColors: {
    INDEX: string;
    Damages: string;
    ClientUpload: string; 
    Discovery: string;
    Liability: string;
    Other: string;
    custom: string;
  };
  folderAliases: {name: string, label: string}[] = [];
  pathSegments: FolderSegment[] = [];

  constructor(private utils_$: UtilsService) {}

  ngOnInit() {
    this.folderAliases = [
      { name: 'Discs', label: 'Discs' },
      { name: 'INDEX', label: 'All Files' },
      { name: 'Clio', label: 'Clio' }
    ];
    this.updateBreadcrumb();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentFolderName || changes.currentFolder || changes.folderColor || changes.caseFolders) {
      this.updateBreadcrumb();
    }
  }

  private updateBreadcrumb() {
    this.pathSegments = [];
    
    if (this.currentFolder === '') {
      return;
    }

    // Special folder cases
    const specialFolders = ['all', 'Clio', 'Discs'];
    if (specialFolders.includes(this.currentFolder) || specialFolders.includes(this.currentFolderName)) {
      const alias = this.folderAliases.find(a => 
        a.name === this.currentFolderName || a.name === this.currentFolder
      );
      this.pathSegments = [{
        id: this.currentFolder,
        name: this.currentFolderName || this.currentFolder,
        label: alias?.label,
        color: this.folderColor
      }];
      return;
    }

    // Build path segments for nested folders
    this.buildPathSegments();
  }

  private buildPathSegments() {
    if (!this.caseFolders?.length) return;

    let currentFolderId = this.currentFolder;
    const segments: FolderSegment[] = [];

    // Build path from current folder up to root
    while (currentFolderId) {
      const folder = this.caseFolders.find(f => f.folderId === currentFolderId);
      if (!folder) break;

      // Add folder to start of path
      segments.unshift({
        id: folder.folderId,
        name: folder.name,
        label: this.getFolderLabel(folder.name),
        color: folder.color || this.folderColor,
        parentId: folder.parentFolder
      });

      // Move up to parent
      currentFolderId = folder.parentFolder;
    }

    this.pathSegments = segments;
  }

  private getFolderLabel(folderName: string): string | undefined {
    const alias = this.folderAliases.find(a => a.name === folderName);
    return alias?.label;
  }

  getButtonStyle(name: string) {
    const style = this.utils_$.getButtonStyle(name);
    // If we have an explicit folder color, use it
    if (this.folderColor) {
      style.background = this.folderColor;
    }
    return style;
  }

  handleGo(folderId: string) {
    this.buttonClick.emit(folderId);
  }
}
