import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';

interface FileNode {
  id: string;
  name: string;
  type: 'file' | 'folder';
  parentFolder: string | null;
  children?: FileNode[];
}

@Component({
  selector: 'app-file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.scss'],
})
export class FileExplorerComponent implements OnInit, OnChanges {
  @Input() data: FileNode[] = [];
  @Input() localDocuments: any[] = []; // Add this line
  @Output() getFolderContents = new EventEmitter();

  public treeControl = new NestedTreeControl<FileNode>(node => node.children);
  public dataSource = new MatTreeNestedDataSource<FileNode>();
  public selection = new SelectionModel<FileNode>(true); // true enables multi-select

  // Add loading state tracking
  public loadingFolder = new Map<string, boolean>();

  private expandedNodeIds: Set<string> = new Set();
  // Modify hasChildren to always return true for folders
  public hasChildren = (_: number, node: FileNode): boolean => {
    return node.type === 'folder';
  };

  ngOnInit() {
    this.dataSource.data = this.buildFileTree();
  }

  ngOnChanges() {
    this.dataSource.data = this.buildFileTree();
    this.restoreExpandedState(this.dataSource.data);
  }

  /** Whether all descendants of the node are selected */
  descendantsAllSelected(node: FileNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.length > 0 && descendants.every(child => this.selection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FileNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const selected = descendants.some(child => this.selection.isSelected(child));
    return selected && !this.descendantsAllSelected(node);
  }

  /** Toggle the selection of a node */
  nodeSelectionToggle(node: FileNode): void {
    this.selection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);

    if (this.selection.isSelected(node)) {
      this.selection.select(...descendants);
    } else {
      this.selection.deselect(...descendants);
    }
  }

  /** Check if node is expandable (is folder with children) */
  isExpandable(node: FileNode): boolean {
    return node.type === 'folder' && !!node.children && node.children.length > 0;
  }

  getNodeIcon(node: FileNode): string {
    if (node.type === 'folder') {
      return 'folder';
    }

    // Map file extensions to specific icons
    const extension = node.name.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'picture_as_pdf';
      case 'doc':
      case 'docx':
        return 'description';
      case 'xls':
      case 'xlsx':
        return 'table_chart';
      case 'jpg':
      case 'jpeg':
      case 'png':
        return 'image';
      case 'dcm':
        return 'medical_services';
      default:
        return 'insert_drive_file';
    }
  }

  // Add method to handle folder expansion
  public async handleNodeExpand(node: FileNode): Promise<void> {
    if (node.type !== 'folder') return;

    if (!this.treeControl.isExpanded(node)) {
      this.expandedNodeIds.delete(node.id);
      return;
    }

    this.expandedNodeIds.add(node.id);
    if (!node.children?.length) {
      this.loadingFolder.set(node.id, true);
      try {
        this.getFolderContents.emit(node.id);
      } finally {
        this.loadingFolder.delete(node.id);
      }
    }
  }

  // Add helper method to check if a folder is loading
  public isFolderLoading(node: FileNode): boolean {
    return this.loadingFolder.get(node.id) ?? false;
  }

  // Add this method to check if a file exists in localDocuments
  public isFileShared(node: FileNode): boolean {
    return node.type === 'file' && this.localDocuments.some(doc => doc.clioDocId === parseInt(node.id, 10));
  }

  private buildFileTree(): FileNode[] {
    const tree: FileNode[] = [];
    const map = new Map<string, FileNode>();

    // First pass: create all nodes and store them in map
    this.data.forEach(item => {
      const existingNode = this.findNodeById(this.dataSource.data, item.id);
      if (!map.has(item.id)) {
        map.set(item.id, {
          ...item,
          children: existingNode?.children || [],
        });
      }
    });

    // Second pass: build tree structure
    this.data.forEach(item => {
      const node = map.get(item.id)!;
      if (item.parentFolder === null) {
        if (!tree.some(n => n.id === node.id)) {
          tree.push(node);
        }
      } else {
        const parent = map.get(item.parentFolder);
        if (parent) {
          parent.children = parent.children || [];
          if (!parent.children.some(n => n.id === node.id)) {
            parent.children.push(node);
          }
        }
      }
    });

    return tree;
  }

  private findNodeById(nodes: FileNode[], id: string): FileNode | null {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      }
      if (node.children) {
        const found = this.findNodeById(node.children, id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  private restoreExpandedState(nodes: FileNode[]): void {
    const expandNode = (node: FileNode) => {
      if (this.expandedNodeIds.has(node.id)) {
        this.treeControl.expand(node);
      }
      if (node.children) {
        node.children.forEach(expandNode);
      }
    };

    nodes.forEach(expandNode);
  }
}
