<!-- <pre>
    {{data|json}}
</pre> -->
<mat-accordion>
  <mat-expansion-panel *ngFor="let item of data; let i = index"
                       [expanded]="expandedIndex === i"
                       (closed)="expandedIndex = -1"
                       (opened)="handleLoadFolderData(item); expandedIndex = i">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon>
          folder
        </mat-icon>
        <span style="margin-left: 8px">
          {{item.label}}
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-panel-description>
      <app-files-navigator-data *ngIf="item.childrenData && expandedIndex === i; else nochildren"
                                [data]="item.childrenData"></app-files-navigator-data>
      <ng-template #nochildren>
      </ng-template>
    </mat-panel-description>
    <div fxLayout="column"
         fxLayoutGap="10px">
      <div *ngFor="let detail of item.details">
        <p>
          {{detail}}
        </p>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
