import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface FileNode {
  id: string;
  name: string;
  type: 'file' | 'folder';
  parentFolder: string | null;
}

@Component({
  selector: 'app-import-disc-from-clio',
  templateUrl: './import-disc-from-clio.component.html',
  styleUrls: ['./import-disc-from-clio.component.scss'],
})
export class ImportDiscFromClioComponent {
  sourceDocuments: any[];
  localDocuments: any[];
  matterData = [];
  mockData: FileNode[];
  @ViewChild('list') list;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ImportDiscFromClioComponent>,
  ) {
    this.matterData.push({ label: 'Matter', value: data.matterData.lpmFields.matter.display_number });

    this.sourceDocuments = data.options.clioDocumentId
      ? data.sourceDocuments.filter(i => i.id === parseInt(data.options.clioDocumentId, 10))
      : data.sourceDocuments;

    this.sourceDocuments.forEach(file => {
      if (data.localDocuments.find(localFile => localFile.clioDocId === file.id)) {
        file['existing'] = true;
      }
    });

    // If all sourceDocuments are existing, go and close the dialog.
    if (this.sourceDocuments.every(file => data.localDocuments.find(localFile => localFile.clioDocId === file.id))) {
      console.log('All sourceDocuments are existing. Closing the dialog.');
      this.dialogRef.close();
    }

    this.localDocuments = data.localDocuments;
    this.mockData = data.mockData || [];
  }

  continueWithoutAction() {
    this.dialogRef.close();
  }

  handleImportSelected() {
    const selected = this.list.selectedOptions.selected.map((item: any) => {
      const { id, name } = this.sourceDocuments.find(file => file.id === item.value);
      return { id, name };
    });
    this.dialogRef.close(selected);
  }

  handleImportAll() {
    this.list.selectAll();
    this.handleImportSelected();
  }
}
