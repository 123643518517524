import firebase from 'firebase/app';
import 'firebase/firestore';
import packageJson from '../../package.json';

const localCloudFunctions = false;
const localStorage = false;
const functionsPort = 5001;
const storagePort = 9199;
const localIPAddress = '127.0.0.1';
const site_uris = ['app.nuagedx.com', 'qa.nuagedx.com', 'localhost:4200'];
const site_uri = site_uris[0];
const version = packageJson['prod-version'];
const devVersion = packageJson['dev-version'];
const projectId = 'nuagedx-dev';
const viewerUrls = ['https://viewer.nuagedx.com/', 'https://viewer-qa.web.app/', 'https://viewer-qa-1.web.app/'];
const viewerIndex = 0;
let cloudFunctionsUrl = 'https://us-central1-nuagedx-dev.cloudfunctions.net/';
const usCallbackStr = 'sso_callback';
const euCallbackStr = 'sso_callback_eu';
if (localCloudFunctions) {
  cloudFunctionsUrl = `http://${localIPAddress}:${functionsPort}/nuagedx-dev/us-central1/`;
}

export default {
  production: true,
  maintenance: false,
  config: {
    raygun: true,
    // sessionTimeLimit: 2 * 60 * 60 * 1000, // 2 hours.
    sessionTimeLimit: 30 * 60 * 1000, // 30 minutes.F
    getNotificationsFrequency: 5 * 60 * 1000, //  20 seconds.
    sysadmin: false,
    notifications: 1,
    databaseURL: 'https://nuagedx-dev.firebaseio.com',
    healthcareApi: `${cloudFunctionsUrl}healthcareApi`,
    healthcareApiV5: `${cloudFunctionsUrl}healthcareApiV5`,
    location: 'us-central1',
    apiKey: 'AIzaSyBPYsY-89MlrB5w3-cQf92-ILGiHF46AZ0',
    authDomain: 'nuagedx-dev.firebaseapp.com',
    projectId: 'nuagedx-dev',
    storageBucket: 'nuagedx-dev.appspot.com',
    messagingSenderId: '409596160399',
    appId: '1:409596160399:web:c94b8198a805007e',
    ohifViewerURL: viewerUrls[viewerIndex],
    host: `https://${site_uri}/`,
    version: version,
    devVersion: devVersion,
    viewerIndex: viewerIndex,
    algolia: {
      appId: '3RFWKF3793',
      apiKey: '5ff8756359c3dca2ad643a1284d7a26e',
      filesIndex: 'dev_FILES',
    },
    malwarescanner: {
      endpoint: 'malwarescanner',
    },
    gapi: {
      clientId: '409596160399-jvos1lrt8q6lqc1u2u9fi2vokn4upe4a.apps.googleusercontent.com',
      healthcareRest: 'https://content.googleapis.com/discovery/v1/apis/healthcare/v1/rest',
      healthcareRest_v1beta1: 'https://www.googleapis.com/discovery/v1/apis/healthcare/v1beta1/rest',
      platformScope: 'https://www.googleapis.com/auth/cloud-platform',
      apiKey: 'AIzaSyCaMW--IxXHWpzH9YsM_gxMOL9tFldan5o',
      location: 'us-central1',
      projectname: projectId,
    },
    clio: {
      client_id: 'rHRqUKxKFh1cQkiPJ9oK4ZCLktkil0V7JJbbWubk',
      client_secret: 'dA8CI7LZndm236U8zPtXO4BPyZEaZYdimv3NhcOB',
      redirect_uri: `https://${site_uri}/clioauth`,
      authorizeURL: 'https://app.clio.com/oauth/authorize',
      authorizeURL_eu: 'https://eu.app.clio.com/oauth/authorize',
      redirectsGroup: {
        updateDefaultCalendar: `https://${site_uri}/clioauth/calendar`,
        updateDefaultCalendar_eu: `https://${site_uri}/clioauth/calendar_eu`,
        clientProfile: `https://${site_uri}/clioauth/client`,
        clientProfile_eu: `https://${site_uri}/clioauth/client_eu`,
      },
      SSO: {
        key: 'f38a14bc-2e90-4811-b847-a89fe408f7f1',
        secret: 'xn6zFuII6AF82rSLKu8RFGHNqA',
        usCallbackStr: usCallbackStr,
        euCallbackStr: euCallbackStr,
        callback: `https://${site_uri}/${usCallbackStr}`,
        callback_eu: `https://${site_uri}/${euCallbackStr}`,
        authVersionURL: 'https://account.clio.com/oauth2/auth',
        authVersionURL_eu: 'https://eu.account.clio.com/oauth2/auth',
        appIntegrationsCallBackURL: `https://app.clio.com/app_integrations_callback`,
        appIntegrationsCallBackURL_eu: `https://eu.app.clio.com/app_integrations_callback`,
      },
    },
    clio_eu: {
      client_id: 'CXfiBlIobooB0fwjmMrNqybDLZhJZXyJ5np28f9C',
      client_secret: '3x63334ng3COrRcOVsO4r4OV1nQHwOWE4gwUHSqZ',
      apiAuthCallback: `https://${site_uri}/clioauth/apiauthcallback_eu`,
      redirect_uri: `https://${site_uri}/clioauth`,
    },
    clio_us: {
      client_id: 'rHRqUKxKFh1cQkiPJ9oK4ZCLktkil0V7JJbbWubk',
      client_secret: 'dA8CI7LZndm236U8zPtXO4BPyZEaZYdimv3NhcOB',
      apiAuthCallback: `https://${site_uri}/clioauth/apiauthcallback`,
    },
    snackBar: {
      duration: 7000,
      verticalPosition: 'top',
      panelClass: 'snackbar-error',
    },
    zoom: {
      clientId: '7AwbYkq9SUK2uqp6RQQfkg',
      clientSecret: 'i2Iu4J8tkhebMtEjJBnY8zr66TPDvZGN',
      redirectURL: `https://${site_uri}/meetings`,
      findLocalNumbersURL: 'https://nuagedx.zoom.us/u/ac0xN4965m',
      invitationOptions: {
        includeDateTime: true,
        host_invitation: false,
      },
    },
    microsoft: {
      oauth: {
        appId: '59617e48-485c-42ca-98ee-914b43b3999c',
        redirectUri: `https://${site_uri}/meetings`,
        scopes: ['user.read', 'calendars.readwrite', 'mailboxsettings.read'],
      },
    },
    email: { targets: { notifications: ['alexisrengifo@gmail.com'] } },
  },
  constants: {
    cloudfunctionsURL: 'https://us-central1-nuagedx-dev.cloudfunctions.net/',
    mailClient: {
      GOOGLE: 'Google',
      OFFICE365: 'Office365',
      OUTLOOK: 'Outlook',
      OTHER: 'Other',
      CLIO: 'Clio',
    },
    zoomDefaults: { DEFAULT_TOPIC: 'Nuagedx meeting' },
  },
};
