<div fxLayout="row"
     fxLayoutAlign=" center">
  <mat-icon fxFlex="5"
            fxFlex="none">
    account_circle
  </mat-icon>

  <div fxFlex="90"
       fxFlexOffset="2"
       fxLayout="column">
    <span class="mat-subheading-1"
          [ngStyle]="{ margin: '0' }">
      {{ client.FirstName && client.LastName ? client.FirstName + ' ' + client.LastName : client.name }}
      <mat-icon [ngClass]="hasNotifications ? 'blinking-star' : 'hidden'">
        notifications
      </mat-icon>
    </span>
    <span class="mat-body"
          fxFlex>
      Matter name: {{ client.caseName }}
      <span *ngIf="client.DateOfBirth">
        | DOB - {{ client.DateOfBirth }}
      </span>
    </span>
  </div>
  <button *ngIf="!permissions.dialogdata && permissions.ableToEditClient"
          class="edit-client-button"
          aria-label="Click here to edit this client."
          fxFlex="5"
          mat-icon-button
          matTooltip="Click here to edit this client."
          (click)="handleEditClient($event, client)">
    <mat-icon>
      create
    </mat-icon>
  </button>
  <button *ngIf="permissions.ableToDeleteClient"
          class="edit-client-button"
          aria-label="Click here to delete this client."
          fxFlex="5"
          mat-icon-button
          matTooltip="Click here to delete this client."
          (click)="handleDeleteClient($event, client)">
    <mat-icon>
      delete
    </mat-icon>
  </button>
</div>
